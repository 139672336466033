<app-messages></app-messages>

<nav class="navbar navbar-expand-lg navbar-dark medium-margin-bottom" *ngIf="appComponentRouter.url != ''"
  style="background-color: #000a36 !important">
  <a class="navbar-brand" href="https://zint.io" id="header-title">
    <img id="main-logo" src="/assets/website-images/logo.png" width="30" height="30" class="d-inline-block align-top"
      alt="" />
    ZINT
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup" style="font-weight: 100">
    <div class="navbar-nav">
      <a class="nav-item nav-link" href="/manage-team" *ngIf="isSuperUser" style="text-align: center"><i
          class="fa fa-users"></i> Manage Team
      </a>
      <a class="nav-item nav-link" href="/manage-territories" *ngIf="isSuperUser && hasTerritoryAssignmentAccess"
        style="text-align: center"><i class="fa fa-map"></i> Manage Territories
      </a>
      <a class="nav-item nav-link" href="/login" *ngIf="!accountEmail">Login <span class="sr-only">(current)</span></a>
      <a class="nav-item nav-link" href="/register" *ngIf="!accountEmail">Register
      </a>

      <a class="nav-item nav-link basic-button-base" href="/dashboard" id="main-find-companies-button">Find Companies
      </a>
    </div>
    <ul class="navbar-nav ml-auto">
      <a *ngIf="hasZintGrowAccess" href="/grow" class="nav-item nav-link basic-button-base icon-nav-link" matTooltip="Zint Grow">
        <i class="fa fa-seedling"></i>
        <span *ngIf="zgUnreadCount" class="zg-counter-badge">{{zgUnreadCount}}</span>
      </a>             

      <a class="nav-item nav-link basic-button-base icon-nav-link" *ngIf="accountEmail" [ngClass]="{
          'nav-button-functionality-disabled': !hasPropensityScoreAccess
        }" [attr.href]="
          hasPropensityScoreAccess
            ? '/propensity-score'
            : 'mailto:sales@zint.io?Subject=Propensity%20Scoring%20Enquiry'
        "  matTooltipPosition="below" [matTooltip]="
          hasPropensityScoreAccess
            ? 'Propensity Score'
            : 'You do not have access to Propensity Scoring'
        " style="padding-left: 13px !important; padding-right: 13px !important"><i class="fa fa-star"></i></a>
      <a class="nav-item nav-link basic-button-base icon-nav-link" *ngIf="accountEmail" href="/saved-searches"
         matTooltipPosition="below" matTooltip="Saved Searches"><i class="fa fa-play-circle"
          style="margin-left: -1px; margin-right: -1px"></i></a>
      <a class="nav-item nav-link basic-button-base icon-nav-link" *ngIf="accountEmail" href="/account"
         matTooltipPosition="below" matTooltip="Settings"><i class="fa fa-cog"></i></a>
      <li class="nav-item nav-link basic-button-base icon-nav-link" *ngIf="accountEmail" style="position: relative;
          padding-left: 5px !important;
          padding-right: 5px !important;
          padding-top: 6px !important;">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" style="display: inline">
          <i class="fa fa-stream" style="margin-left: -3px"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right"
          style="position: absolute; top: 100%; right: 0; margin-left: -100px; z-index: 1030;">
          <a class="dropdown-item" *ngIf="accountEmail" [ngClass]="{
              'dropdown-nav-button-functionality-disabled': !hasCrossSellAccess
            }" [attr.href]="
              hasCrossSellAccess
                ? '/cross-sell-opportunities'
                : 'mailto:sales@zint.io?Subject=Cross%20Sell%20Enquiry'
            "  matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
              hasCrossSellAccess
                ? ''
                : 'You do not have access to the Cross-Sell Tool'
            "><i class="fal fa-link mr-2"></i>Cross-Sell Tool</a>
          <div class="dropdown-divider" [ngClass]="{
              'dropdown-divider-disabled': !hasCrossSellAccess
            }"></div>

          <a class="dropdown-item" *ngIf="accountEmail" [ngClass]="{
              'dropdown-nav-button-functionality-disabled':
                !hasCompanyMatcherAccess
            }" [attr.href]="
              hasCompanyMatcherAccess
                ? '/matcher'
                : 'mailto:sales@zint.io?Subject=Company%20Matcher%20Enquiry'
            "  matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
              hasCompanyMatcherAccess
                ? ''
                : 'You do not have access to the the Company Matcher'
            "><i class="fal fa-upload mr-2"></i>Company Matcher</a>
          <div class="dropdown-divider" [ngClass]="{
              'dropdown-divider-disabled': !hasCompanyMatcherAccess
            }"></div>
            
           <a class="dropdown-item" *ngIf="accountEmail" [ngClass]="{
              'dropdown-nav-button-functionality-disabled': !hasZintGrowAccess
            }" [attr.href]="
              hasZintGrowAccess
                ? '/grow'
                : 'mailto:sales@zint.io?Subject=Zint%20Grow%20Enquiry'
            "  matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
              !hasZintGrowAccess ? 'You do not have access to Zint Grow' : ''
            "><i class="fal fa-seedling mr-2"></i>Zint Grow</a>
          <div [ngClass]="{
              'dropdown-divider': true,
              'mt-0': !hasZintGrowAccess
            }"></div>

          <a class="dropdown-item" *ngIf="accountEmail" [ngClass]="{
              'dropdown-nav-button-functionality-disabled':
                !hasMarketMappingReportAccess
            }" [attr.href]="
              hasMarketMappingReportAccess
                ? '/market-mapping-report'
                : 'mailto:sales@zint.io?Subject=Market%20Mapping%20Enquiry'
            "  matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
              hasMarketMappingReportAccess
                ? ''
                : 'You do not have access to Market Mapping Reports'
            "><i class="fal fa-file-check mr-2"></i>Market Mapping Report</a>
          <div class="dropdown-divider" [ngClass]="{
              'dropdown-divider-disabled': !hasMarketMappingReportAccess
            }"></div>

          <a class="dropdown-item" *ngIf="accountEmail" [ngClass]="{
              'dropdown-nav-button-functionality-disabled':
                !hasSalesforceV2AccessMessage
            }" [attr.href]="
              hasSalesforceV2AccessMessage
                ? '/salesforce'
                : 'mailto:sales@zint.io?Subject=Salesforce%20Integration%20Enquiry'
            "  matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" target="_blank" [matTooltip]="
              hasSalesforceV2AccessMessage
                ? ''
                : 'You do not have access to the Salesforce Integration'
            "><i class="fab fa-salesforce mr-2"></i>Salesforce Integration</a>
          <div class="dropdown-divider" [ngClass]="{
              'dropdown-divider-disabled': !hasSalesforceV2AccessMessage
            }"></div>


          <a class="dropdown-item" *ngIf="accountEmail" [ngClass]="{
              'dropdown-nav-button-functionality-disabled':
                !hasHubspotAccess
            }" [attr.href]="
              hasHubspotAccess
                ? '/hubspot'
                : 'mailto:sales@zint.io?Subject=Hubspot%20Integration%20Enquiry'
            "  matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" target="_blank" [matTooltip]="
              hasHubspotAccess
                ? ''
                : 'You do not have access to the HubSpot Integration'
            "><i class="fab fa-hubspot mr-2"></i>HubSpot Integration</a>
          <div class="dropdown-divider" [ngClass]="{
              'dropdown-divider-disabled': !hasHubspotAccess
            }"></div>



          <a class="dropdown-item" *ngIf="accountEmail" [ngClass]="{
              'dropdown-nav-button-functionality-disabled':
                !hasCalendarIntegrationAccess
            }" [attr.href]="
              hasCalendarIntegrationAccess
                ? '/calendar-integration'
                : 'mailto:sales@zint.io?Subject=Calendar%20Integration%20Enquiry'
            "  matTooltipPosition="before" target="_blank" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
              hasCalendarIntegrationAccess ? '' : 'You do not have access to the Calendar Integration'
            "><i class="fal fa-calendar-alt mr-2"></i>Calendar Integration</a>
          <div class="dropdown-divider" [ngClass]="{
              'dropdown-divider-disabled': !hasCalendarIntegrationAccess
            }"></div>                    

          <a class="dropdown-item" *ngIf="accountEmail" href="#"
            data-toggle="dropdown">
            <i class="fal fa-bell mr-2"></i>
            <i *ngIf="
              !isLoadingAlerts.isLoadingNotifications &&
              notifications &&
              notifications.length > 0"
             class="fa fa-circle notification-circle"></i>            
            Alerts
          </a>

            <div class="dropdown-menu" id="alerts-dropdown-menu">
              <div class="alerts-holder">
                <div class="d-flex justify-content-center align-items-center" *ngIf="isLoadingAlerts.isLoadingNotifications">
                  <zint-loader size="small"></zint-loader>
                </div>
                <div class="text-center mt-1" *ngIf="
                    !isLoadingAlerts.isLoadingNotifications &&
                    notifications &&
                    notifications.length == 0
                  ">
                  <span>You have no notifications.</span>
                </div>
                <ng-container *ngFor="let notification of notifications">
                  <div class="dropdown-item">
                    <div class="row align-items-center">
                      <div class="col-sm-10">
                        <span>{{
                          notification.number_of_unread_companies
                          }}
                          organisations matched your alert "{{
                          notification.name
                          }}"</span>
                        <span class="alerts-time-ago">({{ notification.latest_company_found }})</span>
                      </div>
                      <div class="col-sm-2">
                        <a class="basic-button-base button-dark alerts-play-button" [attr.href]="notification.search_url"><i
                            class="fa fa-play"></i></a>
                        <button class="basic-button-base button-dark alerts-mark-unread-button"
                          (click)="markAlertAsRead($event, notification.id)">
                          <i class="fal fa-spinner fa-spin" *ngIf="isLoadingAlerts.isLoadingMarkAsRead"></i>
                          <i class="fa fa-envelope-open" *ngIf="!isLoadingAlerts.isLoadingMarkAsRead"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                </ng-container>
                <a href="/alerts" class="manage-alert-link">Manage your alerts</a>
              </div>              
            </div>
          <div class="dropdown-divider"></div>

          <a class="dropdown-item" *ngIf="accountEmail" href="https://support.zint.io" title="Knowledge Base"
            target="_blank"><i class="fal fa-graduation-cap mr-2"></i>Knowledge Base</a>

          <div class="dropdown-divider"></div>

          <a class="dropdown-item font-weight-bold" *ngIf="accountEmail" href="/logout" title="Log out"><i
              class="far fa-sign-out-alt mr-2"></i>Logout</a>
        </div>
      </li>

      <li class="nav-item dropdown">
        <input type="text" class="form-control" [(ngModel)]="companyNameSearch"
          (ngModelChange)="searchForCompanyByName()" maxlength="160" id="navbarDropdownMenuLink" data-toggle="dropdown"
          placeholder="Search for a company" />
        <div class="dropdown-menu" style="right: 0; left: unset; z-index: 1030;">
          <ng-container *ngFor="let shortlistedCompany of shortlistedCompaniesByName">
            <a class="dropdown-item" href="company/{{ shortlistedCompany.company_number }}/">
              <table style="width: 100%">
                <tbody>
                  <tr>
                    <td>
                      {{ shortlistedCompany.company_name }}
                    </td>
                    <td style="float: right; font-style: italic">
                      &nbsp;&nbsp;&nbsp;{{
                      shortlistedCompany.revenue
                      | companyDataPipe : "Revenue" : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </a>
          </ng-container>
        </div>
      </li>
    </ul>
  </div>
</nav>

<authentication></authentication>
<push-messages></push-messages>
<plan-features-enabled></plan-features-enabled>
<router-outlet></router-outlet>

<div class="fixed-bottom" *ngIf="showCookieBanner">
  <div class="row">
    <div class="col-md-3 col-lg-2">
      <div style="
          background-color: #000a36;
          color: white;
          padding: 10px;
          margin-bottom: 5px;
          margin-right: 5px;
          margin-left: 5px;
          border-radius: 2px;
        ">
        We use cookies to deliver essential services and for performance
        monitoring.
        <button class="btn-sm basic-button-base button-light" (click)="acceptCookies()">
          Okay
        </button>
      </div>
    </div>
  </div>
</div>
