<ng-container *ngIf="canBeRendered">
  <div *ngIf="isAnySummariesForYearAccount(summariesYearAccount); else noSummaries" class="ai-summaries-container" [ngClass]="{'old-view-container': isOldView}">
    <div *ngIf="isOldView" class="card-header insight-header">

      <h4 class="mb-0">💡Zint Strategic Insights
        <span *ngIf="!isInModal && !fallbackInfoText" role="button" class="anchor-text"
          (click)="scrollAndSelectFinancials()">(Derived from the {{reportYear}} financials report<i
            class="fal fa-chevron-double-down fa-xs ml-1"></i>)</span>
      </h4>

      <div>
        <button *ngIf="!isInModal" class="basic-button-base button-dark" data-toggle="modal"
          data-target="#AiInsightsModal" matTooltip="Full summaries of available year accounts">Read
          more</button>
      </div>
    </div>
    <div class="overflow-mh50-flex" [ngClass]="{'thick-white-border': isOldView}" *ngIf="!fallbackInfoText; else noSummaries">
      <div *ngIf="aiAccountsSummaries[summariesYearAccount][0].business_model?.length" class="insights-container">
        <h6 class><i class="far fa-solar-system"></i>Business Model</h6>
        <div class="insights-points-container">
          <span>{{aiAccountsSummaries[summariesYearAccount][0].business_model}}</span>
        </div>
      </div>

      <div *ngIf="aiAccountsSummaries[summariesYearAccount][0].strategic_goals?.length" class="insights-container">
        <h6 class><i class="far fa-bullseye-arrow"></i>Strategic Goals</h6>
        <div class="insights-points-container">
          <span *ngFor="let goal of aiAccountsSummaries[summariesYearAccount][0].strategic_goals">{{goal.text}}<a
              [href]="goal | xbrlOrOcrAccountsLink: 'insights' : {companyId, totalNumberOfPages, accountsType, pdfURL, fileName}" target="_blank"><i class="fal fa-external-link fa-xs"></i></a></span>
        </div>
      </div>

      <div *ngIf="aiAccountsSummaries[summariesYearAccount][0].pains_challenges?.length" class="insights-container">
        <h6 class><i class="far fa-cloud-showers-heavy"></i>Challenges</h6>
        <div class="insights-points-container">
          <span *ngFor="let pain of aiAccountsSummaries[summariesYearAccount][0].pains_challenges">{{pain.text}}
            <a [href]="pain | xbrlOrOcrAccountsLink: 'insights' : {companyId, totalNumberOfPages, accountsType, pdfURL, fileName}" target="_blank"><i class="fal fa-external-link fa-xs"></i></a></span>
        </div>
      </div>

      <div *ngIf="aiAccountsSummaries[summariesYearAccount][0].future_events?.length" class="insights-container">
        <h6 class><i class="far fa-calendar-star"></i>Future Events</h6>
        <div class="insights-points-container">
          <span *ngFor="let event of aiAccountsSummaries[summariesYearAccount][0].future_events">{{event.text}}<a
              [href]="event | xbrlOrOcrAccountsLink: 'insights' : {companyId, totalNumberOfPages, accountsType, pdfURL, fileName}" target="_blank"><i class="fal fa-external-link fa-xs"></i></a></span>
        </div>
      </div>

      <ng-container *ngIf="isFullSummariesAvailable(aiAccountsSummaries[summariesYearAccount][0].bullets)">
        <div *ngFor="let category of aiAccountsSummaries[summariesYearAccount][0].bullets | keyvalue"
          class="insights-container">
          <h6 class><i class="far fa-arrow-circle-right"></i>{{category.key}}</h6>
          <div class="insights-points-container">
            <span *ngFor="let bullet of category.value">
              {{bullet.text}}
              <a [href]="bullet | xbrlOrOcrAccountsLink: 'insights' : {companyId, totalNumberOfPages, accountsType, pdfURL, fileName}" target="_blank"><i class="fal fa-external-link fa-xs"></i></a>
            </span>            
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</ng-container>
<ng-template #noSummaries>
  <p class="p-3 border bg-light mb-0 text-grey-600">{{fallbackInfoText}}
  </p>
</ng-template>