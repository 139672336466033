import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class PermissionsService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = false;

  addViewableUser(
    userId,
    objectId,
    postParamName,
    urlAppendString
  ): Observable<any> {
    return this.addRemoveViewableUser(
      userId,
      objectId,
      postParamName,
      urlAppendString,
      true,
      true
    );
  }

  removeViewableUser(
    userId,
    objectId,
    postParamName,
    urlAppendString
  ): Observable<any> {
    return this.addRemoveViewableUser(
      userId,
      objectId,
      postParamName,
      urlAppendString,
      false,
      true
    );
  }

  addEditableUser(
    userId,
    objectId,
    postParamName,
    urlAppendString
  ): Observable<any> {
    return this.addRemoveViewableUser(
      userId,
      objectId,
      postParamName,
      urlAppendString,
      true,
      false
    );
  }

  removeEditableUser(
    userId,
    objectId,
    postParamName,
    urlAppendString
  ): Observable<any> {
    return this.addRemoveViewableUser(
      userId,
      objectId,
      postParamName,
      urlAppendString,
      false,
      false
    );
  }

  addRemoveViewableUser(
    userId,
    objectId,
    postParamName,
    urlAppendString,
    isAdd,
    isViewable
  ): Observable<any> {
    let urlPrepend;
    if (isAdd) {
      if (isViewable) {
        urlPrepend = '/add_viewable_user_to_';
      } else {
        urlPrepend = '/add_editable_user_to_';
      }
    } else {
      if (isViewable) {
        urlPrepend = '/remove_viewable_user_from_';
      } else {
        urlPrepend = '/remove_editable_user_from_';
      }
    }
    this.isLoading = true;
    let params = new HttpParams()
      .set('userId', userId)
      .set(postParamName, objectId);
    return this.http
      .post(
        baseUrl + urlPrepend + urlAppendString + '/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading = false)),
        catchError(
          this.pubMethods.handleError(
            'addRemoveViewableUser',
            [],
            this.isLoading
          )
        )
      );
  }

  addViewableTeam(
    teamId,
    objectId,
    postParamName,
    urlAppendString
  ): Observable<any> {
    return this.addRemoveViewableTeam(
      teamId,
      objectId,
      postParamName,
      urlAppendString,
      true,
      true
    );
  }

  removeViewableTeam(
    teamId,
    objectId,
    postParamName,
    urlAppendString
  ): Observable<any> {
    return this.addRemoveViewableTeam(
      teamId,
      objectId,
      postParamName,
      urlAppendString,
      false,
      true
    );
  }

  addEditableTeam(
    teamId,
    objectId,
    postParamName,
    urlAppendString
  ): Observable<any> {
    return this.addRemoveViewableTeam(
      teamId,
      objectId,
      postParamName,
      urlAppendString,
      true,
      false
    );
  }

  removeEditableTeam(
    teamId,
    objectId,
    postParamName,
    urlAppendString
  ): Observable<any> {
    return this.addRemoveViewableTeam(
      teamId,
      objectId,
      postParamName,
      urlAppendString,
      false,
      false
    );
  }

  addRemoveViewableTeam(
    teamId,
    objectId,
    postParamName,
    urlAppendString,
    isAdd,
    isViewable
  ): Observable<any> {
    let urlPrepend;
    if (isAdd) {
      if (isViewable) {
        urlPrepend = '/add_viewable_team_to_';
      } else {
        urlPrepend = '/add_editable_team_to_';
      }
    } else {
      if (isViewable) {
        urlPrepend = '/remove_viewable_team_from_';
      } else {
        urlPrepend = '/remove_editable_team_from_';
      }
    }
    this.isLoading = true;
    let params = new HttpParams()
      .set('teamId', teamId)
      .set(postParamName, objectId);
    return this.http
      .post(
        baseUrl + urlPrepend + urlAppendString + '/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading = false)),
        catchError(
          this.pubMethods.handleError(
            'addRemoveViewableTeam',
            [],
            this.isLoading
          )
        )
      );
  }



  getSuperuserTeamsAndUsers(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/get_superuser_teams_and_users/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('getSuperuserTeamsAndUsers', []))
      );
  }





}
