import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import { CorporateGraphType } from '../../../classes/types';

@Component({
  selector: 'speak-to-sales-popover',
  templateUrl: './speak-to-sales-popover.component.html',
  styleUrls: ['./speak-to-sales-popover.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SpeakToSalesPopoverComponent implements OnInit {
  @Input()
  inputParams: any = {
    isShown: false,
    sectionTitle: '',
    doubleHeight: false,
    noPadding: false,
    companyNumber: ''
  };

  isToggledOn = false;
  isCorporateStructure = false;
  toggleTooltip = 'Show Clusters';
  graphType: CorporateGraphType;

  @Output() switchCorporateGraph: EventEmitter<string> = new EventEmitter();
  @Output() getFullClustersGraph: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.isCorporateStructure = this.inputParams.sectionTitle
      ?.toLowerCase()
      .includes('corporate');
  }

  switchCardTitle(event) {
    this.isToggledOn = event.checked;
    this.graphType = this.isToggledOn ? 'clusters' : 'structure';
    this.switchCorporateGraph.emit(this.graphType);
    this.toggleTooltip = this.isToggledOn ? 'Show Structure' : 'Show Clusters';
  }

  showFullClustersGraph(): void {
    this.getFullClustersGraph.emit();
  }
}
