import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  mockCompany,
  CompanyPageSectionEnums,
  mockCompanyTwo,
} from '../../classes/company';
import { baseUrl, publicMethods } from '../../globals';
import { CompanyService } from '../../services/company.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { combineLatest, Observable } from 'rxjs';
import { CommunicationService } from '../../services/communication.service';
import { ActivatedRoute } from '@angular/router';
import { IFeaturePermissions } from '../../classes/types';
import { PropensityComponentOpacityPipe } from '../../pipes/propensity-component-opacity';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from '../../services/message.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { KeyValue } from '@angular/common';
import { Title } from '@angular/platform-browser';
declare var $: any;
declare var zE: any; // Zendesk SDK

@Component({
  selector: 'company-profile-page',
  templateUrl: './company-profile-page.component.html',
  styleUrl: './company-profile-page.component.css',
  providers: [PropensityComponentOpacityPipe],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyProfilePageComponent {
  loadingStatus: Record<string, any> = {};
  company: Record<string, any> = {};
  companyId: string = '';
  userCustomProperties: Record<string, any> = {};
  accountDetails: Partial<{ isSuperUser: boolean; email: string }> = {};
  teamMembers: Record<string, any>[] = [];
  featurePermissions: Partial<IFeaturePermissions> = {};
  permissionsCheckMap: Record<string, any> = {};
  toggleAllNavSections: boolean = false;
  allNavSections: string[] = [];
  isReordering: boolean = false;
  tempReOrderingList: any[];
  testAsyncPipe$: Observable<any>;

  fullWebsiteSearchTerm: string = '';
  accountsSearchText: string = '';
  accountsDateSelected: string = '';

  fullWebsiteResults: Record<string, any>[] = [];
  accountsResults: Record<string, any> = {};
  customAttrsUpdated: boolean = false;

  // yearAccIDWithLatestSummaries: number | string = null;
  aiAccountsSummaries: Record<string, any> = null;

  companyAIPitch: string = '';

  openSearchModalTitle: string = '';
  currentOpenSearchType: string = '';

  availableFinancialTerms: string[] = [];
  availableAssetsData: Record<string, any> = {};

  @ViewChildren(MatExpansionPanel, { read: ElementRef })
  panelElements: QueryList<ElementRef>;
  @ViewChildren(MatExpansionPanel)
  expansionPanels: QueryList<MatExpansionPanel>;

  constructor(
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private pubMethods: publicMethods,
    private companyService: CompanyService,
    private communicationService: CommunicationService,
    private messageService: MessageService,
    private propensityOpacityPipe: PropensityComponentOpacityPipe,
    private titleService: Title,
    private cdr: ChangeDetectorRef
  ) {
    this.loadingStatus = this.companyService.isLoading;
  }

  ngOnInit() {
    this.communicationService.getEmailMessage().subscribe(email => {
      this.accountDetails = { ...this.accountDetails, email };
    });
    this.communicationService.getIsSuperUserMessage().subscribe(check => {
      this.accountDetails = { ...this.accountDetails, isSuperUser: check };
    });
    this.checkPermissions();
    this.getAllNavSections();
    this.getCompanyProfilePageData();
  }

  findExpansionPanelById(sectionId: string): MatExpansionPanel {
    /* find index of the panel from the native elements since we only have direct access to html id from ElementRef
    Then using this index, return the right MatExpansionPanel.
    */
    const panelIndex = this.panelElements
      .toArray()
      .findIndex(el => el.nativeElement.getAttribute('id') === sectionId);

    if (panelIndex > -1) {
      return this.expansionPanels.toArray()[panelIndex];
    }
  }

  scrollToSectionAndExpand(): void {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        setTimeout(() => {
          const sectionPanel = this.findExpansionPanelById(fragment);
          if (sectionPanel) {
            sectionPanel.expanded = true;
            setTimeout(() => {
              this.companyService.scrollToSelectorOnceExists(fragment);
            }, 400);
          }
        }, 100);
      }
    });
  }

  checkPermissions(): void {
    const permissionObs$ = combineLatest([
      this.communicationService.getHasAIAccountsSummaryAccess(),
      this.communicationService.getHasAIPitchBuilderAccessMessage(),
      this.communicationService.getHasCrossSellAccessMessage(),
      this.communicationService.getHasSupplierDataAccessMessage(),
      this.communicationService.getHasEnergyAndEmissionsAccessMessage(),
      this.communicationService.getHasPatentsAccessMessage(),
      // TODO: Add more permissions as needed
    ]);

    /**
     * destructuring observable array here but we can also use old familiar
     * subscribe(data => {
     * hasAiAccountsSummaryAccess: data[0],
     * hasAiPitchBuilderAccess: data[1], ...}).
     * Either approach needs to maintain and follow the order from above combineLatest
     */
    permissionObs$.subscribe(
      ([
        hasAiAccountsSummaryAccess,
        hasAiPitchBuilderAccess,
        hasCrossSellAccess,
        hasSupplierDataAccess,
        hasPatentsAccess,
        hasEnergyAndEmissionsAccess,
      ]) => {
        this.featurePermissions = {
          ...this.featurePermissions,
          hasAiAccountsSummaryAccess,
          hasAiPitchBuilderAccess,
          hasCrossSellAccess,
          hasSupplierDataAccess,
          hasPatentsAccess,
          hasEnergyAndEmissionsAccess,
        };

        this.permissionsCheckMap = {
          strategic_insights:
            this.featurePermissions.hasAiAccountsSummaryAccess,
          pitch_builder: this.featurePermissions.hasAiPitchBuilderAccess,
          related_people: this.featurePermissions.hasCrossSellAccess,
          supplier_graph: this.featurePermissions.hasSupplierDataAccess,
          energy_and_emissions:
            this.featurePermissions.hasEnergyAndEmissionsAccess,
          patents_filed: this.featurePermissions.hasPatentsAccess,
        };

        // this.getCompanyProfilePageData();
      }
    );
  }

  getCompanyProfilePageData(): void {
    this.companyId = this.route.snapshot.paramMap.get('id');
    if (this.companyId) {
      this.companyService
        .getCompanyProfileData(this.companyId)
        .subscribe(data => {
          this.company = data.company;

          this.scrollToSectionAndExpand();
          this.getAvailableFinancialTerms();
          this.processAssetsData();
          this.titleService.setTitle(
            'Zint - ' + this.company.company_overview?.name
          );
        });

      this.getCompanyUserDetails();
    }
  }

  getCompanyUserDetails(): void {
    /**
     * This method populates custom properties in overview
     * */
    this.companyService
      .getCompanyUserDetails(this.companyId)
      .subscribe(data => {
        this.userCustomProperties = data;
        this.userCustomProperties['hasSomeData'] =
          !this.pubMethods.isEmptyObjectOrArray(data);
        this.updateCustomColumnsDetails();
      });
  }

  getAllNavSections(): void {
    this.companyService
      .getAllCompanySections()
      .subscribe(data => (this.allNavSections = data));
  }

  getSectionIcon(section: string): string {
    return this.pubMethods.renderNavSectionIcon(section);
  }

  showAllNavSections(): void {
    this.toggleAllNavSections = !this.toggleAllNavSections;
    this.isReordering = false;
  }

  handleNavStyle(sectionName: string): string {
    const lowerCaseName = sectionName.toLowerCase();

    const anomaliesMap: Partial<Record<CompanyPageSectionEnums, string>> = {
      'imports_&_exports': 'imports',
      latest_news: 'news',
      hiring_for: 'hiring_positions',
      events_attending: 'events',
      share_allotments: 'share_filings',
      industry_peer_comparison: 'industry_graphs',
      recent_legislation: 'legislations',
      legal_entity_identifier: 'lei_details',
      government_contracts_awarded: 'government_contracts',
      online_marketplaces: 'gcloud_services',
    };

    const graphsMap = {
      corporate_structure: 'TODO:',
      cross_sell_opportunities: 'TODO:',
    };

    /* 
    This simpleStringsForLengthCheck array contains only those keys from CompanyPageSectionEnums that are NOT found in either permissionsCheckMap or anomaliesMap or graphsMap.
     */
    const simpleStringsForLengthCheck: string[] = Object.values(
      CompanyPageSectionEnums
    ).filter(
      key =>
        !Object.keys(this.permissionsCheckMap).includes(key) &&
        !Object.keys(anomaliesMap).includes(key) &&
        !Object.keys(graphsMap).includes(key)
    );

    /**
     * Then lengthCheckStr holds sectionName value IF it is a valid string from simpleStringsForLengthCheck OR an associated anomaly value from anomaliesMap.
     * e.g: key_people or domain (from 'website')
     */

    let lengthCheckStr =
      simpleStringsForLengthCheck.find(str => str === lowerCaseName) ||
      anomaliesMap[lowerCaseName];

    // console.log('nav check', lengthCheckStr, lowerCaseName);

    if (lowerCaseName === 'company_overview') {
      return '';
    } else if (lengthCheckStr === 'website') {
      return this.hasEmptyObjectValues(this.company?.website)
        ? 'nav-no-data'
        : '';
    } else if (lengthCheckStr) {
      return this.pubMethods.isEmptyObjectOrArray(this.company[lengthCheckStr])
        ? 'nav-no-data'
        : '';
    } else if (Object.keys(this.permissionsCheckMap).includes(lowerCaseName)) {
      return this.permissionsCheckMap[lowerCaseName] ? '' : 'nav-no-permission';
    } else return '';
  }

  showPremiumIcon(navName: string): boolean {
    /* returns true only if NEEDS permission but LACKS it */

    const lowerCaseNav = navName.toLowerCase();

    if (!this.permissionsCheckMap.hasOwnProperty(lowerCaseNav)) return false;

    if (this.permissionsCheckMap.hasOwnProperty(lowerCaseNav)) {
      // return the flipped boolean
      return !this.permissionsCheckMap[lowerCaseNav];
    }
  }

  reOrderNavSections(): void {
    let profilePageSelf = this;
    this.isReordering = true;

    this.tempReOrderingList = [...this.allNavSections];
    $('#reOrderSectionsModal').modal();

    $('#reOrderSectionsModal').on('hidden.bs.modal', function () {
      profilePageSelf.isReordering = false;
    });
  }

  saveReOrderedSections(): void {
    if (this.isReordering) {
      this.isReordering = false;
      this.allNavSections = [...this.tempReOrderingList];
      $('#reOrderSectionsModal').modal('hide');
    }
  }

  dragSort(event: CdkDragDrop<any>): void {
    moveItemInArray(
      this.tempReOrderingList,
      event.previousIndex,
      event.currentIndex
    );
  }

  collapseAllNavs(): void {
    if (!this.toggleAllNavSections) return;
    setTimeout(() => {
      this.toggleAllNavSections = false;
    }, 400);
  }

  getTagsTotal(): number {
    const {
      tags = [],
      team_member_tags = [],
      organisation_tags = [],
    } = this.userCustomProperties;

    const filtered = [
      ...tags,
      ...team_member_tags,
      ...organisation_tags,
    ].filter(tag => !tag.is_propensity);

    return filtered.length;
  }

  updateCustomColumnsDetails(): void {
    this.teamMembers = this.userCustomProperties?.team_members;
    if (this.userCustomProperties.custom_columns) {
      this.sortCustomProperties(this.userCustomProperties.custom_columns);

      this.userCustomProperties.custom_columns.forEach(col => {
        if (col.column_type === 'User') {
          col['options'] = this.teamMembers;
        }
      });
      // this.refreshZintGrowColumns();
    }
    // this.salesforceLinks = data.salesforce_links;
  }

  sortCustomProperties(customColumns: Record<string, any>[]): void {
    // Sort propensity columns to appear first
    customColumns.sort((a, b) => {
      if (a.is_propensity && !b.is_propensity) {
        return -1; // a first
      } else if (!a.is_propensity && b.is_propensity) {
        return 1; // a not first
      } else {
        return 0; // original order
      }
    });
  }

  getPropensityComponentStyle(
    scoreTag: Record<string, any>
  ): Record<string, any> {
    // prettier-ignore
    const styleObj = {
      'background-color':
        scoreTag?.propensity_component_weighting >= 0
          ? '#' + scoreTag?.colour
          : 'var(--zint-red-600)',
      opacity: this.propensityOpacityPipe.transform(
        scoreTag?.propensity_component_weighting
      ),
      cursor: this.showPropensitySearchLink(scoreTag) ? 'pointer' : 'auto',
    };

    return styleObj;
  }

  showPropensitySearchLink(propensityTag): boolean {
    const params = propensityTag.propensity_component_params;
    if (
      params.homepageSearchTerm ||
      params.fullWebsiteSearchTerm ||
      params.accountsSearchTerm ||
      params.newsContaining
    ) {
      return true;
    } else {
      return false;
    }
  }

  openSearchPreview(propensityTag): void {
    const params = propensityTag.propensity_component_params;

    if (params.homepageSearchTerm || params.fullWebsiteSearchTerm) {
      if (params.homepageSearchTerm) {
        this.fullWebsiteSearchTerm = params.homepageSearchTerm;
      }
      if (params.fullWebsiteSearchTerm) {
        this.fullWebsiteSearchTerm = params.fullWebsiteSearchTerm;
      }
      $(function () {
        $('#fullWebsiteSearchModal').modal();
      });
      this.fullWebsiteSearch();
    }

    if (params.accountsSearchTerm) {
      this.accountsSearchText = params.accountsSearchTerm;
      $(function () {
        $('#accountsSearchModal').modal();
      });
      this.accountsSearch();
    }

    // if (params.newsContaining) {
    //   if (params.newsSource === 'selfPublished') {
    //     this.companyService.scrollToSelectorOnceExists(
    //       'selfPublishedCompanyNews'
    //     );
    //     this.selfPublishedCompanyNewsSearchText = params.newsContaining;
    //     this.selfPublishedCompanyNewsSearch();
    //   } else {
    //     this.companyService.scrollToSelectorOnceExists('companyNews');
    //     this.companyNewsSearch = params.newsContaining;
    //     this.newsSearch();
    //   }
    // }
  }

  fullWebsiteSearch(): void {
    this.companyService
      .runFullWebsiteSearch(
        <string>this.companyId,
        <string>this.fullWebsiteSearchTerm
      )
      .subscribe(data => (this.fullWebsiteResults = data.matching_webpages));
  }

  accountsSearch(): void {
    this.companyService
      .searchCompanyAccounts(
        <string>this.companyId,
        <string>this.accountsSearchText,
        <string>this.accountsDateSelected
      )
      .subscribe(data => {
        this.accountsResults = data;
        this.accountsResults['accounts_results'].forEach(
          (account: Record<string, any>) => {
            account.processedPageNumber =
              this.processAccountsResultPageNumber(account);
          }
        );
      });
  }

  processAccountsResultPageNumber(
    accountObj: Record<string, any>
  ): string | number {
    if (!accountObj) return '';
    const { accounts_type, page_number } = accountObj;

    if (!accounts_type) return page_number;

    if (accounts_type === 'ocr') {
      const pageNumberPlusOne = Number(page_number) + 1;
      return pageNumberPlusOne;
    } else {
      return page_number;
    }
  }

  showUpdatedCustomAttrsMessage(): void {
    this.customAttrsUpdated = true;
    setTimeout(() => {
      this.customAttrsUpdated = false;
    }, 1500);
  }

  openInsightsModal(): void {
    $('#AiInsightsModal').modal();
  }

  generateAiPitch(): void {
    this.companyService.validateAIPitchBuilderSettings().subscribe(data => {
      if (data.settings_configured === true) {
        if (data.counter === 0) {
          this.pubMethods.showWarningMessageWithoutAutoDisappear(
            'You do not have any AI credits on your account. Please contact customer success to add more.'
          );
          zE('messenger', 'open');
        } else {
          //TODO: remove testStream
          this.testStream();
          return;
          this.getAiPitch();
        }
      } else {
        if (this.accountDetails.isSuperUser) {
          this.pubMethods.showWarningMessageWithoutAutoDisappear(
            'We were not able to auto-generate your AI configuration. Please navigate <a href="/pitch-builder">here</a> to configure.'
          );
        } else {
          this.pubMethods.showInfoMessage(
            'Please ask your superuser to configure your AI settings or contact customer success through chat for assistance.'
          );
        }
      }
    });
  }

  getAiPitch() {
    console.log('B4 -- loading status:', this.loadingStatus);
    this.loadingStatus['loadingAIPitch'] = true;
    const source = new EventSource(
      baseUrl + '/get_ai_pitch/' + this.companyId,
      { withCredentials: true }
    );
    source.addEventListener('message', message => {
      console.log('source message>>', message);
      if (message.data) {
        const data = JSON.parse(message.data);
        if (data.content) {
          this.companyAIPitch += data.content;
        }
      } else {
        this.loadingStatus['loadingAIPitch'] = false;
        source.close();
      }
    });

    source.addEventListener('error', e => {
      this.loadingStatus['loadingAIPitch'] = false;
      this.snackbar.open('Error generating pitch', 'Okay', {
        duration: 5000,
        verticalPosition: 'top',
      });
      source.close();
    });
  }

  // TODO: delete this mock stream
  testStream(): void {
    let currentIndex = 0;
    let textToStream = `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni nam voluptatibus dolorum incidunt ut deserunt, voluptatem libero sequi officiis recusandae eius rerum. Corporis odit deserunt magnam tempore veritatis, sapiente sequi. \n Lore...
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni nam voluptatibus
      `;

    this.companyAIPitch = '';
    let timer = setInterval(() => {
      this.loadingStatus.loadingAIPitch = true;
      if (currentIndex < textToStream.length) {
        this.companyAIPitch += textToStream[currentIndex];
        currentIndex += 1;
      } else {
        clearInterval(timer);
        this.loadingStatus.loadingAIPitch = false;
      }
    }, 20);
  }

  openSearchModal(openSearchType: string, panelId: string): void {
    const matPanel = this.findExpansionPanelById(panelId);
    matPanel.expanded = true;
    console.log('matPanel', matPanel);
    if (openSearchType === 'website-search') {
      this.openSearchModalTitle = 'Search Website';
      this.currentOpenSearchType = 'website-search';
    } else if (openSearchType === 'accounts-search') {
      this.openSearchModalTitle = 'Search Accounts';
      this.currentOpenSearchType = 'accounts-search';
    }

    // Open the modal
    const modalElement = document.getElementById('openSearchModal');
    if (modalElement) {
      $(modalElement).modal('show'); // Using jQuery to show the modal
    }
  }

  getAvailableFinancialTerms(): void {
    /**
     * Take all non-null keys of every financial objects and remove dupes.
     * Then remove non-line items e.g id and submission_details.
     */

    const nonLineItems = [
      'url_to_pdf_file',
      'url_to_xbrl_file',
      'submission_details',
      'has_been_indexed',
      'id',
      'made_up_to',
      'software_type',
      'average_number_employees_during_period',
    ];

    this.availableFinancialTerms = Array.from(
      new Set(
        this.company.financials.accounts?.flatMap(yearAccObj =>
          Object.keys(yearAccObj).filter(
            key => !nonLineItems.includes(key) && yearAccObj[key] !== null
          )
        )
      )
    );
  }

  isDate(val: string): boolean {
    if (!val) return;
    return val.toString().indexOf('-') >= 1;
  }

  processAssetsData(): void {
    /**
     * Loop through the asset_breakdown obj to collect keys for both tangible and intangible assets.
     * Based on the length of these keys arrays, return whether "both" or only "tangible", or only "intangible" assets are available.
     * The processed data is saved to availableAssetsData which is then used to dynamically populate the assets table header columns.
     */

    if (this.pubMethods.isEmptyObjectOrArray(this.company.asset_breakdown))
      return;

    let tangibleKeys = [];
    let intangibleKeys = [];

    Object.values(this.company.asset_breakdown).forEach(assetYearObj => {
      if (assetYearObj['tangible']) {
        Object.keys(assetYearObj['tangible']).forEach(key =>
          tangibleKeys.push(key)
        );
      }
      if (assetYearObj['intangible']) {
        Object.keys(assetYearObj['intangible']).forEach(key =>
          intangibleKeys.push(key)
        );
      }
    });

    let availableAssetType = '';

    if (!!tangibleKeys?.length && !!intangibleKeys?.length) {
      availableAssetType = 'both';
    } else {
      availableAssetType = tangibleKeys.length ? 'tangible' : 'intangible';
    }
    this.availableAssetsData = {
      availableAssetType,
      tangible: Array.from(new Set(tangibleKeys)),
      intangible: Array.from(new Set(intangibleKeys)),
    };
  }

  hasEmptyData(inputData: Record<string, any>): boolean {
    return this.pubMethods.isEmptyObjectOrArray(inputData);
  }

  hasEmptyObjectValues(inputObj: Record<string, any>): boolean {
    if (inputObj) {
      return Object.values(inputObj).every(value =>
        this.pubMethods.isEmptyObjectOrArray(value)
      );
    }
  }

  originalOrder(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    /*
    keep the original order of the object/dict with keyvalue pipe -
    negative values reverses order.
    https://v17.angular.io/api/common/KeyValuePipe#parameters
    */
    return 0;
  }
}
