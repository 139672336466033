<main class="page-container">
  <header class="hero">
    <div *ngIf="company.company_overview?.registered_address_lat_long_and_zoom" class="map-logo-container">
      <aol-map [width]="'100%'" [height]="'200px'">
        <aol-interaction-default></aol-interaction-default>
        <aol-view [zoom]="company.company_overview?.registered_address_lat_long_and_zoom.zoom_level">
          <aol-coordinate [x]="company.company_overview?.registered_address_lat_long_and_zoom.lat_long[1]"
            [y]="company.company_overview?.registered_address_lat_long_and_zoom.lat_long[0]"
            [srid]="'EPSG:4326'"></aol-coordinate>
        </aol-view>
        <aol-layer-tile>
          <aol-source-osm></aol-source-osm>
        </aol-layer-tile>
        <aol-layer-vector *ngIf="company.company_overview.registered_address_lat_long_and_zoom.show_point">
          <aol-source-vector>
            <aol-feature>
              <aol-geometry-point>
                <aol-coordinate [x]="company.company_overview?.registered_address_lat_long_and_zoom.lat_long[1]"
                  [y]="company.company_overview?.registered_address_lat_long_and_zoom.lat_long[0]"
                  [srid]="'EPSG:4326'"></aol-coordinate>
              </aol-geometry-point>
              <aol-style>
                <aol-style-circle [radius]="5">
                  <aol-style-stroke [color]="'black'" [width]="width"></aol-style-stroke>
                  <aol-style-fill [color]="'red'"></aol-style-fill>
                </aol-style-circle>
              </aol-style>
            </aol-feature>
          </aol-source-vector>
        </aol-layer-vector>
      </aol-map>

      <div class="logo">
        <img *ngIf="company.company_overview?.domain" class alt="logo"
          src="https://www.google.com/s2/favicons?sz=64&domain={{company.company_overview?.domain}}">
        <span class="logo-text" *ngIf="!company.company_overview?.domain">{{company.company_overview?.name | companyDataPipe:
          'company logo'}}</span>
      </div>
    </div>
    <div class="title-container">
      <div class>
        <h2 class>{{company.company_overview?.name | titlecase}}
          <sup class="status-badge"
            [ngClass]="company.company_overview?.company_status === 'Active' ? 'badge-active' : 'badge-inactive'">
            <i *ngIf="company.company_overview?.company_status !== 'Active'" class="fal fa-exclamation-triangle"></i>
            {{company.company_overview?.company_status}}
          </sup>
          <span *ngIf="company?.has_viewed"><i class="far fa-eye fa-2xs ml-2"
              matTooltip="You have viewed this company previously."></i></span>
        </h2>
        <p class="company-summary">{{company.company_overview?.summary}}</p>
      </div>
      <div class="socials">
        <a [href]="company.company_overview?.facebook_url" target="_blank"><i class="fab fa-facebook-f" style="color: #1877f2;"></i></a>
        <a [href]="company.company_overview?.twitter_url" target="_blank"><i class="fab fa-twitter" style="color: #1da1f2;"></i></a>
        <a [href]="company.company_overview?.linkedin_url" target="_blank"><i class="fab fa-linkedin-in"
          style="font-size: 1.13rem; color:white; background-color: #0e76a8; border-radius: 50%; padding: 4px;"></i></a>
      </div>
    </div>

    <div class="hero-btns-container flex-column flex-sm-row">

      <button class="rounded-btn-base rounded-btn-vivid btn-p-xl"><i
          class="fa-kit fa-ai-growth mr-1"></i>Analyse</button>

      <button class="rounded-btn-base rounded-btn-white btn-p-xl"><i
          class="fal fa-seedling fa-sm mr-1"></i>Follow</button>

      <div class="btn-group tag-btn-group" role="group">
        <button type="button" class="btn" matTooltip="Tag Company"><i class="fal fa-tag fa-rotate-by mr-1" style="--fa-rotate-angle: 135deg;"></i></button>
        <button type="button" class="btn" matTooltip="Salesforce Export"><i class="fa-brands fa-salesforce"></i></button>
        <button type="button" class="btn" matTooltip="HubSpot Export"><i class="fa-brands fa-hubspot"></i></button>
      </div>

    </div>
  </header>

  <nav class="sticky-nav-wrapper" [ngClass]="{'sticky-nav-wrapper-all': toggleAllNavSections, 'nav-loading': loadingStatus.loadingCompanyProfileData}">
   
    <div class="nav-container" [ngClass]="{'nav-container-all': toggleAllNavSections}">
      <div *ngIf="!toggleAllNavSections" class="nav-items">        
        <h6 role="button" *ngFor="let nav of allNavSections" class="mr-4 nav-item-each"
          [ngClass]="handleNavStyle(nav)" [routerLink]="[]" [fragment]="nav+'_panel'">
          <i class="mr-1" [ngClass]="getSectionIcon(nav)"></i>
          {{nav | underscoreToUpper}}
          <i *ngIf="showPremiumIcon(nav)" matTooltip="Premium" matTooltipPosition="above" class="ml-1 fal fa-circle-dollar" (click)="$event.stopPropagation()"></i>
        </h6>      
      </div>
      <div class="see-all-navs-container" [ngClass]="{'no-gradient': toggleAllNavSections}">
        <h6 role="button" (click)="showAllNavSections()">See all
          <i class="ml-1 fas"
            [ngClass]="toggleAllNavSections ? 'fa-caret-up' : 'fa-caret-down'"></i>
        </h6>
        <button class="rounded-btn-base rounded-btn-vivid-outline btn-p-sm align-self-end"
          [ngClass]="{'reorder-btn-clicked': isReordering}" *ngIf="toggleAllNavSections"
          (click)="reOrderNavSections()">
          <i class="fal fa-bars-sort fa-xs"></i>&nbsp;Reorder
        </button>
      </div>

      <div *ngIf="toggleAllNavSections" class="nav-items-show-all"
        [ngClass]="{'bg-light': isReordering}"
        (mouseleave)="collapseAllNavs()"
        >
        <h6 role="button" *ngFor="let nav of allNavSections; let index = index"
          class="nav-item-each" [ngClass]="handleNavStyle(nav)"
          [routerLink]="[]" [fragment]="nav+'_panel'">
          <i class="mr-1" [ngClass]="getSectionIcon(nav)"></i>
          {{nav | underscoreToUpper}}    
          <i *ngIf="showPremiumIcon(nav)" matTooltip="Premium" matTooltipPosition="above" class="ml-1 fal fa-circle-dollar"
            (click)="$event.stopPropagation()"></i>
        </h6>
      </div>
    </div>
  </nav>

  @if (loadingStatus.loadingCompanyProfileData) {
    <div class="d-flex justify-content-center mt-5">
      <zint-loader size="large"></zint-loader>
    </div>
  }
  @else {  
  <section class="sections-container">
    <mat-expansion-panel id="company_overview_panel" togglePosition="before" class [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>
            <h4>Company Overview</h4>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="grid-container">
        <div class="grid-container-left-col">
          <div *ngIf="company.company_overview?.sic_codes.length" class="grid-item grid-sic-codes card border-rounded-05" 
          [ngClass]="{'grid-span-2': !userCustomProperties.hasSomeData}">
            <div class="text-info-card">
              <div id="companyCarousel" class="carousel slide custom-carousel"
                [ngStyle]="{'height': company.company_overview?.sic_codes?.length < 2 ? '6rem' : '8.5rem'}"
                data-ride="carousel" data-interval="false">
                <!-- indicators & controls -->
                <div class="controls-wrapper" [ngClass]="{'d-none': company.company_overview?.sic_codes?.length < 2}">
                  <a class="custom-control-left" href="#companyCarousel" data-slide="prev">
                    <i role="button" class="fas fa-caret-circle-left"></i>
                  </a>
                  <ol class="carousel-indicators">
                    <li *ngFor="let sicCode of company.company_overview?.sic_codes; let i = index"
                      [class.active]="i === 0"
                      data-target="#companyCarousel" [attr.data-slide-to]="i">
                    </li>
                  </ol>
                  <a class="custom-control-right" href="#companyCarousel" data-slide="next">
                    <i role="button" class="fas fa-caret-circle-right"></i>
                  </a>
                </div>

                <!-- carousel contents/slides -->
                <div class="carousel-inner">
                  <div *ngFor="let sicCode of company.company_overview?.sic_codes; let i = index"
                    class="carousel-item"
                    [ngClass]="{'active': i === 0}">
                    <div class="card">
                      <h6>{{sicCode.description}}</h6>
                      <span class>
                        <i class="fa-duotone fas fa-industry-windows mr-1"></i>
                        <b>{{sicCode.code}}</b> - {{sicCode.section}}
                      </span>
                    </div>
                  </div>
                </div>

              </div>
              <div class="keywords-container">
                <h5>Company Keywords</h5>
                @for (keyword of company.company_overview?.keywords; track $index) {
                  <span class="badge-light-grey">{{keyword}}</span>
                }
              </div>
            </div>
          </div>

          <div class="grid-custom-properties card border-rounded-05" [ngClass]="{'border-green-500': customAttrsUpdated, 'grid-span-2': !company.company_overview?.sic_codes.length}">
            <div class="m-auto" *ngIf="loadingStatus.loadingCompanyUserDetails">
              <zint-loader size="small"></zint-loader>
            </div>
              <div *ngIf="userCustomProperties.hasSomeData && !loadingStatus.loadingCompanyUserDetails" class="custom-props-container">
                <mat-expansion-panel *ngIf="getTagsTotal()" togglePosition="before">
                  <mat-expansion-panel-header>
                    <mat-panel-title><i class="far fa-tag mr-1"></i>Existing Tags</mat-panel-title>

                    <span class="badge-custom-props badge-propensity-score">{{getTagsTotal()}}</span>
                  </mat-expansion-panel-header>
                  <div>
                    <div class="mb-1">
                      @for (tag of userCustomProperties?.tags; let i = $index; track i) {

                        <span *ngIf="i === 0" class="text-grey-600">Your Tags:&nbsp;</span>

                        @if(!tag.is_propensity) {

                          <span class="badge-custom-props"
                          [ngStyle]="{'background-color': '#'+tag.colour}">{{tag.name}}</span>
                        }
                      }
                    </div>

                    <div class="mb-1">
                      @for (tag of userCustomProperties?.team_member_tags; let i = $index; track i) {

                      <span *ngIf="i === 0" class="text-grey-600">Colleagues Tags:&nbsp;</span>

                        @if(!tag.is_propensity){
                          <span class="badge-custom-props"
                            [ngStyle]="{'background-color': '#'+tag.colour}">{{tag.name}}</span>
                        }
                      }
                    </div>

                    <div class="mb-1">
                      @for (tag of userCustomProperties?.organisation_tags; let i = $index; track i) {
                      <span *ngIf="i === 0" class="text-grey-600">Shared Tags:&nbsp;</span>

                        @if(!tag.is_propensity){

                          <span class="badge-custom-props"
                            [ngStyle]="{'background-color': '#'+tag.colour}">{{tag.name}}</span>
                        }
                      }
                    </div>

                  </div>
                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="userCustomProperties?.cross_sell_reason"
                  togglePosition="before">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <i class="far fa-link mr-1"></i>
                      Cross Sell Connections
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <span class="text-grey-600">{{userCustomProperties.cross_sell_reason}}</span>
                </mat-expansion-panel>

                <div class="">
                  @for (customColumn of userCustomProperties?.custom_columns; let customColumnIndex = $index; track customColumnIndex)
                  {
                    <ng-container *ngIf="customColumn.is_propensity ? customColumn.value : true">
                      <mat-expansion-panel togglePosition="before" [expanded]="customColumn.is_propensity && customColumn.value">
                        <mat-expansion-panel-header>
                          <mat-panel-title>

                            @if(customColumn.is_propensity && customColumn.value){
                              <i class="far fa-star mr-1"></i>
                            }
                            @else if (customColumn.is_salesforce) {                        
                              <i class="fab fa-salesforce salesforce-icon mr-1"></i>
                            }
                            @else if (customColumn.is_lockable) {                          
                              <i class="fal fa-lock mr-1"></i>
                            }

                            <span class="custom-props-title">{{customColumn.name}}</span>

                          </mat-panel-title>

                          <span *ngIf="customColumn.is_propensity && customColumn.value"
                            class="badge-custom-props badge-propensity-score">{{ customColumn.value |
                            number:'1.1-1' }}&nbsp;%</span>

                        </mat-expansion-panel-header>

                        
                        @if (customColumn.is_propensity === true && customColumn.value) {

                          @for (scoreTag of userCustomProperties?.propensity_score_tags; track $index) {
                          <span
                            *ngIf="scoreTag?.propensity_column_id === customColumn.id"
                            class="badge-custom-props"
                            [matTooltip]="scoreTag?.propensity_component_annotation"
                            [ngStyle]="getPropensityComponentStyle(scoreTag)">
                            <i *ngIf="showPropensitySearchLink(scoreTag)" class="fal fa-external-link"></i>
                            {{scoreTag?.name}}
                          </span>
                          }
                        }
                        
                        
                        @if (customColumn.is_propensity === false) {
                          
                          <custom-column-edit [inputParams]="{
                            'customColumn': customColumn,
                            'companyIds': [company.id],
                            'displayTight': false,
                            'isSuperUser': accountDetails.isSuperUser,
                            'lockablePermittedEditValue': accountDetails.email,
                            'teamMembers': teamMembers,
                            'secondDatePickerIndex': customColumnIndex }"
                            (onCustomColumnSaved)="showUpdatedCustomAttrsMessage($event[0])"
                            >
                          </custom-column-edit>

                          @if(customColumn.is_lockable && (customColumn.is_locked_indefinitely || customColumn.unlock_datetime)) {
                            <div class="unlock-date">

                              @if (customColumn.is_locked_indefinitely && customColumn.value) {
                                <span>Locked</span>
                              }

                              @if (customColumn.unlock_datetime) { 
                                <span>Unlock Date:
                                {{customColumn.unlock_datetime | date:'d MMM y'}}
                                </span>
                              }
                            </div>
                          }

                          @if(customAttrsUpdated) {
                            <span class="text-grey-600">Updated 
                              <i class="fal fa-check"></i>
                            </span>
                          }
                          
                      }

                      </mat-expansion-panel>
                    </ng-container>
                  }
                </div>
              </div>
          </div>

          <div class="addresses-card card border-rounded-05"
            [ngClass]="{'grid-addresses': company.company_overview?.registered_address && company.company_overview?.trading_addresses}">

            <div class="registered-address-container">
              <h6 class="border-bottom px-3 py-2"><i
                  class="fal fa-map-marker-alt mr-1"></i>Registered Address</h6>
              <div class="px-3 py-2">
                <span class="line-break-text address-each d-inline-block">{{
                  company.company_overview?.registered_address }} </span>

              </div>
            </div>

            <div class="trading-address-container">
              <h6 class="border-bottom px-3 py-2 sticky-top"><i
                  class="fal fa-map-marker-alt mr-1"></i>Trading Addresses</h6>
              <div class="trading-addresses-list">

                <div *ngFor="let ta of company.company_overview?.trading_addresses;" class="address-each">

                  <span class="line-break-text">{{ ta }}</span>

                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="grid-item grid-company-snapshot card border-rounded-05">
          <table class="snapshot-table">
            <tr>
              <td><i class="fal fa-temperature-list"></i>Heat score</td>
              <td>
                @for (item of [1,2,3]; track $index) {
                <i class="far fa-pepper-hot"></i>
                }
              </td>
            </tr>

            <tr *ngIf="company.company_overview?.number">
              <td><i class="fal fa-hashtag"></i>Company Number</td>
              <td>{{ company.company_overview?.number }}</td>
            </tr>

            <tr *ngIf="company.company_overview?.quarter_statement">
              <td><i class="fal fa-circle-three-quarters"></i>Quarter</td>
              <td>{{ company.company_overview?.quarter_statement }}</td>
            </tr>

            <tr *ngIf="company.company_overview?.age">
              <td><i class="fal fa-stopwatch"></i>Age</td>
              <td>{{ company.company_overview?.age }} old</td>
            </tr>

            <tr *ngIf="company.company_overview?.ubos && company.company_overview?.ubos.length > 0">
              <td><i class="fal fa-cubes"></i>UBOs</td>
              <td>
                @for (ubo of company.company_overview?.ubos; let i = $index; track i) {
                
                  <div style="display:inline" [ngStyle]="{'margin-left': (i > 0)? '44px' : '0px' }">
                    <a href="/company/{{ubo.company_number}}" target="_blank"
                      *ngIf="ubo.company_number">
                      <span [ngClass]="'flag-icon flag-icon-' + ubo.country_code"
                        style="margin-left: 5px; margin-right: 5px"
                        matTooltipPosition="below" matTooltip="{{ ubo.country_name }}"></span> {{
                      ubo.name }} <br>
                    </a>
                    @if (!ubo.company_number) {
                      <span [ngClass]="'flag-icon flag-icon-' + ubo.country_code"
                        style="margin-left: 5px; margin-right: 5px"
                        matTooltipPosition="below" matTooltip="{{ ubo.country_name }}"></span> {{
                      ubo.name }} <br>
                    }
                  </div>
                }
              </td>
            </tr>

            <tr *ngIf="company.company_overview?.phone_number">
              <td><i class="fal fa-phone"></i>Phone</td>
              <td> {{ company.company_overview?.phone_number }}</td>
            </tr>

            <tr *ngIf="company.company_overview?.email">
              <td><i class="fal fa-envelope"></i>Email</td>
              <td> {{ company.company_overview?.email }} </td>
            </tr>

            <tr *ngIf="company.company_overview?.headcount">
              <td><i class="fal fa-users"></i>Headcount</td>
              <td>{{ company.company_overview?.headcount.total }} employees<span
                  *ngIf="company.company_overview?.headcount.is_estimated">, estimated</span> <span
                  *ngIf="company.company_overview?.is_in_group"> (is part of a
                  group)</span></td>
            </tr>

            <tr *ngIf="company.company_overview?.url || company.company_overview?.domain">
              <td><i class="fal fa-globe"></i>Website</td>
              <td><a href="{{ company.company_overview?.url }}" target="_blank"> {{
                  company.company_overview?.domain }}</a></td>
            </tr>

            <tr *ngIf="company.company_overview?.revenue">
              <td><i class="fa-light fa-money-bill"></i>Revenue</td>
              <td>{{ company.company_overview?.revenue }}</td>
            </tr>

            <tr *ngIf="company.company_overview?.assets_growth_percentage">
              <td><i class="fal fa-chart-line-up-down"></i>Assets Growth</td>
              <td><span class="badge"
                  [ngClass]="{'badge-danger' : company.company_overview?.assets_growth_percentage < 0, 'badge-success' : company.company_overview?.assets_growth_percentage > 0}">{{
                  company.company_overview?.assets_growth_percentage.toPrecision(2) | number }}%</span></td>

            </tr>

            <tr *ngIf="company.company_overview?.revenue_growth_percentage">
              <td><i class="fal fa-money-bill-trend-up"></i>Revenue Growth</td>
              <td><span class="badge"
                  [ngClass]="{'badge-danger' : company.company_overview?.revenue_growth_percentage < 0, 'badge-success' : company.company_overview?.revenue_growth_percentage > 0}">{{
                  company.company_overview?.revenue_growth_percentage.toPrecision(2) | number }}%</span></td>
            </tr>

            <tr *ngIf="company.company_overview?.gross_profit_growth_percentage">
              <td><i class="fal fa-chart-mixed-up-circle-dollar"></i>Gross Profit Growth</td>
              <td><span class="badge"
                  [ngClass]="{'badge-danger' : company.company_overview?.gross_profit_growth_percentage < 0, 'badge-success' : company.company_overview?.gross_profit_growth_percentage > 0}">{{
                  company.company_overview?.gross_profit_growth_percentage.toPrecision(2) | number }}%</span></td>
            </tr>
          </table>
        </div>
      </div>

    </mat-expansion-panel>

    @if (featurePermissions.hasAiAccountsSummaryAccess) {
      <mat-expansion-panel id="strategic_insights_panel" togglePosition="before" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              <h4>Strategic Insights</h4>
            </div>
          </mat-panel-title>
          <button *ngIf="!hasEmptyData(company.strategic_insights)" class="rounded-btn-base rounded-btn-light text-grey-600" matTooltip="Full summaries of available year accounts"
            (click)="openInsightsModal(); $event.stopPropagation();">Read more</button>
        </mat-expansion-panel-header> 
            @if (hasEmptyData(company.strategic_insights)) {
              <span class="text-grey-600">No summaries have been generated yet.</span>
            }
            
            @if (company.strategic_insights && company.financials.accounts) {      
              <ai-accounts-summaries 
              [aiAccountsSummaries]="company.strategic_insights"
              [financials]="company.financials.accounts" 
              [companyId]="companyId">
              </ai-accounts-summaries>
            }
      </mat-expansion-panel>
  } 

  
    <mat-expansion-panel id="key_people_panel" togglePosition="before" [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>
            <h4>Key People</h4>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <span>{{company.key_people?.length}}&nbsp;people</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      
      <div class="row justify-content-around key-people-container">
        @for (person of company.key_people; let count = $count; track $index) {

          @if ($index <= 6 ) {
            <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-30">              
              <key-people [person]="person" [personIndex]="$index"></key-people>              
            </div>
          }

          @if ($index === 7 && count > 8) {
            <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-30">
              <key-people [showAllPeople]="true" [totalCount]="count"></key-people>
            </div>
          }

          @if ($index === 7 && count === 8) {
            <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-30"> 
              <key-people [person]="person" [personIndex]="$index"></key-people>         
            </div>
          }
          
        }        
      </div>
    </mat-expansion-panel>

    @if (featurePermissions.hasAiPitchBuilderAccess) {
      <mat-expansion-panel id="pitch_builder_panel" togglePosition="before" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              <h4>Bespoke Analysis with Zint AI</h4>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        @if (loadingStatus.loadingAIPitchBuilderSettings) {
          <zint-loader size="small"></zint-loader>
          <span>Configuring...</span>
        }
        @else if (!loadingStatus.loadingAIPitchBuilderSettings) {
          <button type="button" class="rounded-btn-base rounded-btn-vivid btn-p-lg" (click)="generateAiPitch()"
            [attr.disabled]="loadingStatus.loadingAIPitch ? 'disabled': null">
            Generate AI pitch
            <zint-loader *ngIf="loadingStatus.loadingAIPitch === true" size="xxSmall" dotColor="white" [isInline]="true"></zint-loader>
          </button>
        }
      
        @if (companyAIPitch) {
        <div class="card max-height-75vh border-rounded-05 mt-3">
          <div class="card-body" [innerText]="companyAIPitch"></div>
        </div>
        }
      </mat-expansion-panel>
    }
    
    @if (!hasEmptyObjectValues(company.website)) {
      <mat-expansion-panel id="website_panel" togglePosition="before" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              <h4>Website</h4>
            </div>
          </mat-panel-title>
          <button class="rounded-btn-base rounded-btn-light text-grey-600 btn-p-sm"
            (click)="openSearchModal('website-search', 'website_panel'); $event.stopPropagation()">
            <i class="fal fa-search"></i>            
            Search this website
          </button>
        </mat-expansion-panel-header>
        
          <div class="website-section-container">

            <div class="website-left-col">
              <img src="{{ company.website?.website_screenshot }}"
                *ngIf="company.website_screenshot">

                <div class="website-snapshot-container">
                  <table class="snapshot-table">
                    <tr>
                      <td>Website</td>
                      <td>
                        <a href="{{ company.website?.url }}" target="_blank">{{ company.website?.domain }}</a>
                      </td>
                    </tr>

                    @if (company.website?.associated_websites && company.website?.associated_websites.length > 0) {
                      <tr>
                        <td>Associated Websites</td>
                        <td>
                        @for (site of company.website?.associated_websites; track $index) {
                          <a href="{{ site.url }}" target="_blank" class="mr-2">{{ site.domain_name
                            }}</a>
                        }
                        </td>
                      </tr>
                    }
                    <tr *ngIf="company.website?.monthly_website_visitors">
                        <td>Monthly Visitors</td>
                        <td>{{ company.website?.monthly_website_visitors | number }}</td>
                    </tr>
                  </table>
                  
                </div>
            </div>

            <div *ngIf="company.website?.web_technologies" class="website-right-col">
              <h6>Website Technologies</h6>
                <table class="snapshot-table">
                  @for (web_tech_cat_to_techs of company.website?.web_technologies | mapToIterable; track $index) {
                    <tr>
                      <td>{{web_tech_cat_to_techs.key}}</td>

                      <td class="pt-2">
                      @for (tech of web_tech_cat_to_techs.val; track $index) {
                          <span class="badge-web-tech">
                            <img src="/assets/tech-icons/{{ tech.icon }}" onError="this.src='/assets/tech-icons/tachometer-alt.png'" style="height: 16px">
                            {{ tech.name }} 
                            <span *ngIf="tech.version">(Version {{ tech.version
                              }})
                            </span>
                          </span>
                        }
                      </td>
                    </tr>
                  }
                </table>            
            </div>
          </div>
      
      </mat-expansion-panel>
  }

  @if (company.financials?.accounts) {
    <mat-expansion-panel id="financials_panel" togglePosition="before" [expanded]="false">
      <mat-expansion-panel-header id="financials_panel_header">
        <mat-panel-title>
          <div>
            <h4>Financials</h4>
          </div>
        </mat-panel-title>
          <button class="rounded-btn-base rounded-btn-light text-grey-600 btn-p-sm" (click)="openSearchModal('accounts-search', 'financials_panel'); $event.stopPropagation()">
            <i class="fal fa-search"></i>
            Search Company Accounts
          </button>
      </mat-expansion-panel-header>

      <div class="financials-table-container" [ngClass]="{'scrollbar-show': availableFinancialTerms.length > 13}">
        <table class="table financials-table">
          <thead>
            <tr class="header-row">
              <th scope="col" class="sticky-col">Line Item</th>
              @for (yearAcc of company.financials.accounts; track $index) {
                <th scope="col">
                  <a [href]="yearAcc.url_to_xbrl_file || yearAcc.url_to_pdf_file" 
                  target="_blank" 
                  class="text-reset"
                  [matTooltip]="yearAcc.submission_details || 'View Accounts'"
                  >Y.E.&nbsp;{{yearAcc.made_up_to | date: 'd MMM y'}}                  
                    <i class="fal fa-external-link text-primary"></i>
                  </a>
                </th>              
              }
            </tr>
          </thead>
          <tbody>
            
            @for (item of availableFinancialTerms; track $index) {                    
              <tr>    
                <th scope="row" class="sticky-col">{{item | underscoreToUpper}}</th>
                
                @for (yearAcc of company.financials.accounts; track $index) { 
                  @if (yearAcc[item] === null) {
                    <td class="">&#65343;&#65343;&#65343;&#65343;</td>                                      
                  }
                  @else if (isDate(yearAcc[item])) {
                    <td>{{yearAcc[item] | date: 'd MMM y'}}</td>                    
                  } 
                  @else {
                    <td>{{yearAcc[item] |
                    currency:'GBP':'symbol-narrow':'.0'}}</td>
                  }              
                }
              </tr>
            }                           
          </tbody>
        </table>        
      </div>
      @if (company.amount_raised) {
        <div class="financials-table-container mt-3">
        <h5>Fundraising Details:</h5>
        <span>We believe this company has raised investment of approximately {{
        company.amount_raised.toPrecision(3) | currency:'GBP':'symbol-narrow':'.0' }}</span>
        </div>
      }
    </mat-expansion-panel>
  }

  @if (!hasEmptyObjectValues(company.asset_breakdown)) {
    <mat-expansion-panel id="asset_breakdown_panel" togglePosition="before" [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>
            <h4>Assets Breakdown</h4>
          </div>
        </mat-panel-title>      
      </mat-expansion-panel-header>
    
      @if (availableAssetsData.availableAssetType === 'both') {
        @for (assetType of ['tangible', 'intangible']; track $index) {
          <mat-expansion-panel togglePosition="before" expanded>
            <mat-expansion-panel-header>
              {{assetType | titlecase}} Assets
            </mat-expansion-panel-header>

            <div class="financials-table-container max-height-40vh" [ngClass]="{'scrollbar-show': availableAssetsData[assetType]?.length > 6}">
              <table class="table financials-table table-layout-fixed">
                <thead>
                  <tr class="header-row">
                    <th scope="col" class="sticky-col">Assets</th>
                    @for (kV of company.asset_breakdown | keyvalue: originalOrder; track $index) {
                      <th>Y.E.&nbsp;{{kV.key | date: 'd MMM y'}}</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  @for (term of availableAssetsData[assetType]; track $index) {
                    <tr>
                      <th scope="row" class="sticky-col">
                        {{term | underscoreToUpper}}
                      </th>
      
                      @for (yearAccKV of company.asset_breakdown | keyvalue; track $index) {
                        @if (yearAccKV.value[assetType] && yearAccKV.value[assetType][term]) {
                          <td>{{yearAccKV.value[assetType][term] | companyDataPipe: 'Assets'}}</td>                        
                        }
                        @else {
                          <td>&#65343;&#65343;&#65343;</td>
                        }
                      }
                    </tr>
                  }                
                </tbody>
              </table>            
            </div>
          </mat-expansion-panel>
        }
      }
      @else {
        <div class="financials-table-container max-height-50vh" [ngClass]="{'scrollbar-show': availableAssetsData[availableAssetsData.availableAssetType]?.length > 8}">
          <table class="table financials-table">
            <thead>
              <tr class="header-row">
                <th scope="col" class="sticky-col">{{availableAssetsData.availableAssetType | titlecase}} Assets</th>
                @for (kV of company.asset_breakdown | keyvalue; track $index) {
                <th>Y.E.&nbsp;{{kV.key | date: 'd MMM y'}}</th>
                }
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="availableAssetsData.availableAssetType as onlyAssetType">
                @for (term of availableAssetsData[onlyAssetType]; track $index) {
                <tr>
                  <th scope="row" class="sticky-col">
                    {{term | underscoreToUpper}}
                  </th>
          
                  @for (yearAccKV of company.asset_breakdown | keyvalue: originalOrder; track $index) {
                  @if (yearAccKV.value[onlyAssetType] && yearAccKV.value[onlyAssetType][term]) {
                  <td>{{yearAccKV.value[onlyAssetType][term] | companyDataPipe: 'Assets'}}</td>
                  }
                  @else {
                  <td>&#65343;&#65343;&#65343;&#65343;</td>
                  }
                  }
                </tr>
                }
              </ng-container>
            </tbody>
          </table>
        </div>
      }
    </mat-expansion-panel>
  }

  @if (company.geographical_revenue?.length) {
    <mat-expansion-panel togglePosition="before" id="geographical_revenue_panel" [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>
            <h4>Geographical Revenue</h4>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="financials-table-container">
        <table class="table financials-table table-layout-fixed">
          <thead>
            <tr class="header-row">
              <th scope="col" class="sticky-col">Regions</th>
              <th scope="col">Revenue</th>
            </tr>
          </thead>
          <tbody>
            @for (item of company.geographical_revenue; track $index) {
              <tr>
                <th scope="row" class="sticky-col">{{item.name}}</th>
                <td>{{item.revenue | companyDataPipe: 'Revenue'}}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </mat-expansion-panel>
  }

  </section>
}
</main>


<!--******************* Modals and modal components ******************/-->

<div class="modal fade" id="reOrderSectionsModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Reorder Sections</h4>
      </div>
      <div class="modal-body">
        <p>Reorder the sections by dragging items up or down.</p>
        <div cdkDropList
          class="reorder-list"
          (cdkDropListDropped)="dragSort($event)">
          @for (item of tempReOrderingList; track item; let index = $index) {
          <div class="reorder-item-box"
            cdkDrag>
            <span>{{index + 1}}</span>
            <span>
              <i class="mr-1" [ngClass]="getSectionIcon(item)"></i>
              {{item | underscoreToUpper}}
            </span>
          </div>
          }
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="rounded-btn-base rounded-btn-vivid btn-p-md"
          (click)="saveReOrderedSections()">Save changes</button>
      </div>
    </div>
  </div>
</div>

<ai-insights-modal *ngIf="company.financials?.accounts && company.strategic_insights" [financials]="company.financials.accounts"
  [aiAccountsSummaries]="company.strategic_insights" [companyId]="companyId"></ai-insights-modal>

<div class="modal" id="allKeyPeopleModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xxl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Key People</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="row justify-content-around overflow-auto mx-0 mt-4">
        @for (person of company.key_people; track $index) {
          <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-30">
            <key-people [person]="person" [personIndex]="$index"></key-people>
          </div>
        }
      </div>
      <div class="modal-footer">
        <button type="button" class="rounded-btn-base rounded-btn-vivid btn-p-lg" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="openSearchModal" tabindex="-1" aria-labelledby="modalTitle" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalTitle">{{ openSearchModalTitle }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container [ngSwitch]="currentOpenSearchType">          
          <div *ngSwitchCase="'website-search'">            
            <h6><b>Search on this website</b></h6>
            <input type="text" class="search-website-container full-width" placeholder="enter search term..." maxlength="100" [(ngModel)]="fullWebsiteSearchTerm" (keyup.enter)="fullWebsiteSearch()">
            <button class="float-right my-2 rounded-btn-base rounded-btn-vivid btn-p-lg"><i class="fal fa-search mr-1"></i>Search</button>
          </div>
          <div *ngSwitchCase="'accounts-search'">
            <!-- Accounts Search Content -->
            <input type="text" class="search-website-container full-width" placeholder="enter search term..." maxlength="100">
            <button class="float-right my-2 rounded-btn-base rounded-btn-vivid btn-p-lg"><i
                class="fal fa-search mr-1"></i>Search</button>
            <!-- Add more dynamic content here -->
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="rounded-btn-base btn-p-lg" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
