<div class="modal fade" id="exportToHubspotModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exportToHubspotModalLabel"><i class="fab fa-hubspot"></i> Export to HubSpot</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body hs-export-modal-body">
                <div class="row" *ngIf="loadingStatus.loadingHubspotExportStatus">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-center">
                            <zint-loader size="xSmall"></zint-loader>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!loadingStatus.loadingHubspotExportStatus">
                    <div class="col-md-12">

                        @if (haveHubspotMatches) {
                            <p class="text-center">We found the following records in HubSpot already:</p>

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input type="checkbox" name="selectAllHubspotExistingRecords" [(ngModel)]="allExistingChecked" (change)="toggleAllExistingChecked()"/>
                                        </th>
                                        <th scope="col">Company Number (Zint)</th>
                                        <th scope="col">Matching Record Name (Hubspot)</th>
                                        <th scope="col">HubSpot Record ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (hubspotMatchDetails of hubspotMatches | keyvalue; track $index) {
                                        @for (hubspotMatch of hubspotMatchDetails.value; track $index) {
                                        <tr>
                                            <td>
                                                <input type="checkbox" name="selectHubspotRecordToUpdate" [(ngModel)]="hubspotMatch.checked"/>
                                            </td>
                                            <td>{{hubspotMatchDetails.key}}</td>
                                            <td>{{hubspotMatch.name}}</td>
                                            <td><a href="{{hubspotMatch.zint_to_hubspot_record_url}}" target="_blank" class="badge badge-info">{{hubspotMatch.hs_object_id}} <i class="fal fa-external-link"></i></a></td>
                                        </tr>
                                        }
                                    }
                                </tbody>
                            </table>

                            <div class="row">
                                <div class="col">
                                    <button class="rounded-btn-base rounded-btn-light btn-p-sm xsmall-margin-bottom" [attr.disabled]="loadingStatus.updatingHubspotRecords ? 'disabled': null" matTooltipPosition="above" matTooltip="&#9888; Uses your export quota" (click)="updateHubspotRecords()">Update Hubspot Records For Selected &nbsp; <zint-loader size="xxSmall" isInline="true" *ngIf="loadingStatus.updatingHubspotRecords"></zint-loader></button>
                                </div>
                            </div>
                        }

                        @else {
                            <p class="text-center">No existing records were found in HubSpot.</p>
                        }


                        <hr class="medium-margin-bottom medium-margin-top">

                        @if (noHubspotMatches?.length) {
                            <p class="text-center">The following companies were not found in HubSpot:</p>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input type="checkbox" name="selectAllNewRecords" [(ngModel)]="allNewChecked" (change)="toggleAllNewChecked()"/>
                                        </th>
                                        <th scope="col">Company Name (Zint)</th>
                                        <th scope="col">Company Number (Zint)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (noHubspotMatch of noHubspotMatches; track $index) {
                                        <tr>
                                            <td>
                                                @if (!newlyCreatedCompanyNumbersToHubspotObjects[noHubspotMatch.company_number]){
                                                    <input type="checkbox" name="selectHubspotRecordToCreate" [(ngModel)]="noHubspotMatch.checked"/>
                                                }
                                            </td>
                                            <td>{{noHubspotMatch.company_name}}</td>
                                            <td>
                                                {{noHubspotMatch.company_number}}
                                                @if (newlyCreatedCompanyNumbersToHubspotObjects[noHubspotMatch.company_number]){
                                                    <a href="{{this.newlyCreatedCompanyNumbersToHubspotObjects[noHubspotMatch.company_number]['zint_to_hubspot_record_url']}}" target="_blank">
                                                        <span class="float-right badge badge-info"><i class="fal fa-check-circle"></i> New Record ID {{this.newlyCreatedCompanyNumbersToHubspotObjects[noHubspotMatch.company_number]['hs_object_id']}}</span>
                                                    </a>
                                                }
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <div class="row">
                                <div class="col">
                                    <button class="rounded-btn-base rounded-btn-light btn-p-sm xsmall-margin-bottom" [attr.disabled]="loadingStatus.creatingHubspotRecords ? 'disabled': null"  matTooltipPosition="above" matTooltip="&#9888; Uses your export quota" (click)="createHubspotRecords()">Create New Hubspot Records For Selected <zint-loader size="xxSmall" isInline="true" *ngIf="loadingStatus.creatingHubspotRecords"></zint-loader></button>
                                </div>
                            </div>
                        }

                        @else {
                            <p class="text-center">All the selected records were found in HubSpot</p>
                        }

                    </div>
                </div>


            </div>
        </div>
    </div>
</div>
