<div *ngFor="let searchTag of inputParams.searchTags" style="display: inline">
    <span class="badge badge-info custom-badge xsmall-margin-right" *ngIf="searchTag.value && searchTag.value != 'false' && searchTag.value != 'null'" [ngClass]="{'highlighted-badge': searchTag.param === 'selectedTerritory'}">
        <span *ngIf="searchTag.value == 'true'"><i class="fal fa-check-circle"></i> </span>
        <span style="font-weight: 900 !important"> {{ searchTag.param | camelCaseToProper }}</span>
        <span *ngIf="searchTag.value != 'true' && searchTag.param != 'selectedCustomColumns'" style="max-width: 100px">:
            <ng-container *ngIf="searchTag.param === 'selectedTerritory'">
                {{ searchTag.value | nameOnlyDisplay }}
            </ng-container>
            <ng-container *ngIf="searchTag.param !== 'selectedTerritory'">
                {{ searchTag.value }}
            </ng-container>
        </span>
        <span *ngIf="searchTag.param == 'selectedCustomColumns'">: {{ searchTag.value | customColumnDisplay }}</span>
        <span *ngIf="inputParams.enableClearing && searchTag.param != 'pageNumber'" (click)="clearParameterButtonWasClicked(searchTag)" class="cursor-pointer badge-close-button"><i class="fal fa-times"></i></span>
    </span>
</div>
<ng-content></ng-content>
