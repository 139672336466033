<div class="card-header d-flex flex-column flex-sm-row justify-content-between">    
    <div class="d-flex align-items-center">
        <h4 [ngClass]="{'card-header-h4-pre-power-tool': inputParams.isRestricted, 'selectedTitle': isCorporateStructure && isToggledOn}">{{ inputParams.sectionTitle }}
        </h4>
        <mat-slide-toggle class="icon-slide-toggle mx-2" *ngIf="inputParams.sectionTitle?.toLowerCase().includes('corporate')" color="primary" [matTooltip]="toggleTooltip" [disableRipple]="true" [disabled]="inputParams.isRestricted" (change)="switchCardTitle($event)"></mat-slide-toggle>
        <h4 *ngIf="isCorporateStructure" [ngClass]="{'card-header-h4-pre-power-tool': inputParams.isRestricted, 'selectedTitle': isCorporateStructure && !isToggledOn}">Corporate Clusters:
        </h4>
    </div>
    <button *ngIf="isCorporateStructure && isToggledOn" class="basic-button-base" data-toggle="modal" data-target="#fullClustersModal" (click)="showFullClustersGraph()">Show Full Clusters</button>
    <a *ngIf="isCorporateStructure && !isToggledOn" class="basic-button-base button-dark" href="/dashboard?corporateStructureRelatedCompanies={{inputParams.companyNumber}}" target="_blank">View As List</a>
    <h4 *ngIf="inputParams.isRestricted" class="power-tool-header"><i class="fal fa-lock-alt"></i> Premium Feature</h4>
</div>
<div class="card-body" [ngClass]="{'no-padding': inputParams.noPadding, 'should-not-be-restricted': !inputParams.isRestricted, 'card-body-overflow': !inputParams.doubleHeight, 'card-body-overflow-double': inputParams.doubleHeight}">
    <div *ngIf="inputParams.isRestricted" class="speak-to-sales-popover">
        <span class="sample-data-shown-text"><i class="fal fa-lock-alt"></i> Sample Data Shown</span>
        <a class="basic-button-base button-light" href="mailto:sales@zint.io?Subject=Zint%20Demo%20Request" target="_blank">Book A Demo</a>
    </div>
    <div [ngClass]="{'blurred-text': inputParams.isRestricted}">
        <ng-content></ng-content>
    </div>
</div>
