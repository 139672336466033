<div *ngIf="isLoading" class="d-flex justify-content-center align-items-center">    
    <zint-loader></zint-loader>
</div>

<div class="container-fluid company-page-container" *ngIf="company">    
    <div class="row">
        <div class="col-md-8 order-2 order-md-1 xsmall-margin-top">
            <aol-map [width]="'100%'" [height]="'300px'">
                <aol-interaction-default></aol-interaction-default>
                <aol-view [zoom]="company.registered_address_lat_long_and_zoom.zoom_level">
                    <aol-coordinate [x]="company.registered_address_lat_long_and_zoom.lat_long[1]"
                        [y]="company.registered_address_lat_long_and_zoom.lat_long[0]"
                        [srid]="'EPSG:4326'"></aol-coordinate>
                </aol-view>
                <aol-layer-tile>
                    <aol-source-osm></aol-source-osm>
                </aol-layer-tile>
                <aol-layer-vector *ngIf="company.registered_address_lat_long_and_zoom.show_point">
                    <aol-source-vector>
                        <aol-feature>
                            <aol-geometry-point>
                                <aol-coordinate [x]="company.registered_address_lat_long_and_zoom.lat_long[1]"
                                    [y]="company.registered_address_lat_long_and_zoom.lat_long[0]"
                                    [srid]="'EPSG:4326'"></aol-coordinate>
                            </aol-geometry-point>
                            <aol-style>
                                <aol-style-circle [radius]="5">
                                    <aol-style-stroke [color]="'black'" [width]="width"></aol-style-stroke>
                                    <aol-style-fill [color]="'red'"></aol-style-fill>
                                </aol-style-circle>
                            </aol-style>
                        </aol-feature>
                    </aol-source-vector>
                </aol-layer-vector>
            </aol-map>

            <div class="row">

                <div class="col-lg-4 d-flex">
                    <div class="flex-grow-1">

                        <div class="card">
                            <div class="card-header">
                                <h5>Registered Address</h5>
                            </div>
                            <div class="card-body"
                                [ngClass]="{'card-body-overflow-short' : company.trading_addresses && company.trading_addresses.length > 0, 'top-card-body-overflow' : !company.trading_addresses || company.trading_addresses.length == 0}">
                                <i class="fal fa-map-marker"></i> &nbsp;&nbsp;<span class="line-break-text">{{
                                    company.registered_address }}</span>
                            </div>
                        </div>
                        <div class="card" *ngIf="company.trading_addresses && company.trading_addresses.length > 0">
                            <div class="card-header">
                                <h5>Trading Addresses</h5>
                            </div>
                            <div class="card-body card-body-overflow-no-min-height"
                                [ngClass]="{'card-body-overflow-short' : company.trading_addresses && company.trading_addresses.length > 0}">
                                <div *ngFor="let ta of company.trading_addresses; let i=index">
                                    <i class="fal fa-map-marker"></i> &nbsp;&nbsp;<span class="line-break-text">{{ ta
                                        }}<br>
                                        <hr *ngIf="i != company.trading_addresses.length-1">
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="col-lg-8 d-flex">
                    <div class="card flex-grow-1">
                        <div class="card-header">
                            <h5 [ngClass]="{'card-header-h4-pre-power-tool': isRestricted}">Custom Properties <span
                                    *ngIf="customAttrsUpdated"> - Updated <i class="fal fa-check"></i></span></h5>
                            <h4 *ngIf="isRestricted" class="power-tool-header"><i class="fal fa-lock-alt"></i> Premium
                                Feature</h4>
                        </div>
                        <div class="card-body card-body-overflow">
                            <div *ngIf="!isRestricted" class="row">
                                <div class="col-lg-6">

                                    <div *ngIf="zintGrowColumns">
                                        <button
                                            *ngIf="!hasZintGrowAccess || zintGrowColumns.length === 0 ; else zintGrowButtons"
                                            class="basic-button-base button-call-to-action full-width"
                                            data-toggle="modal" data-target="#zintGrowVideoModal">
                                            <span>Follow in Zint Grow&nbsp;</span> <i class="fal fa-seedling"></i>
                                        </button>
                                        <ng-template #zintGrowButtons>
                                            <button
                                                *ngIf="zintGrowColumnsFollowing && zintGrowColumnsFollowing.length > 0; else followButton"
                                                class="basic-button-base button-dark full-width"
                                                (click)="unfollowCompanyInZintGrow()">
                                                <span>Unfollow in Zint Grow&nbsp;</span> <i class="fal fa-times"></i>
                                            </button>
                                            <ng-template #followButton>
                                                <button (click)="followCompanyInZintGrow()"
                                                    class="basic-button-base button-call-to-action full-width">
                                                    <span>Follow in Zint Grow&nbsp;</span> <i
                                                        class="fal fa-seedling"></i>
                                                </button>
                                            </ng-template>
                                        </ng-template>
                                        <hr>
                                    </div>


                                    <div *ngIf="hasSalesforceAccess && !hasSalesforceV2Access">
                                        <button
                                            class="basic-button-base button-dark btn vertically-centre-text full-width"
                                            data-toggle="modal" data-target="#salesforceModal">
                                            <span>Export&nbsp;</span> <i class="fab fa-salesforce salesforce-icon"></i>
                                        </button>
                                        <hr>
                                    </div>
                                    <div *ngIf="hasSalesforceV2Access">
                                        <ng-container *ngIf="salesforceLinks && salesforceLinks.length > 0">
                                            <ng-container *ngFor="let salesforceLink of salesforceLinks">
                                                <a class="basic-button-base xsmall-margin-bottom button-dark btn vertically-centre-text full-width btn-sm"
                                                    href="{{salesforceLink}}" target="_blank">
                                                    <span>View record on Salesforce</span>
                                                </a>
                                            </ng-container>
                                        </ng-container>
                                        <button
                                            class="basic-button-base button-dark btn vertically-centre-text full-width"
                                            data-toggle="modal" data-target="#exportToSalesforceModal">
                                            <span>Export&nbsp;</span> <i class="fab fa-salesforce salesforce-icon"></i>
                                        </button>
                                        <hr>
                                    </div>
                                    <div *ngIf="hasHubspotAccess">
                                        <button
                                            class="basic-button-base button-dark btn vertically-centre-text full-width"
                                            data-toggle="modal" data-target="#exportToHubspotModal">
                                            <span>Export&nbsp;</span> <i class="fab fa-hubspot"></i>
                                        </button>
                                        <hr>
                                    </div>


                                    <div *ngIf="companyTags && companyTags.length > 0">
                                        <ng-container *ngFor="let tag of companyTags; let i = index;">
                                            <span *ngIf="i == 0">Your Tags: </span>
                                            <span *ngIf="!tag.is_propensity" class="badge"
                                                [style.background-color]="'#'+tag.colour"
                                                style="color: white; margin-right: 7px;">
                                                <i class="fal fa-tag"></i> {{ tag.name }} {{ tag.duck }} <i
                                                    class="fal fa-times tag-delete-button" *ngIf="!tag.is_propensity"
                                                    (click)="removeTag(tag.tag_id)"></i>
                                            </span>
                                        </ng-container>
                                    </div>

                                    <div *ngIf="teamMemberTags && teamMemberTags.length > 0">
                                        <ng-container *ngFor="let teamTag of teamMemberTags; let i = index;">
                                            <span *ngIf="i == 0">Tags by colleagues: </span>
                                            <span *ngIf="!teamTag.is_propensity" class="badge"
                                                [style.background-color]="'#'+teamTag.colour"
                                                style="color: white; margin-right: 7px;"><i class="fal fa-tag"></i> {{
                                                teamTag.name }} </span>
                                        </ng-container>
                                    </div>

                                    <div *ngIf="organisationTags && organisationTags.length > 0">
                                        <ng-container *ngFor="let organisationTag of organisationTags; let i = index;">
                                            <span *ngIf="i == 0">Shared Tags: </span>
                                            <span *ngIf="!organisationTag.is_propensity" class="badge"
                                                [style.background-color]="'#'+organisationTag.colour"
                                                style="color: white; margin-right: 7px;"><i class="fal fa-tag"></i> {{
                                                organisationTag.name }} <i class="fal fa-times tag-delete-button"
                                                    *ngIf="organisationTag.can_edit"
                                                    (click)="removeTag(organisationTag.tag_id)"></i> </span>
                                        </ng-container>
                                    </div>

                                    <button class="basic-button-base button-dark small-margin-top full-width"
                                        data-toggle="modal" data-target="#tagModal">Tag Company</button>
                                    <hr>

                                    <div *ngIf="crossSellReason">
                                        <hr>
                                        <span><i class="fa fa-link"></i> Cross-Sell Connections: {{ crossSellReason
                                            }}</span>
                                        <hr>
                                    </div>
                                    <div *ngIf="hasViewed" class="small-margin-top"><span class="xsmall-margin-top"><i
                                                class="fal fa-eye"></i> You have viewed this company previously</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 border-left">
                                    <div
                                        *ngFor="let customColumn of customColumnDetails; let datePickerIndex = index; let secondDatePickerIndex = index">
                                        <ng-container *ngIf="customColumn.is_propensity && customColumn.value">
                                            <div class="card" style="padding: 10px;">
                                                <div class="card-content">
                                                    <span style="font-size: 16px; margin-bottom: 0px"><i
                                                            class="fal fa-star"></i> {{ customColumn.name }}:</span>
                                                    <span class="badge badge-info"
                                                        style="font-size: 16px; margin-left: 5px; background-color: #00516C">
                                                        {{ customColumn.value | number:'1.1-1' }} %
                                                    </span>
                                                    <hr>
                                                    <div *ngIf="propensityTags && propensityTags.length > 0">
                                                        <ng-container *ngFor="let propensityTag of propensityTags">
                                                            <span
                                                                *ngIf="propensityTag.propensity_column_id == customColumn.id"
                                                                class="badge propensity-component"
                                                                matTooltipPosition="above"
                                                                matTooltip="{{propensityTag.propensity_component_annotation}}"
                                                                [style.background-color]="propensityTag.propensity_component_weighting >= 0 ? '#'+propensityTag.colour : '#DC3545'"
                                                                [style.opacity]="propensityTag.propensity_component_weighting | propensityComponentOpacity"
                                                                [style.cursor]="showPropensitySearchLink(propensityTag) ? 'pointer' : ''"
                                                                style="color: white; margin-right: 7px;"
                                                                (click)="openSearchPreview(propensityTag)">
                                                                <i *ngIf="showPropensitySearchLink(propensityTag)"
                                                                    class="fal fa-external-link"></i>
                                                                {{ propensityTag.name }}
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!customColumn.is_propensity">
                                            <label style="font-size: 12px; margin-bottom: 0px">
                                                <ng-container *ngIf="customColumn.is_salesforce">
                                                    <i class="fab fa-salesforce salesforce-icon"></i>
                                                </ng-container>
                                                <ng-container *ngIf="customColumn.is_lockable">
                                                    <i class="fal fa-lock"></i>
                                                </ng-container>
                                                {{ customColumn.name }}:
                                            </label>
                                            <custom-column-edit [inputParams]="{
                                                        'customColumn': customColumn,
                                                        'companyIds': [company.id],
                                                        'displayTight': false,
                                                        'isSuperUser': isSuperUser,
                                                        'lockablePermittedEditValue': emailOnAccount,
                                                        'teamMembers': teamMembers,
                                                        'secondDatePickerIndex': secondDatePickerIndex
                                                        }"
                                                (onCustomColumnSaved)="showUpdatedCustomAttrsMessage($event[0])"></custom-column-edit>
                                            <ng-container
                                                *ngIf="customColumn.is_lockable && (customColumn.is_locked_indefinitely || customColumn.unlock_datetime)">
                                                <div style="margin-top: -20px; font-size: 12px; margin-bottom: 10px">
                                                    <ng-container
                                                        *ngIf="customColumn.is_locked_indefinitely && customColumn.value">
                                                        Locked</ng-container>
                                                    <ng-container *ngIf="customColumn.unlock_datetime"> Unlock Date:
                                                        {{customColumn.unlock_datetime | date:'d MMM y'}}</ng-container>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isRestricted" class="row">
                                <div class="col-lg-12">
                                    <ul>
                                        <li>Add searchable notes to organisations</li>
                                        <li>Bulk tag businesses and group them according to your own requirements</li>
                                        <li>Create and update your own filterable, searchable and customisable columns
                                        </li>
                                        <li>View your own personalised Propensity Score for each business, allowing you
                                            to quickly prioritise accounts.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>


        <div class="col-md-4 order-1 order-md-2  xsmall-margin-top">
            <div class="card" style="margin-top: 0px !important">
                <div class="card-header" style="background-color: #000a36 !important; color: white;">
                    <div *ngIf="company.url" style="display: flex; align-items:center; margin-bottom: 0.15rem">
                        <img id="company-favicon"
                            style="vertical-align:middle; height: 22px; width: 22px; margin-right: 5px; margin-top: 0.1rem"
                            src="https://www.google.com/s2/favicons?domain={{ company.url }}">
                        <h2 style="margin-bottom: 0">{{ company.name }} </h2>
                    </div>
                    <h2 *ngIf="!company.url">{{ company.name }} </h2>
                </div>
                <div class="card-body" style="max-height: none; overflow-x: auto;">
                    <table class="snapshot-table" style="width: 100%">
                        <tr *ngIf="heatScore">
                            <heat-score [heatScore]="heatScore"></heat-score>
                        </tr>
                        <tr *ngIf="company.company_status">
                            <td>
                                <span *ngIf="company.company_status == 'Active'">{{ company.company_status }}</span>
                                <span *ngIf="company.company_status != 'Active'" class="badge badge-danger"> <i
                                        class="fal fa-exclamation-triangle"></i> {{ company.company_status }}</span>
                            </td>
                        </tr>
                        <tr *ngIf="company.number">
                            <td>Company Number: {{ company.number }}</td>
                        </tr>
                        <tr *ngIf="company.quarter_statement">
                            <td>{{ company.quarter_statement }}</td>
                        </tr>
                        <tr *ngIf="company.age">
                            <td>{{ company.age }} old</td>
                        </tr>

                        <tr *ngIf="company.ubos && company.ubos.length > 0">
                            <td>
                                UBOs:
                                <ng-container *ngFor="let ubo of company.ubos; let i = index">
                                    <div style="display:inline" [ngStyle]="{'margin-left': (i > 0)? '44px' : '0px' }">
                                        <a href="/company/{{ubo.company_number}}" target="_blank"
                                            *ngIf="ubo.company_number">
                                            <span [ngClass]="'flag-icon flag-icon-' + ubo.country_code"
                                                style="margin-left: 5px; margin-right: 5px" matTooltipPosition="below"
                                                matTooltip="{{ ubo.country_name }}"></span> {{
                                            ubo.name }} <br>
                                        </a>
                                        <ng-container *ngIf="!ubo.company_number">
                                            <span [ngClass]="'flag-icon flag-icon-' + ubo.country_code"
                                                style="margin-left: 5px; margin-right: 5px" matTooltipPosition="below"
                                                matTooltip="{{ ubo.country_name }}"></span> {{
                                            ubo.name }} <br>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div *ngFor="let sic of company.sic_codes">
                                    <span class="badge badge-info" *ngIf="sic.section"><i class="fal fa-industry-alt"
                                            style="margin-right:3px"></i> {{ sic.code }} {{ sic.section }}</span><br> -
                                    <span style="font-size: 12px">{{ sic.description }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="company.keywords && company.keywords.length > 0">
                            <span class="badge badge-info" style="margin-right: 7px"
                                *ngFor="let keyword of company.keywords">{{ keyword }}</span>
                        </tr>
                        <tr *ngIf="company.summary">
                            <td style="font-style:italic; font-size: 12px; padding-top: 10px; padding-bottom: 10px">{{
                                company.summary }}</td>
                        </tr>
                        <tr *ngIf="company.phone_number">
                            <td><i class="fal fa-phone"></i> {{ company.phone_number }}</td>
                        </tr>
                        <tr *ngIf="company.email">
                            <td><i class="fal fa-envelope"></i> {{ company.email }} </td>
                        </tr>
                        <tr *ngIf="company.headcount">
                            <td><i class="fal fa-users"></i> {{ company.headcount.total }} employees<span
                                    *ngIf="company.headcount.is_estimated">, estimated</span> <span
                                    *ngIf="company.is_in_group"> (is part of a group)</span></td>
                        </tr>
                        <tr *ngIf="company.url || company.domain">
                            <td><i class="fal fa-globe"></i><a href="{{ company.url }}" target="_blank"> {{
                                    company.domain }}</a></td>
                        </tr>
                        <tr *ngIf="company.revenue">
                            <td>Revenue: {{ company.revenue }}</td>
                        </tr>
                        <tr *ngIf="company.assets_growth_percentage">
                            <td>Assets Growth: <span class="badge"
                                    [ngClass]="{'badge-danger' : company.assets_growth_percentage < 0, 'badge-success' : company.assets_growth_percentage > 0}">{{
                                    company.assets_growth_percentage.toPrecision(2) | number }}%</span></td>
                        </tr>
                        <tr *ngIf="company.revenue_growth_percentage">
                            <td>Revenue Growth: <span class="badge"
                                    [ngClass]="{'badge-danger' : company.revenue_growth_percentage < 0, 'badge-success' : company.revenue_growth_percentage > 0}">{{
                                    company.revenue_growth_percentage.toPrecision(2) | number }}%</span></td>
                        </tr>
                        <tr *ngIf="company.gross_profit_growth_percentage">
                            <td>Gross Profit Growth: <span class="badge"
                                    [ngClass]="{'badge-danger' : company.gross_profit_growth_percentage < 0, 'badge-success' : company.gross_profit_growth_percentage > 0}">{{
                                    company.gross_profit_growth_percentage.toPrecision(2) | number }}%</span></td>
                        </tr>
                    </table>
                    &nbsp;<a href="{{ company.facebook_url }}" target="_blank" class="social-media-icon"
                        [ngClass]="company.is_direct_facebook_url ? 'direct-facebook-icon' : 'icon-not-direct-link'"><i
                            class="fab fa-facebook"></i></a>
                    &nbsp;<a href="{{ company.twitter_url }}" target="_blank" class="social-media-icon"
                        [ngClass]="company.is_direct_twitter_url ? 'direct-twitter-icon' : 'icon-not-direct-link'"><i
                            class="fab fa-twitter"></i></a>
                    &nbsp;<a href="{{ company.linkedin_url }}" target="_blank" class="social-media-icon"
                        [ngClass]="company.is_direct_linkedin_url ? 'direct-linkedin-icon' : 'icon-not-direct-link'"><i
                            class="fab fa-linkedin"></i></a>

                </div>
            </div>
        </div>
    </div>


    <div class="row small-margin-top">

        <div class="col-md-12">            
            <ai-accounts-summaries
            [aiAccountsSummaries]="aiAccountsSummaries" 
            [financials]="company.financials" 
            [companyId]="companyId"
            [isOldView]="true"
            >
            </ai-accounts-summaries>
        </div>

        <div class="col-md-12"
            *ngIf="company && hasAIPitchBuilder">
            <div class="card">
                <div class="card-header">
                    <h4>Bespoke Analysis with Zint AI</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md">
                            <ng-container *ngIf="loadingStatus.loadingAIPitchBuilderSettings"><i
                                    class="fal fa-robot fa-spin"></i> Configuring...</ng-container>
                            <ng-container *ngIf="!loadingStatus.loadingAIPitchBuilderSettings">
                                <button type="button" class="basic-button-base button-dark" (click)="generateAiPitch()"
                                    [attr.disabled]="loadingStatus.loadingAIPitch ? 'disabled': null">
                                    <i class="fal fa-spinner fa-spin" *ngIf="loadingStatus.loadingAIPitch"></i>
                                    Generate
                                    AI
                                    pitch
                                </button>
                            </ng-container>
                        </div>
                    </div>

                    <div class="card" *ngIf="companyAIPitch">
                        <div class="card-body" [innerText]='companyAIPitch'></div>
                    </div>

                </div>
            </div>
        </div>    

        <div class="col-md-12" *ngIf="companyId && hasAIResearchAssistantAccess">
            <ai-research-engine [companyId]="companyId" [enableOpenQuestionChatBot]="hasAICompanyAssistantAccess">
            </ai-research-engine>
        </div>

        <div class="col-md-12" *ngIf="company.key_people?.length > 0">
            <div class="card">
                <div id="key-people" class="card-header">
                    <h4>Key People</h4>
                </div>
                <div class="card-body card-body-overflow-double">
                    <div class="row" style="text-align: center">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mx-auto"
                            *ngFor="let person of company.key_people">

                            <div class="row">
                                <div class="col-md-12 col-lg-9 mx-auto">
                                    <div class="key-people-card-holder">
                                        <div class="key-people-background"></div>

                                        <div class="key-people-info-section">
                                            <i class="fal fa-user-circle key-people-icon"></i>
                                            <br>
                                            <a style="font-size: 25px" *ngIf="person.linkedin_url"
                                                href="{{ person.linkedin_url }}" target="_blank"
                                                [ngClass]="person.is_direct_linkedin_url ? 'direct-linkedin-icon' : 'icon-not-direct-link'"><i
                                                    class="fab fa-linkedin"></i> </a>&nbsp;
                                            <span *ngIf="person.forename || person.middle_name || person.surname"
                                                style="font-weight: 400; font-size: 25px">
                                                <ng-container *ngIf="person.title">({{ person.title }}) </ng-container>
                                                <ng-container>{{ person.forename }} </ng-container> <ng-container>{{
                                                    person.middle_name }} </ng-container> <ng-container>{{
                                                    person.surname }} </ng-container> <ng-container
                                                    *ngIf="person.suffix">({{ person.suffix }})</ng-container><br>
                                            </span>
                                            <div *ngIf="person.nationality_codes">
                                                <span [ngClass]="'flag-icon flag-icon-' + flag_code | lowercase"
                                                    style="margin-left: 5px; margin-right: 5px"
                                                    *ngFor="let flag_code of person.nationality_codes"></span>
                                            </div>
                                            <span *ngIf="person.occupation" style="font-style: italic;">{{
                                                person.occupation }}<br></span>
                                            <span *ngIf="person.website_occupation" style="font-style: italic;">{{
                                                person.website_occupation }}<br></span>
                                            <ng-container *ngFor="let officer_role of person.officer_roles">
                                                <span>{{officer_role}}<br></span>
                                            </ng-container>
                                            <span *ngIf="person.appointed_on">Appointed {{ person.appointed_on
                                                }}<br></span>
                                            <span *ngIf="person.tenure">Tenure: {{ person.tenure }}<br></span>
                                            <div class="row xsmall-margin-top xsmall-margin-bottom">
                                                <div class="col-md-6 mx-auto" *ngIf="person.nationalities">
                                                    <ng-container *ngFor="let nationality of person.nationalities">
                                                        <span class="badge badge-info">{{ nationality }}</span><br>
                                                    </ng-container>
                                                </div>
                                                <div class="col-md-6 mx-auto" *ngIf="person.age">
                                                    <span class="badge badge-info">Age {{ person.age }}</span><br>
                                                </div>
                                            </div>
                                            <table
                                                class="table table-bordered table-sm xsmall-margin-top xsmall-margin-bottom"
                                                *ngIf="person.natures_of_control">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Natures of Control</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let noc of person.natures_of_control">
                                                        <td>{{ noc }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br>
                                            <span *ngIf="person.information_url"><a href="{{ person.information_url }}"
                                                    target="_blank">Further information on website <i
                                                        class="fal fa-external-link"></i></a><br></span>
                                            <br>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="col-md-12" *ngIf="company.domain">
            <div class="card">
                <div class="card-header">
                    <h4>Website</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 text-center">
                            <img src="{{ company.website_screenshot }}" style="width: 100%;" class="custom-light-border"
                                *ngIf="company.website_screenshot">
                            <a href="{{ company.url }}" target="_blank" style="font-size: 25px">{{ company.domain }}</a>
                            <p *ngIf="company.monthly_website_visitors">{{ company.monthly_website_visitors | number }}
                                monthly website visitors.</p>
                        </div>
                        <div class="col-md-6" style="display: flex; flex-direction: column">
                            <div *ngIf="company.associated_websites && company.associated_websites.length > 0">
                                <p>Associated Websites:</p>
                                <div class="row">
                                    <div *ngFor="let related_site of company.associated_websites"
                                        class="col-md-6 col-lg-4">
                                        <a href="{{ related_site.url }}" target="_blank">{{ related_site.domain_name
                                            }}</a>
                                    </div>
                                </div>
                                <br>
                            </div>
                            <div *ngIf="company.web_technologies" class="small-margin-top">
                                <span>Website Technologies:</span>
                                <div class="row">
                                    <div class="col-md-4 d-flex small-margin-top"
                                        *ngFor="let web_tech_cat_to_techs of company.web_technologies | mapToIterable">
                                        <div class="flex-grow-1">
                                            <b>{{ web_tech_cat_to_techs.key }}</b>
                                            <div *ngFor="let tech of web_tech_cat_to_techs.val">
                                                <span class="badge badge-light">
                                                    <img src="/assets/tech-icons/{{ tech.icon }}"
                                                        onError="this.src='/assets/tech-icons/tachometer-alt.png'"
                                                        style="height: 16px">
                                                    {{ tech.name }} <span *ngIf="tech.version">(Version {{ tech.version
                                                        }})</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="flex-grow: 1;"></div>
                            <div *ngIf="company.domain" class="small-margin-top">
                                <button type="button" class="basic-button-base button-dark float-right"
                                    data-toggle="modal" data-target="#fullWebsiteSearchModal">
                                    Search this website
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="fullWebsiteSearchModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="fullWebsiteSearchModalLabel">Search Website</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Search this website:
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="fullWebsiteSearchTerm"
                                (keyup.enter)="fullWebsiteSearch()">
                            <div class="input-group-append">
                                <button class="btn basic-button-base button-dark" type="button"
                                    (click)="fullWebsiteSearch()">
                                    <i class="fal fa-search" *ngIf="!loadingStatus.loadingFullWebsiteResults"></i> <i
                                        class="fal fa-spinner fa-spin"
                                        *ngIf="loadingStatus.loadingFullWebsiteResults"></i>
                                </button>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="fullWebsiteResults">
                            <p>Found {{ fullWebsiteResults?.length }} results</p>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>URL</th>
                                        <th>Summary</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let websiteResult of fullWebsiteResults">
                                        <td>
                                            <a href="{{ websiteResult.url }}" target="_blank">{{ websiteResult.url
                                                }}</a>
                                        </td>
                                        <td>
                                            <strong>
                                                <p style="font-size:20px" *ngIf="websiteResult.title">{{
                                                    websiteResult.title }}</p>
                                            </strong>
                                            <div style="font-style: italic;" [innerHTML]="websiteResult.summary"></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div class="col-md-12">            
            <div id="accounts-section" class="card">
                <div class="card-header">
                    <h4>Financials:</h4>
                </div>
                <div class="card-body">

                    <div *ngIf="!company.financials || company.financials.length == 0">
                        <span>This company has no financial records.</span>
                    </div>

                    <div *ngIf="company.financials && company.financials.length > 0">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" *ngFor="let year_account of company.financials; let isFirstRow=first"
                                style="cursor: pointer">
                                <a [id]="'fi-tab'+ year_account.id" class="nav-link" data-toggle="tab" [attr.data-target]="'#fi' + year_account.id"
                                    role="tab" aria-controls="profile" aria-selected="false"
                                    [ngClass]="{'active' : isFirstRow, 'financial-tab-disabled' : !isFirstRow && isRestricted}">
                                    <i class="fal fa-lock" *ngIf="!isFirstRow && isRestricted"></i>
                                    Y.E. {{ year_account.made_up_to | date:'d MMM y' }}
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" style="width: 100%">
                            <div class="tab-pane" role="tabpanel" id="fi{{ year_account.id }}"
                                *ngFor="let year_account of company.financials; let isFirstRow=first"
                                [ngClass]="{'active' : isFirstRow, 'in' : isFirstRow}">
                                <div class="col-md-12" style="padding-left: 0px; padding-right: 0px">
                                    <table class="table table-striped table-bordered">
                                        <tbody>
                                            <tr>
                                                <td colspan="2" style="font-size: 20px">
                                                    <a href="{{ year_account.url_to_pdf_file }}" target="_blank">View {{
                                                        year_account.submission_details }} <span
                                                            *ngIf="!year_account.submission_details"> accounts for year
                                                            ending {{ year_account.made_up_to | date:'d MMM y' }}
                                                        </span> <i class="fal fa-external-link"></i></a>
                                                    <div class="d-inline-flex float-lg-right ml-md-2">
                                                        <ng-container *ngIf="hasAccessToAISummary && year_account.id">
                                                            <button class="basic-button-base btn-sm button-dark mr-1"
                                                                data-toggle="modal" data-target="#AiInsightsModal"
                                                                *ngIf="aiAccountsSummaries[year_account.id]?.length">
                                                                View AI Summary
                                                            </button>
                                                            <ng-container
                                                                *ngIf="aiAccountSummariesStatuses['hasGeneric'].indexOf(year_account.id) < 0">
                                                                <button class="basic-button-base btn-sm button-dark"
                                                                    (click)="generateNewAccountSummary(year_account.id)">
                                                                    <i class="fal fa-robot"></i> Summarise with AI
                                                                </button>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </td>
                                            </tr>
                                            <ng-container *ngFor="let fin_k_v of year_account | mapToIterable">
                                                <tr
                                                    *ngIf="fin_k_v.val && ['id', 'url_to_pdf_file', 'url_to_xbrl_file', 'submission_details', 'has_been_indexed', 'made_up_to', 'average_number_employees_during_period', 'software_type'].indexOf(fin_k_v.key) < 0">
                                                    <td>{{ fin_k_v.key | underscoreToUpper }}</td>
                                                    <td>
                                                        <span *ngIf="!isDate(fin_k_v.val)">{{ fin_k_v.val |
                                                            currency:'GBP':'symbol-narrow':'.0'}} </span>
                                                        <span *ngIf="isDate(fin_k_v.val)">
                                                            {{ fin_k_v.val | date: 'd MMM y' }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="basic-button-base button-dark" data-toggle="modal"
                            data-target="#accountsSearchModal">
                            Search Company Accounts
                        </button>
                    </div>

                    <div *ngIf="company.amount_raised">
                        <br>
                        <h3>Fundraising Details:</h3>
                        <p>We believe this company has raised investment of approximately {{
                            company.amount_raised.toPrecision(3) | currency:'GBP':'symbol-narrow':'.0' }}</p>
                    </div>
                </div>        

                <div class="modal fade" id="accountsSearchModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="accountsSearchModalLabel">Search Company Accounts For Terms
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                Year Ending
                                <select [(ngModel)]="accountsDateSelected" class="custom-select">
                                    <option selected="selected" value="any">Any Year</option>
                                    <option *ngFor="let yearAccount of filterIndexAccounts()"
                                        [ngValue]="yearAccount.made_up_to">
                                        {{yearAccount.made_up_to | date:'d MMM y' }}
                                    </option>
                                </select>
                                <br>
                                Search for:
                                <input type="text" class="form-control" [(ngModel)]="accountsSearchText"
                                    (keyup.enter)="accountsSearch()"><br>
                                <button type="button" class="basic-button-base button-dark"
                                    (click)="accountsSearch()">Search</button>
                                <br>
                                <div *ngIf="accountsResults">
                                    <p>Found {{ accountsResults.number_of_results }} results</p>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Year Ending</th>
                                                <th>Page Number</th>
                                                <th>Search Text</th>
                                                <th>Page Rendered</th>
                                                <th style="min-width: 160px">Link to page in accounts</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let account of accountsResults.accounts_results">
                                                <td>{{ account.made_up_to | date:'d MMM y' }}</td>
                                                <td>{{ account.processedPageNumber }}</td>
                                                <td [innerHTML]="account.search_text"></td>
                                                <td>
                                                    <div [ngClass]="{'wrapxhtml': account.do_spaces_url.endsWith('.xhtml')}"
                                                        class="wrap" id="{{account.file_id}}">
                                                        <iframe
                                                            [ngClass]="{'framexhtml': account.do_spaces_url.endsWith('.xhtml')}"
                                                            class="frame" [src]="account.do_spaces_url | safe"></iframe>
                                                    </div>
                                                </td>
                                                <td><a target="_blank" [href]="account | xbrlOrOcrAccountsLink: 'accountsSearch': {companyId}">View <span
                                                            *ngIf="!account.do_spaces_url.endsWith('.xhtml')">page in
                                                        </span>accounts <i class="fal fa-external-link"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4>Assets Breakdown:</h4>
                </div>
                <div class="card-body">
                    <div *ngIf="!company.asset_breakdown || !getObjectKeys(company.asset_breakdown)">
                        <span>There are no asset records for this company.</span>
                    </div>
                    <div *ngIf="company && getObjectKeys(company.asset_breakdown)" class="col px-0 mr-1">
                        <ul class="nav nav-tabs" role="tablist">
                            <ng-container *ngFor="let date of getObjectKeys(company.asset_breakdown); let i = index">
                                <li class="nav-item">
                                    <a class="nav-link" [id]="'tab-' + date" data-toggle="tab" [href]="'#asset' + date"
                                        role="tab" [attr.aria-controls]="'asset' + date" [attr.aria-selected]="i === 0"
                                        [ngClass]="{ 'active': i === 0 }">Y.E. {{date | date:'d MMM y'}}</a>
                                </li>
                            </ng-container>
                        </ul>

                        <div class="tab-content" style="width: 100%">
                            <ng-container *ngFor="let date of getObjectKeys(company.asset_breakdown); let i =index">
                                <div [id]="'asset' + date" class="tab-pane"
                                    [ngClass]="{ 'active': i === 0, 'show': i === 0 }" role="tabpanel">

                                    <div class="row">

                                        <!-- Tangible Assets Table -->
                                        <div *ngIf="company.asset_breakdown[date].tangible"
                                            [ngClass]="company.asset_breakdown[date].intangible ? 'col-md-6' : 'col-md-12'">
                                            <table class="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Tangible Assets</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let item of company.asset_breakdown[date].tangible | keyvalue">
                                                        <td>{{ item.key | underscoreToUpper }}</td>
                                                        <td>{{ item.value | companyDataPipe: 'Assets' }}</td>
                                                    </tr>                                                    
                                                </tbody>
                                            </table>
                                        </div>

                                        <!-- Intangible Assets Table -->
                                        <div *ngIf="company.asset_breakdown[date].intangible" [ngClass]="company.asset_breakdown[date].tangible ? 'col-md-6' : 'col-md-12'">
                                            <table class="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Intangible Assets</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let item of company.asset_breakdown[date].intangible | keyvalue">
                                                        <td>{{ item.key | underscoreToUpper}}</td>
                                                        <td>{{ item.value | companyDataPipe: 'Assets' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-md-12" *ngIf="company.geographical_revenue && company.geographical_revenue.length > 0">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Geographical Revenue Breakdown:'}">
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-striped table-bordered">
                                <tr *ngFor="let geography_revenue of company.geographical_revenue">
                                    <td>{{ geography_revenue.name }}</td>
                                    <td>{{ geography_revenue.revenue | companyDataPipe: 'Revenue':'' }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>

        <div class="col-md-12"
            *ngIf="(company.news &&  company.news.length > 0) || (company.self_published_news && company.self_published_news.length > 0) || company.twitter_handle">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Latest News:', 'doubleHeight': (company.news && company.news.length > 0 && company.self_published_news && company.self_published_news.length > 0)}">
                    <div class="row xsmall-margin-bottom">
                        <div class="col-md-8"
                            *ngIf="(company.news &&  company.news.length > 0) || (company.self_published_news && company.self_published_news.length > 0)">
                            <ng-container *ngIf="(company.news && company.news.length > 0)">
                                <div id="companyNews">
                                    <h5>News Publications:</h5>
                                    <div class="input-group">
                                        <input type="text" class="form-control" name="companyNewsSearch"
                                            [(ngModel)]="companyNewsSearch" (keyup.enter)="newsSearch()">
                                        <div class="input-group-append">
                                            <button class="btn basic-button-base button-dark" type="button"
                                                (click)="newsSearch()"
                                                [attr.disabled]="loadingStatus.loadingSearchNews ? 'disabled': null">
                                                <i class="fal fa-search" *ngIf="!loadingStatus.loadingSearchNews"></i>
                                                <i class="fal fa-spinner fa-spin"
                                                    *ngIf="loadingStatus.loadingSearchNews"></i>
                                            </button>
                                            <button class="btn basic-button-base button-dark" type="button"
                                                (click)="resetNewsArticles()"
                                                [attr.disabled]="loadingStatus.loadingSearchNews ? 'disabled': null">
                                                <i class="fal fa-redo fa-flip-horizontal"
                                                    *ngIf="!loadingStatus.loadingSearchNews"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-light-border news-body-overflow">
                                    <ng-container *ngFor="let article of company.news; let isFirstRow=first">
                                        <div
                                            *ngIf="!filterNewsResultsFromSearch || (companyNewsArticleUrlsMatchingSearch && companyNewsArticleUrlsMatchingSearch.indexOf(article.article_link) >= 0 && filterNewsResultsFromSearch)">
                                            <h5>{{ article.title }}</h5> <i class="fal fa-newspaper"></i> <b><a
                                                    href="{{ article.article_link }}" target="_blank"> {{ article.source
                                                    }}</a></b>
                                            <p>{{ article.published_date | date:'d MMM y' }}</p>
                                            <p *ngIf="!filterNewsResultsFromSearch">{{ article.description }}</p>
                                            <p *ngIf="filterNewsResultsFromSearch"
                                                [innerHTML]="newsArticlesToSummaries[article.article_link]"></p>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="(company.self_published_news && company.self_published_news.length > 0)">
                                <div id="selfPublishedCompanyNews"
                                    [ngClass]="{'small-margin-top': company.news && company.news.length > 0}">
                                    <h5>Self-Published Articles:</h5>
                                    <div class="input-group">
                                        <input type="text" class="form-control" name="companyNewsSearch"
                                            [(ngModel)]="selfPublishedCompanyNewsSearchText"
                                            (keyup.enter)="selfPublishedCompanyNewsSearch()">
                                        <div class="input-group-append">
                                            <button class="btn basic-button-base button-dark" type="button"
                                                (click)="selfPublishedCompanyNewsSearch()"
                                                [attr.disabled]="loadingStatus.loadingSearchSelfPublishedNews ? 'disabled': null">
                                                <i class="fal fa-search"
                                                    *ngIf="!loadingStatus.loadingSearchSelfPublishedNews"></i> <i
                                                    class="fal fa-spinner fa-spin"
                                                    *ngIf="loadingStatus.loadingSearchSelfPublishedNews"></i>
                                            </button>
                                            <button class="btn basic-button-base button-dark" type="button"
                                                (click)="resetSelfPublishedNewsArticles()"
                                                [attr.disabled]="loadingStatus.loadingSearchSelfPublishedNews ? 'disabled': null">
                                                <i class="fal fa-redo fa-flip-horizontal"
                                                    *ngIf="!loadingStatus.loadingSearchSelfPublishedNews"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-light-border news-body-overflow">
                                    <ng-container
                                        *ngFor="let article of company.self_published_news; let isFirstRow=first">
                                        <div
                                            *ngIf="!filterSelfPublishedNewsResultsFromSearch || (selfPublishedCompanyNewsArticleUrlsMatchingSearch && selfPublishedCompanyNewsArticleUrlsMatchingSearch.indexOf(article.url) >= 0 && filterSelfPublishedNewsResultsFromSearch)">
                                            <h5>{{ article.title }}</h5> <i class="fal fa-newspaper"></i> <b><a
                                                    href="{{ article.url }}" target="_blank"> News From Company Website
                                                </a></b>
                                            <p>{{ article.article_published_date | date:'d MMM y' }}</p>
                                            <p *ngIf="!filterSelfPublishedNewsResultsFromSearch">{{
                                                article.article_description || article.article_search_text | slice:0:300
                                                }}</p>
                                            <p *ngIf="filterSelfPublishedNewsResultsFromSearch"
                                                [innerHTML]="selfPublishedNewsArticlesToSummaries[article.url]"></p>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div class="d-flex justify-content-center align-items-center"
                            [ngClass]="(company.news && company.news.length > 0) || (company.self_published_news && company.self_published_news.length > 0) ? 'col-md-4': 'col-md-12'">
                            <div *ngIf="company.twitter_handle" style="width: 400px"
                                class="custom-light-border float-right can-be-restricted">
                                <a class="twitter-timeline custom-light-border" data-width="400" data-height="600"
                                    data-dnt="true" data-theme="light" data-link-color="#2B7BB9"
                                    href="https://twitter.com/{{ company.twitter_handle }}?ref_src=twsrc%5Etfw">Tweets
                                    by {{ company.twitter_handle }}</a>
                                <div class="customscript" src="https://platform.twitter.com/widgets.js"></div>
                            </div>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>


        <div class="col-md-12" *ngIf="company.hiring_positions?.length > 0">
            <div class="card">
                <speak-to-sales-popover [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Hiring For:'}">
                    <div class="row xsmall-margin-bottom">
                        <div class="col-md-8">
                            Search jobs:
                            <div class="input-group">
                                <input type="text" class="form-control" name="jobSearchText" [(ngModel)]="jobSearchText"
                                    (keyup.enter)="jobTextSearch()">
                                <div class="input-group-append">
                                    <button class="btn basic-button-base button-dark" type="button"
                                        (click)="jobTextSearch()"
                                        [attr.disabled]="loadingStatus.loadingJobSearch ? 'disabled': null">
                                        <i class="fal fa-search" *ngIf="!loadingStatus.loadingJobSearch"></i> <i
                                            class="fal fa-spinner fa-spin" *ngIf="loadingStatus.loadingJobSearch"></i>
                                    </button>
                                    <button class="btn basic-button-base button-dark" type="button"
                                        (click)="resetJobSearch()"
                                        [attr.disabled]="loadingStatus.loadingJobSearch ? 'disabled': null">
                                        <i class="fal fa-redo fa-flip-horizontal"
                                            *ngIf="!loadingStatus.loadingJobSearch"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <ng-container *ngIf="filterJobResultsFromSearch">
                            <div class="col-md-12 xsmall-margin-top"
                                *ngIf="jobsMatchingSearch && jobsMatchingSearch.length == 0">
                                <span>No jobs matched your search.</span>
                            </div>
                            <div *ngFor="let position of jobsMatchingSearch" class="col-md-4 xsmall-margin-top">
                                <b>{{ position.title }}<br></b>
                                <span>{{ position.date_published }}<br></span>
                                <div style="font-style: italic;" [innerHTML]="position.summary"></div>
                                <a href="{{ position.link }}" target="_blank">Read More</a>
                                <br>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!filterJobResultsFromSearch">
                            <div *ngFor="let position of company.hiring_positions" class="col-md-4 xsmall-margin-top">
                                <b>{{ position.title }}<br></b>
                                <span>{{ position.date_published }}<br></span>
                                <a href="{{ position.link }}" target="_blank">Read More</a>
                                <br>
                            </div>
                        </ng-container>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>


        <div class="col-md-12" *ngIf="company.events?.length > 0">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Events Attending:'}">
                    <div class="row">
                        <div class="col-md-4" *ngFor="let event of company.events">
                            <h5>{{ event.name }}</h5>
                            <span *ngIf="event.start || event.end">{{ event.start | date:'d MMM y' }} <span
                                    *ngIf="event.start && event.end">-</span> {{ event.end | date:'d MMM y'
                                }}<br></span>
                            <i>{{ event.description }}<br></i>
                            <a href="{{ event.event_url }}" target="_blank">Find out more about exhibition</a><br>
                            <a href="{{ event.exhibitor_link }}" target="_blank">View Exhibitor Page</a><br>
                            <br>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>


        <div class="col-md-12" *ngIf="company.imports?.length > 0 || company.exports?.length > 0">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Imports/Exports:'}">
                    <div class="row can-be-restricted">
                        <div class="col-lg-6 col-md-12" style="max-height: 300px">
                            <p>Imports:</p>
                            <ng-container *ngIf="!isBrowserSafari">
                                <ngx-charts-bar-vertical [results]='company.imports'
                                    [scheme]="lineChartColorSchemeImports" [gradient]="false" [xAxis]="true"
                                    [yAxis]="true" [legend]="false" [showXAxisLabel]="true" [showYAxisLabel]="true"
                                    [xAxisLabel]="Month" [yAxisLabel]="yAxisLabelImports">
                                </ngx-charts-bar-vertical>
                            </ng-container>
                            <ng-container *ngIf="isBrowserSafari">Your browser is not able to display import/export
                                charts. Please consider using Google Chrome or view details below.</ng-container>
                        </div>
                        <div class="col-lg-6 col-md-12" style="max-height: 300px">
                            <p>Exports:</p>
                            <ng-container *ngIf="!isBrowserSafari">
                                <ngx-charts-bar-vertical [scheme]="lineChartColorSchemeExports"
                                    [results]='company.exports' [gradient]="false" [xAxis]="true" [yAxis]="true"
                                    [legend]="false" [showXAxisLabel]="true" [showYAxisLabel]="true"
                                    [xAxisLabel]="Month" [yAxisLabel]="yAxisLabelExports">
                                </ngx-charts-bar-vertical>
                            </ng-container>
                            <ng-container *ngIf="isBrowserSafari">Your browser is not able to display import/export
                                charts. Please consider using Google Chrome or view details below.</ng-container>
                        </div>
                    </div>
                    <div class="row small-margin-top text-center can-be-restricted">
                        <div class="col-md-6">
                            <button type="button" class="basic-button-base button-dark" data-toggle="modal"
                                data-target="#importsModal">
                                View All Imports
                            </button>
                            <div class="modal fade" id="importsModal" tabindex="-1" role="dialog">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="importsModalLabel">All Imports</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <import-export-details
                                                [inputParams]="{'detailedImportsOrExports': company.detailed_imports}"></import-export-details>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="basic-button-base button-dark"
                                                data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <button type="button" class="basic-button-base button-dark" data-toggle="modal"
                                data-target="#exportsModal">
                                View All Exports
                            </button>
                            <div class="modal fade" id="exportsModal" tabindex="-1" role="dialog">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exportsModalLabel">All Exports</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <import-export-details
                                                [inputParams]="{'detailedImportsOrExports': company.detailed_exports}"></import-export-details>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="basic-button-base button-dark"
                                                data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>

        <div class="col-md-12" *ngIf="company.share_filings.length > 0">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Share Allotments:'}">
                    <div class="row">
                        <div *ngFor="let filing of company.share_filings" class="col-md-6">
                            <table class="table table-striped table-bordered">
                                <tbody>
                                    <tr>
                                        <td>Filing Type:</td>
                                        <td>{{ filing.type }}</td>
                                    </tr>
                                    <tr>
                                        <td>Allotment Date:</td>
                                        <td>{{ filing.date | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr>
                                        <td>Document Link:</td>
                                        <td><a href="{{ filing.pdf_url }}" target="_blank">View online </a><i
                                                class="fal fa-external-link"></i></td>
                                    </tr>
                                    <tr *ngIf="filing.capital_values.length > 0">
                                        <td>Capital Values:</td>
                                        <td>
                                            <span *ngFor="let capital_value of filing.capital_values">
                                                {{ capital_value.figure | number }} {{ capital_value.currency }}<br>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>

        <div class="col-md-12" *ngIf="company.grants.length > 0">
            <div class="card">
                <speak-to-sales-popover [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Grants:'}">
                    <div *ngFor="let grant of company.grants; let i = index">
                        <b>{{ grant.project_title }}<br></b>
                        <span *ngIf="grant.description" style="font-size: 12px; font-style: italic;">{{
                            grant.description }}<br><br></span>
                        <span *ngIf="grant.project_start_date && grant.project_end_date">{{ grant.project_start_date |
                            date:'d MMM y' }} - {{ grant.project_end_date | date:'d MMM y'}}<br></span>
                        <span *ngIf="grant.grant_offered">Grant Offered: {{ grant.grant_offered |
                            currency:'GBP':'symbol-narrow':'.0'}}<br></span>
                        <span *ngIf="grant.total_costs">Total Project Cost: {{ grant.total_costs |
                            currency:'GBP':'symbol-narrow':'.0'}}<br></span>
                        <span *ngIf="grant.spend_to_date">Spend to Date: {{ grant.spend_to_date |
                            currency:'GBP':'symbol-narrow':'.0'}}<br></span>
                        <hr *ngIf="i != company.grants.length-1">
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>


        <div class="col-md-12"
            *ngIf="company.industry_graphs && (company.industry_graphs.turnover_bar_graph || company.industry_graphs.headcount_bar_graph)">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Industry Peer Comparison'}">
                    <div class="row can-be-restricted">
                        <div class="col-lg-6 col-md-12" style="max-height: 300px" id="industry-turnover-graph-holder"
                            *ngIf="company.industry_graphs.turnover_bar_graph && company.industry_graphs.turnover_line_graph">
                            <ng-container *ngIf="!isBrowserSafari">
                                <p class="text-center">Revenue Analysis <span *ngIf="company.sic_codes">of companies in
                                        <span style="font-style: italic">{{ company.sic_codes[0].description
                                            }}:</span></span></p>
                                <combo-chart-component class="chart-container" [scheme]="combinationBarChartTheme"
                                    [colorSchemeLine]="combinationLineChartTheme"
                                    [results]="company.industry_graphs.turnover_bar_graph"
                                    [lineChart]="company.industry_graphs.turnover_line_graph" [animations]="false"
                                    [tooltipDisabled]="true" [gradient]="false" [xAxis]="true" [yAxis]="true"
                                    [showGridLines]="false" [showXAxisLabel]="true" [showYAxisLabel]="true"
                                    [yAxisLabel]="'Number of Companies'">
                                </combo-chart-component>
                            </ng-container>
                            <ng-container *ngIf="isBrowserSafari">Your browser is not able to display turnover charts.
                                Please consider using Google Chrome.</ng-container>
                            <p class="text-center">Compared to competitors in the same sector, {{ company.name }} is in
                                the {{ company.industry_graphs.turnover_percentile }} percentile for revenue.</p>
                        </div>
                        <div class="d-lg-none col-md-12" style="height: 100px;"></div>
                        <div class="col-lg-6 col-md-12"
                            [ngClass]="{'offset-lg-3' : !company.industry_graphs.turnover_bar_graph || !company.industry_graphs.turnover_line_graph}"
                            style="max-height: 300px" id="headcount-turnover-graph-holder"
                            *ngIf="company.industry_graphs.headcount_bar_graph && company.industry_graphs.headcount_line_graph">
                            <ng-container *ngIf="!isBrowserSafari">
                                <p class="text-center">Headcount Analysis <span *ngIf="company.sic_codes">of companies
                                        in <span style="font-style: italic">{{ company.sic_codes[0].description
                                            }}:</span></span></p>
                                <combo-chart-component class="chart-container" [scheme]="combinationBarChartTheme"
                                    [colorSchemeLine]="combinationLineChartTheme"
                                    [results]="company.industry_graphs.headcount_bar_graph"
                                    [lineChart]="company.industry_graphs.headcount_line_graph" [animations]="false"
                                    [tooltipDisabled]="true" [gradient]="false" [xAxis]="true" [yAxis]="true"
                                    [showGridLines]="false" [showXAxisLabel]="true" [showYAxisLabel]="true"
                                    [yAxisLabel]="'Number of Companies'">
                                </combo-chart-component>
                            </ng-container>
                            <ng-container *ngIf="isBrowserSafari">Your browser is not able to display headcount charts.
                                Please consider using Google Chrome.</ng-container>
                            <p class="text-center">Compared to competitors in the same sector, {{ company.name }} is in
                                the {{ company.industry_graphs.headcount_percentile }} percentile for headcount.</p>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>



        <div class="col-md-12" *ngIf="company.industry_insights?.length > 0">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Industry Insights:'}">
                    <div class="row">
                        <div *ngFor="let insight of company.industry_insights" class="col-md-6 d-flex">
                            <div class="flex-grow-1">
                                <b>{{ insight.title }}</b><br>
                                <table class="table table-striped table-bordered table-sm xsmall-margin-top">
                                    <tr>
                                        <td>Sector Revenue:</td>
                                        <td>{{ insight.sector_revenue | companyDataPipe:'Revenue':'' }}</td>
                                    </tr>
                                    <tr *ngIf="insight.revenue_growth">
                                        <td>Revenue Growth {{ insight.growth_period }}</td>
                                        <td>{{ insight.revenue_growth | number:'1.1-1' }}%</td>
                                    </tr>
                                    <tr>
                                        <td>Sector Employment:</td>
                                        <td>{{ insight.sector_employment | number }} people</td>
                                    </tr>
                                    <tr *ngIf="insight.employment_growth">
                                        <td>Employment Growth {{ insight.growth_period }}</td>
                                        <td>{{ insight.employment_growth | number:'1.1-1' }}%</td>
                                    </tr>
                                    <tr>
                                        <td>Number of Businesses in Sector:</td>
                                        <td>{{ insight.number_of_businesses | number }}</td>
                                    </tr>
                                </table>
                                <br>
                            </div>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>



        <div class="col-md-12" *ngIf="company.legislations.length > 0">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Recent Legislation:'}">
                    <div *ngFor="let legislation of company.legislations">
                        <b>{{ legislation.title }} ({{ legislation.type }})</b>
                        <span> {{ legislation.published }}</span><br>
                        <span>{{ legislation.summary }}</span><br>
                        <a href="{{ legislation.link }}" target="_blank">Read online</a>
                        <br><br><br>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>



        <div class="col-md-12" *ngIf="company.lei_details">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Legal Entity Identifier:'}">

                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-striped table-bordered">
                                <tr>
                                    <td>LEI number:</td>
                                    <td><b>{{ company.lei_details.lei_number }}</b></td>
                                </tr>
                                <tr>
                                    <td>Registration Status:</td>
                                    <td>{{ company.lei_details.registration_status }}</td>
                                </tr>
                                <tr>
                                    <td>Validation Sources:</td>
                                    <td>{{ company.lei_details.registration_validation_sources }}</td>
                                </tr>
                                <tr>
                                    <td>Initial Registration Date:</td>
                                    <td>{{ company.lei_details.initial_registration_date | date:'d MMM y' }}</td>
                                </tr>
                                <tr>
                                    <td>Renewal Date:</td>
                                    <td>{{ company.lei_details.renewal_date | date:'d MMM y'}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2"><a
                                            href="https://www.gleif.org/lei/{{ company.lei_details.lei_number }}"
                                            target="_blank">Read more on the LEI website <i
                                                class="fal fa-external-link"></i></a></td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <p>A Legal Entity Identifier (LEI) is a 20-character identifier that identifies distinct
                                legal entities that engage in financial transactions. It is defined by ISO 17442.</p>
                        </div>
                    </div>

                </speak-to-sales-popover>
            </div>
        </div>


        <div class="col-md-12" *ngIf="company.trademarks.length > 0">
            <div class="card">
                <speak-to-sales-popover [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Trademarks:'}">
                    <div class="row">
                        <div *ngFor="let trademark of company.trademarks" class="col-md-6">
                            <table class="table table-striped table-bordered">
                                <tbody>
                                    <tr>
                                        <td style="width: 30%">Trademark Number:</td>
                                        <td><b>{{ trademark.trademark_number }}</b></td>
                                    </tr>
                                    <tr *ngIf="trademark.trademark_text">
                                        <td>Text:</td>
                                        <td style="font-style: italic">{{ trademark.trademark_text }}</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>{{ trademark.status }}</td>
                                    </tr>
                                    <tr>
                                        <td>Type of Trademark:</td>
                                        <td>{{ trademark.type_of_trademark }}</td>
                                    </tr>
                                    <tr>
                                        <td>Date Filed:</td>
                                        <td>{{ trademark.date_filed | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="trademark.date_published">
                                        <td>Date Published:</td>
                                        <td>{{ trademark.date_published | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="trademark.date_registered">
                                        <td>Date Registered:</td>
                                        <td>{{ trademark.date_registered | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="trademark.date_expired">
                                        <td>Date Expired:</td>
                                        <td>{{ trademark.date_expired | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="trademark.renewal_due_date">
                                        <td>Renewal Due Date:</td>
                                        <td>{{ trademark.renewal_due_date | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="trademark.classes">
                                        <td>Classification:</td>
                                        <td> <span *ngIf="trademark.classes.length > 1">Classes</span> <span
                                                *ngIf="trademark.classes.length == 1">Class</span> {{
                                            trademark.classes.join(", ") }}</td>
                                    </tr>
                                    <tr *ngIf="trademark.url">
                                        <td>UK IPO Website Entry:</td>
                                        <td><a href="{{ trademark.url }}" target="_blank">More <i
                                                    class="fal fa-external-link"></i></a><br></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>


        <div class="col-md-12" *ngIf="hasPatentsAccess && (company.patents.length > 0)">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Patents Filed:'}">
                    <div class="row">
                        <div *ngFor="let patent of company.patents" class="col-md-6">
                            <table class="table table-striped table-bordered">
                                <tbody>
                                    <tr *ngIf="patent.title">
                                        <td>Title:</td>
                                        <td style="font-style: italic">{{ patent.title }}</td>
                                    </tr>
                                    <tr *ngIf="patent.status">
                                        <td>Status:</td>
                                        <td>{{ patent.status }}</td>
                                    </tr>
                                    <tr *ngIf="patent.application_number">
                                        <td>Application Number:</td>
                                        <td>{{ patent.application_number }}</td>
                                    </tr>
                                    <tr *ngIf="patent.publication_number">
                                        <td>Publication Number:</td>
                                        <td>{{ patent.publication_number }}</td>
                                    </tr>
                                    <tr>
                                        <td>Date Filed:</td>
                                        <td>{{ patent.date_filed | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="patent.date_revoked">
                                        <td>Date Revoked:</td>
                                        <td>{{ patent.date_revoked | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="patent.date_expired">
                                        <td>Date Expired:</td>
                                        <td>{{ patent.date_expired | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="patent.first_applicant">
                                        <td>First Applicant:</td>
                                        <td>{{ patent.first_applicant.prefix}} {{ patent.first_applicant.first_name}} {{
                                            patent.first_applicant.last_name}} {{ patent.first_applicant.suffix}} </td>
                                    </tr>
                                    <tr *ngIf="patent.ipo_link">
                                        <td>UK IPO Website Entry:</td>
                                        <td><a href="{{ patent.ipo_link }}" target="_blank">More <i
                                                    class="fal fa-external-link"></i></a><br></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>


        <div class="col-md-12" *ngIf="company.company_charges && (company.company_charges.length > 0)">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Company Charges:'}">
                    <div class="row">
                        <div *ngFor="let charge of company.company_charges" class="col-md-6">
                            <table class="table table-striped table-bordered">
                                <tbody>
                                    <tr *ngIf="charge.classification_description">
                                        <td>Classification:</td>
                                        <td>{{ charge.classification_description }}</td>
                                    </tr>
                                    <tr *ngIf="charge.persons_entitled">
                                        <td>Persons Entitled:</td>
                                        <td *ngIf="charge.persons_entitled">
                                            <ng-container
                                                *ngIf="charge.persons_entitled && charge.persons_entitled.length > 1">
                                                <ul style="margin-bottom: 5px; margin-top: 0; padding-left: 18px;">
                                                    <ng-container
                                                        *ngFor="let person_entitled of charge.persons_entitled">
                                                        <li>
                                                            <ng-container *ngIf="person_entitled.company_number">
                                                                <a href="/company/{{person_entitled.company_number}}"
                                                                    target="_blank">{{person_entitled.name}}</a>
                                                            </ng-container>
                                                            <ng-container *ngIf="!person_entitled.company_number">
                                                                {{person_entitled.name}}
                                                            </ng-container>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="charge.persons_entitled && charge.persons_entitled.length === 1">
                                                <span *ngFor="let person_entitled of charge.persons_entitled">
                                                    <ng-container *ngIf="person_entitled.company_number">
                                                        <a href="/company/{{person_entitled.company_number}}"
                                                            target="_blank">{{person_entitled.name}}</a>
                                                    </ng-container>
                                                    <ng-container *ngIf="!person_entitled.company_number">
                                                        {{person_entitled.name}}
                                                    </ng-container>
                                                </span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <tr *ngIf="charge.status">
                                        <td>Status:</td>
                                        <td style="font-style: italic">{{ charge.status }}</td>
                                    </tr>
                                    <tr>
                                        <td>Charge Identifier:</td>
                                        <td>{{ charge.code || charge.identifier }}</td>
                                    </tr>
                                    <tr *ngIf="charge.description">
                                        <td>Description:</td>
                                        <td style="font-style: italic">{{ charge.description }}</td>
                                    </tr>
                                    <tr *ngIf="charge.created_on">
                                        <td>Created:</td>
                                        <td>{{ charge.created_on | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="charge.satisfied_on">
                                        <td>Satisfied:</td>
                                        <td>{{ charge.satisfied_on | date:'d MMM y' }}</td>
                                    </tr>
                                    <tr *ngIf="charge.creation_link_id">
                                        <td>Link:</td>
                                        <td><a href="https://find-and-update.company-information.service.gov.uk/company/{{company.number}}/filing-history/{{ charge.creation_link_id }}/document?format=pdf&download=0"
                                                target="_blank" rel="noreferrer">More <i
                                                    class="fal fa-external-link"></i></a><br></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>


        <div class="col-md-12" *ngIf="company.government_contracts && (company.government_contracts.length > 0)">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Government Contracts Awarded:'}">
                    <div class="row">
                        <ng-container *ngIf="company.government_contracts">
                            <button type="button" class="basic-button-base button-dark button-right-company-detail"
                                data-toggle="modal" data-target="#governmentContractsSearchModal"
                                (click)="getAllGovernmentContracts()">
                                View All Contracts
                            </button>
                        </ng-container>
                        <div *ngFor="let contract of company.government_contracts" class="col-md-6">
                            <government-contract [contract]="contract"></government-contract>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>

        <div class="modal fade" id="governmentContractsSearchModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="governmentContractsSearchModalLabel">Government Contracts</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-header" style="padding-top: 10px; padding-bottom: 0;">
                        <div class="row full-width">
                            <div class="col-md-6">
                                <label>Search Titles or Descriptions:</label>
                                <input type="text" class="form-control" [(ngModel)]="governmentContractsSearchTerm"
                                    (keyup)="searchGovernmentContractsForCompany()"><br>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ng-container *ngIf="allGovernmentContracts">
                                            <button (click)="downloadAllGovernmentContracts();"
                                                class="btn basic-button-base button-dark float-right"
                                                style="margin-top: 5px"><i class="fal fa-download"></i> Download All
                                                {{allGovernmentContracts.length}}<ng-container
                                                    *ngIf="allGovernmentContracts.length===1000">+</ng-container>
                                                Contracts<sup>*</sup></button>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-12" style="margin-top: 8px">
                                        <span class="float-right" style="font-size: 10px"><sup>*</sup>Uses your download
                                            quota.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex justify-content-center align-items-center">
                            <zint-loader *ngIf="loadingStatus.loadingGovernmentContracts"></zint-loader>
                            </div>
                        <div class="row" style="max-height: 60vh; overflow-y: scroll;">
                            <div *ngFor="let contract of filteredGovernmentContracts" class="col-md-6">
                                <government-contract [contract]="contract"></government-contract>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-md-12" *ngIf="company.gcloud_services.length > 0 || company.amazon_seller_details.length > 0">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Online Marketplaces:'}">
                    <div class="row">
                        <div class="col-md-6" *ngIf="company.gcloud_services.length > 0">
                            <h5 style="font-weight: 900"><i class="fal fa-server"></i> G Cloud</h5>
                            <hr>
                            <div *ngFor="let gcloud of company.gcloud_services">
                                <span>Listed on the G Cloud with pricing for their product detailed at "{{ gcloud.price
                                    }}"<br></span>
                                <a href="{{ gcloud.link }}" target="_blank">Find out more on the G-Cloud <i
                                        class="fal fa-external-link"></i></a><br>
                                <a href="{{ gcloud.t_and_c }}" target="_blank">Read {{ company.name }}'s Terms and
                                    Conditions <i class="fal fa-external-link"></i></a><br><br>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="company.amazon_seller_details.length > 0">
                            <h5 style="font-weight: 900"><i class="fal fa-shopping-cart"></i> amazon.co.uk</h5>
                            <hr>
                            <div *ngFor="let amazon_detail of company.amazon_seller_details">
                                <span>Sells products on Amazon under the trade name <span style="font-style: italic">"{{
                                        amazon_detail.seller_name }}"</span><br></span>
                                <a href="{{ amazon_detail.url }}" target="_blank">Find out more on Amazon <i
                                        class="fal fa-external-link"></i></a><br><br>
                            </div>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>

        <div class="col-md-12"
            *ngIf="hasEnergyAndEmissionsAccess && ((company.energy && company.energy.length > 0) || (company.emissions && company.emissions.length > 0))">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Energy and Emissions:'}">
                    <div class="row">
                        <div *ngIf="company.energy && company.energy.length > 0" class="col-lg-6 col-md-12">
                            <p>Energy:</p>
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item"
                                    *ngFor="let energy_year_account of company.energy; let isFirstRow=first"
                                    style="cursor: pointer">
                                    <a class="nav-link" data-toggle="tab"
                                        [attr.data-target]="'#fi' + energy_year_account.made_up_to" role="tab"
                                        aria-controls="profile" aria-selected="false"
                                        [ngClass]="{'active' : isFirstRow, 'financial-tab-disabled' : !isFirstRow && isRestricted}">
                                        <i class="fal fa-lock" *ngIf="!isFirstRow && isRestricted"></i>
                                        Y.E. {{ energy_year_account.made_up_to | date:'d MMM y' }}
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" style="width: 100%">
                                <div class="tab-pane" role="tabpanel" id="fi{{ energy_year_account.made_up_to }}"
                                    *ngFor="let energy_year_account of company.energy; let isFirstRow=first"
                                    [ngClass]="{'active' : isFirstRow, 'in' : isFirstRow}">
                                    <div class="col-md-12" style="padding-left: 0px; padding-right: 0px">
                                        <table class="table table-striped table-bordered">
                                            <tbody>
                                                <ng-container
                                                    *ngFor="let fin_k_v of energy_year_account | mapToIterable">
                                                    <tr
                                                        *ngIf="fin_k_v.val && ['url_to_pdf_file', 'made_up_to'].indexOf(fin_k_v.key) < 0">
                                                        <td>{{ fin_k_v.key | underscoreToUpper | removeTrailingKwh }}
                                                        </td>
                                                        <td>
                                                            <span *ngIf="fin_k_v.key !== 'low_energy'">{{ fin_k_v.val |
                                                                number }} kWh </span>
                                                            <span *ngIf="fin_k_v.key === 'low_energy'">{{ fin_k_v.val
                                                                }}</span>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="company.emissions && company.emissions.length > 0" class="col-lg-6 col-md-12"
                            style="display: flex; flex-direction: column">
                            <p>Emissions:</p>
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item"
                                    *ngFor="let emissions_year_account of company.emissions; let isFirstRow=first"
                                    style="cursor: pointer">
                                    <a class="nav-link" data-toggle="tab"
                                        [attr.data-target]="'#em' + emissions_year_account.made_up_to" role="tab"
                                        aria-controls="profile" aria-selected="false"
                                        [ngClass]="{'active' : isFirstRow, 'financial-tab-disabled' : !isFirstRow && isRestricted}">
                                        <i class="fal fa-lock" *ngIf="!isFirstRow && isRestricted"></i>
                                        Y.E. {{ emissions_year_account.made_up_to | date:'d MMM y' }}
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" style="width: 100%">
                                <div class="tab-pane" role="tabpanel" id="em{{ emissions_year_account.made_up_to }}"
                                    *ngFor="let emissions_year_account of company.emissions; let isFirstRow=first"
                                    [ngClass]="{'active' : isFirstRow, 'in' : isFirstRow}">
                                    <div class="col-md-12" style="padding-left: 0px; padding-right: 0px">
                                        <table class="table table-striped table-bordered">
                                            <tbody>
                                                <ng-container
                                                    *ngFor="let fin_k_v of emissions_year_account | mapToIterable">
                                                    <tr
                                                        *ngIf="fin_k_v.val && ['url_to_pdf_file', 'made_up_to'].indexOf(fin_k_v.key) < 0">
                                                        <td>{{ fin_k_v.key | underscoreToUpper | removeTrailingKgCo2e }}
                                                        </td>
                                                        <td>
                                                            <span
                                                                *ngIf="(fin_k_v.key === 'kg_co2e_per_employee' || fin_k_v.key === 'kg_co2e_per_million_revenue'); else addKgCo2e">{{
                                                                fin_k_v.val | number }}</span>
                                                            <ng-template #addKgCo2e><span *ngIf="fin_k_v.val">{{
                                                                    fin_k_v.val | number }} Kg CO2e
                                                                </span></ng-template>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>

        <div class="col-md-12" *ngIf="company.functional_departments && company.functional_departments.length > 0">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Functional Departments:'}">
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-striped table-bordered">
                                <tr *ngFor="let functional_department of company.functional_departments">
                                    <td>{{ functional_department.dept }}</td>
                                    <td>{{ functional_department.total }} employees</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </speak-to-sales-popover>
            </div>
        </div>


        <div class="col-md-12" id="first-graph">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Corporate Structure:', 'companyNumber': company.number}" (switchCorporateGraph)="switchCorporateGraphType($event)" (getFullClustersGraph)="showFullClustersGraph = true">
                    <div *ngIf="isLoadingCorporateStructureGraph" class="d-flex"><zint-loader size="xSmall"></zint-loader> <span class="ml-2">Loading Graph</span></div>
                    <div class="graph-holder can-be-restricted-allow-click" *ngIf="corporateStructureGraph">
                        <ngx-graph *ngIf="corporateGraphType === 'structure'" #graph class="chart-container" [autoZoom]="true" [panOnZoom]="true"
                            [enableZoom]="true" [zoomSpeed]="0.05" [minZoomLevel]="0.1" [maxZoomLevel]="2.0"
                            [autoCenter]="true" [legend]="false" [links]="corporateStructureGraph.links"
                            [nodes]="corporateStructureGraph.nodes" [curve]="curve"
                            [layoutSettings]="{orientation: 'TB'}">

                            <ng-template #defsTemplate>
                                <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4"
                                    markerHeight="4" orient="auto" fill="#fccfa0">
                                    <svg:path d="M0,-5L10,0L0,5" />
                                </svg:marker>
                            </ng-template>

                            <ng-template #nodeTemplate let-node>
                                <svg:g class="node">
                                    <svg:rect *ngIf="node.label == company.name"
                                        style="stroke-width:1px; stroke: #dee2e6" [attr.width]="node.dimension.width"
                                        [attr.height]="100" [attr.fill]="'#FA9F42'" />
                                    <svg:rect *ngIf="node.label != company.name"
                                        style="stroke-width:1px; stroke: #dee2e6" [attr.width]="node.dimension.width"
                                        [attr.height]="100" [attr.fill]="'#f4f5f7'" />
                                    <switch>
                                        <foreignObject *ngIf="!node.link" x="0.1em" width="2em" height="1.8em">
                                            <span class="flag-icon flag-icon-{{node.country_code}}"></span>
                                        </foreignObject>
                                        <foreignObject *ngIf="node.link" x="1.2em" width="2em" height="1.8em">
                                            <span class="flag-icon flag-icon-{{node.country_code}}"></span>
                                        </foreignObject>
                                    </switch>
                                    <svg:text *ngIf="!node.link" alignment-baseline="central" [attr.y]="10">
                                        <tspan x="0.1em" dx="1.8em" dy="0.5em">{{node.label}}&nbsp;&nbsp;&nbsp;&nbsp;
                                        </tspan>
                                    </svg:text>
                                    <switch *ngIf="node.link">
                                        <foreignObject [attr.width]="node.dimension.width" [attr.height]="100">
                                            <p style="right: 1px; position: absolute; bottom: -1.1em; cursor: pointer"
                                                (click)="collapseCorporateStructureGraph(node.id)">
                                                <i class="fal fa-compress-arrows-alt"></i>
                                            </p>
                                            <p style="right: 20px; position: absolute; bottom: -1.1em; cursor: pointer"
                                                (click)="expandCorporateStructureUp(node.id)">
                                                <i class="fal fa-caret-circle-up"></i>
                                            </p>
                                            <p style="right: 40px; position: absolute; bottom: -1.1em; cursor: pointer"
                                                (click)="expandCorporateStructureDown(node.id)">
                                                <i class="fal fa-caret-circle-down"></i>
                                            </p>
                                        </foreignObject>
                                    </switch>
                                    <svg:text *ngIf="node.link" alignment-baseline="central" [attr.x]="10"
                                        [attr.y]="10">
                                        <tspan x="1.2em" dx="1.8em" dy="0.5em">{{node.label}}&nbsp;&nbsp;&nbsp;&nbsp;
                                        </tspan>
                                        <tspan x="1.2em" dy="1.8em" *ngIf="node.revenue">Revenue: {{ node.revenue |
                                            companyDataPipe:'Revenue':'' }}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                                        <tspan x="1.2em" dy="1.2em" *ngIf="node.sic_code_1">{{ node.sic_code_1
                                            }}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                                        <tspan x="1.2em" dy="1.2em"></tspan>
                                        <a attr.xlink:href="{{node.link}}" target="_blank" *ngIf="node.link">
                                            <tspan x="1.2em" dy="1.2em">View Organisation</tspan>
                                        </a>
                                    </svg:text>
                                </svg:g>
                            </ng-template>

                            <ng-template #linkTemplate let-link>
                                <svg:g class="edge" ngx-tooltip [tooltipPlacement]="'top'" [tooltipType]="'tooltip'"
                                    [tooltipTitle]="link.label">
                                    <svg:path class="line" [attr.stroke-width]="link.line_width" stroke="#FA9F42"
                                        stroke-opacity="0.5" marker-end="url(#arrow)">
                                    </svg:path>
                                </svg:g>
                            </ng-template>
                        </ngx-graph>
                        <app-company-clusters *ngIf="corporateGraphType === 'clusters'" 
                        [clustersGraphType]="'immediate'"                        
                        ></app-company-clusters>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>

        <div *ngIf="showFullClustersGraph" class="modal fade" id="fullClustersModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-xxl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="">Corporate Clusters</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <app-company-clusters></app-company-clusters>
                        <a [href]="clustersPageLink" target="_blank" class="ml-3 float-right">Open clusters in a new page.<i class="fal fa-external-link ml-1"></i></a>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-md-12">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'noPadding': !isLoadingRelatedPeopleGraph, 'isRestricted': isRestricted, 'sectionTitle': 'Cross Sell Opportunities:', 'doubleHeight': isRelatedPeopleGraphExpandedView}">
                    <span (click)="fitAndCentreRelatedPeopleGraph()"
                        style="cursor: pointer; position: absolute; right: 20px; top: 68px"
                        *ngIf="!isLoadingRelatedPeopleGraph">
                        <ng-container *ngIf="!isRelatedPeopleGraphExpandedView">
                            <i class="fal fa-expand-arrows-alt"></i>
                        </ng-container>
                        <ng-container *ngIf="isRelatedPeopleGraphExpandedView">
                            <i class="fal fa-compress-arrows-alt"></i>
                        </ng-container>
                    </span>
                    <p *ngIf="isLoadingRelatedPeopleGraph"><i class="fal fa-spinner fa-spin"></i> Loading Graph</p>
                    <p *ngIf="relatedPeopleGraph && relatedPeopleGraph.nodes && relatedPeopleGraph.nodes.length == 0"
                        style="padding: 20px">No cross sell opportunities identified.</p>
                    <div class="graph-holder can-be-restricted-allow-click"
                        *ngIf="relatedPeopleGraph && relatedPeopleGraph.nodes && relatedPeopleGraph.nodes.length > 0">
                        <ngx-graph #graph2 class="chart-container" layout="d3ForceDirected"
                            [links]="relatedPeopleGraph.links" [nodes]="relatedPeopleGraph.nodes" [panOnZoom]="true"
                            [enableZoom]="true" [zoomSpeed]="0.05" [zoomLevel]="0.25" [minZoomLevel]="0.1"
                            [maxZoomLevel]="2.0" [autoCenter]="shouldAutoCentreRelatedPeopleGraph" [legend]="false"
                            [layoutSettings]="{
                                force: this.force,
                                forceLink: this.forceLink
                            }">

                            <ng-template #defsTemplate>
                                <svg:marker id="arrowRelatedPeople" viewBox="0 -5 10 10" refX="8" refY="0"
                                    markerWidth="4" markerHeight="4" fill="#fccfa0">
                                    <svg:path d="M0,-5L10,0L0,5" />
                                </svg:marker>
                            </ng-template>


                            <ng-template #nodeTemplate let-node>
                                <svg:g class="node">
                                    <svg:rect style="stroke-width:1px; stroke: #dee2e6"
                                        [attr.width]="node.dimension.width" [attr.height]="node.dimension.height"
                                        [attr.fill]="node.node_type=='business' ? '#f4f5f7' : '#000a36'" />
                                    <svg:text alignment-baseline="central" dx="10" dy="20"
                                        [attr.fill]="node.node_type=='business' ? '#000' : '#f4f5f7'">
                                        <tspan x="0.6em" dy="1.2em">{{node.label}}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                                        <tspan x="1.2em" dy="1.2em" *ngIf="node.revenue">Revenue: {{ node.revenue |
                                            companyDataPipe:'Revenue':'' }}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                                        <tspan x="1.2em" dy="1.2em" *ngIf="node.sic_code_1">{{ node.sic_code_1
                                            }}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                                        <a attr.xlink:href="{{node.link}}" target="_blank" *ngIf="node.link">
                                            <tspan x="1.2em" dy="1.2em">View Organisation</tspan>
                                        </a>
                                    </svg:text>
                                </svg:g>
                            </ng-template>

                            <ng-template #linkTemplate let-link>
                                <svg:g class="edge" ngx-tooltip [tooltipPlacement]="'top'" [tooltipType]="'tooltip'"
                                    [tooltipTitle]="link.label">
                                    <svg:path class="line" [attr.stroke-width]="link.line_width" stroke="#FA9F42"
                                        stroke-opacity="0.5" marker-end="url(#arrowRelatedPeople)">
                                    </svg:path>
                                </svg:g>
                            </ng-template>
                        </ngx-graph>
                    </div>
                </speak-to-sales-popover>
            </div>
        </div>



        <div class="col-md-12"
            *ngIf="(company.legal_person_pscs && company.legal_person_pscs.length > 0) || (company.corporate_officers && company.corporate_officers.length > 0)">
            <div class="card">
                <div class="card-header">
                    <h4>Corporate Officers & Legal PSCs</h4>
                </div>
                <div class="card-body card-body-overflow-double">
                    <div class="row" style="text-align: center">

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mx-auto"
                            *ngFor="let entity of company.corporate_officers">
                            <div class="row">
                                <div class="col-md-12 col-lg-9 mx-auto">
                                    <div class="key-people-card-holder">
                                        <div class="key-people-background"></div>
                                        <div class="key-people-info-section">
                                            <i class="fal fa-building key-people-icon"></i>
                                            <br>
                                            <span *ngIf="entity.name" style="font-weight: 400; font-size: 25px">
                                                {{entity.name}}<br>
                                            </span>
                                            <span *ngIf="entity.role" style="font-style: italic;">{{ entity.role
                                                }}<br></span>
                                            <span *ngIf="entity.tenure">Tenure: {{ entity.tenure }}<br></span>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mx-auto"
                            *ngFor="let entity of company.legal_person_pscs">
                            <div class="row">
                                <div class="col-md-12 col-lg-9 mx-auto">
                                    <div class="key-people-card-holder">
                                        <div class="key-people-background"></div>
                                        <div class="key-people-info-section">
                                            <i class="fal fa-building key-people-icon"></i>
                                            <br>
                                            <span *ngIf="entity.name" style="font-weight: 400; font-size: 25px">
                                                {{entity.name}}<br>
                                            </span>
                                            <span *ngIf="entity.legal_authority" style="font-style: italic;">{{
                                                entity.legal_authority }}<br></span>
                                            <span *ngIf="entity.legal_form" style="font-style: italic;">{{
                                                entity.legal_form }}<br></span>
                                            <table
                                                class="table table-bordered table-sm xsmall-margin-top xsmall-margin-bottom"
                                                *ngIf="entity.natures_of_control">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Natures of Control</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let noc of entity.natures_of_control">
                                                        <td>{{ noc }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <div class="col-md-12" id="supplier-graph"
            *ngIf="hasSupplierDataAccess && supplierGraph && supplierGraph?.nodes?.length > 1">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Supplier Graph:'}">
                    <p *ngIf="supplierGraph && supplierGraph?.nodes?.length >= 100">Each page limited to 100 suppliers
                    </p>
                    <p *ngIf="isLoadingSupplierGraph"><i class="fal fa-spinner fa-spin"></i> Loading</p>

                    <div class="btn-group btn-group-toggle button-right-company-detail" data-toggle="buttons">
                        <label class="btn btn-sm basic-button-base button-dark">
                            <input type="radio" name="options" id="supplierDataAsTable" autocomplete="off" checked
                                [value]="true" [(ngModel)]="viewSupplierDataDisplayAsTable"> Table
                        </label>
                        <label class="btn btn-sm basic-button-base button-dark">
                            <input type="radio" name="options" id="supplierDataAsGraph" autocomplete="off"
                                [value]="false" [(ngModel)]="viewSupplierDataDisplayAsTable"> Graph
                        </label>
                        <button class="btn btn-sm basic-button-base button-dark" *ngIf="hasSupplierPreviousPage"
                            (click)="supplierDataPageNumber = supplierDataPageNumber - 1; getSupplierGraph()">
                            < </button>
                                <button class="btn btn-sm basic-button-base button-dark" *ngIf="hasSupplierNextPage"
                                    (click)="supplierDataPageNumber = supplierDataPageNumber + 1; getSupplierGraph()">
                                    >
                                </button>
                    </div>

                    <div class="row" *ngIf="supplierGraph && viewSupplierDataDisplayAsTable">
                        <div class="col-md-12">
                            <table class="table table-striped table-bordered">
                                <ng-container *ngFor="let supplier of supplierGraph.nodes">
                                    <tr *ngIf="supplier.label != company.name">
                                        <td>{{ supplier.label }}</td>
                                        <td><a href="{{supplier.link}}" target="_blank">View Organisation</a></td>
                                        <td><a href="{{supplier.source_url}}" target="_blank">View Source</a></td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </div>

                    <ng-container *ngIf="supplierGraph && !viewSupplierDataDisplayAsTable">
                        <div class="graph-holder can-be-restricted-allow-click">
                            <ngx-graph #suppliergraph class="chart-container" layout="d3ForceDirected"
                                [panOnZoom]="true" [enableZoom]="true" [zoomSpeed]="0.05" [zoomLevel]="0.25"
                                [minZoomLevel]="0.1" [maxZoomLevel]="2.0" [autoCenter]="true" [legend]="false"
                                [links]="supplierGraph.links" [nodes]="supplierGraph.nodes" [layoutSettings]="{
                                    force: this.force,
                                    forceLink: this.forceLink
                                }">
                                <ng-template #defsTemplate>
                                    <svg:marker id="arrowSupplierGraph" viewBox="0 -5 10 10" refX="8" refY="0"
                                        markerWidth="4" markerHeight="4" orient="auto" fill="#fccfa0">
                                        <svg:path d="M0,-5L10,0L0,5" />
                                    </svg:marker>
                                </ng-template>
                                <ng-template #nodeTemplate let-node>
                                    <svg:g class="node">
                                        <svg:rect *ngIf="node.label == company.name"
                                            style="stroke-width:1px; stroke: #dee2e6" [attr.width]="300"
                                            [attr.height]="100" [attr.fill]="'#FA9F42'" />
                                        <svg:rect *ngIf="node.label != company.name"
                                            style="stroke-width:1px; stroke: #dee2e6" [attr.width]="300"
                                            [attr.height]="100" [attr.fill]="'#f4f5f7'" />
                                        <svg:text alignment-baseline="central" [attr.x]="10" [attr.y]="10">
                                            <tspan x="1.2em" dx="0.0em" dy="0.5em">
                                                {{node.label}}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                                            <a attr.xlink:href="{{node.link}}" target="_blank" *ngIf="node.link">
                                                <tspan x="1.2em" dy="1.2em">View Organisation</tspan>
                                            </a>
                                            <a attr.xlink:href="{{node.source_url}}" target="_blank"
                                                *ngIf="node.source_url">
                                                <tspan x="1.2em" dy="1.2em">View Source</tspan>
                                            </a>
                                        </svg:text>
                                    </svg:g>
                                </ng-template>
                                <ng-template #linkTemplate let-link>
                                    <svg:g class="edge">
                                        <svg:path class="line" [attr.stroke-width]="5" stroke="#FA9F42"
                                            stroke-opacity="0.5" marker-end="url(#arrowSupplierGraph)">
                                        </svg:path>
                                    </svg:g>
                                </ng-template>
                            </ngx-graph>
                        </div>
                    </ng-container>
                </speak-to-sales-popover>
            </div>
        </div>





        <div class="col-md-12" *ngIf="company.similar_organisations.length > 0">
            <div class="card">
                <speak-to-sales-popover
                    [inputParams]="{'isRestricted': isRestricted, 'sectionTitle': 'Similar Organisations', 'doubleHeight': true}">
                    <div id="similar-company-carousel" class="carousel slide" data-ride="carousel"
                        data-interval="999999999">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="row">
                                    <div class="col-lg-3"
                                        *ngFor="let similarOrg of company.similar_organisations | slice : 0 : 4">
                                        <similar-company-card
                                            [inputParams]="{'similarOrg': similarOrg}"></similar-company-card>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="row">
                                    <div class="col-lg-3"
                                        *ngFor="let similarOrg of company.similar_organisations | slice : 4 : 8">
                                        <similar-company-card
                                            [inputParams]="{'similarOrg': similarOrg}"></similar-company-card>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="row">
                                    <div class="col-lg-3"
                                        *ngFor="let similarOrg of company.similar_organisations | slice : 8">
                                        <similar-company-card
                                            [inputParams]="{'similarOrg': similarOrg}"></similar-company-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button onclick="$('#similar-company-carousel').carousel('prev')"
                            class="btn basic-button-base button-dark left-right-button left-button">&#60;</button>
                        <button onclick="$('#similar-company-carousel').carousel('next')"
                            class="btn basic-button-base button-dark left-right-button right-button">&#62;</button>
                    </div>

                </speak-to-sales-popover>
            </div>
        </div>


    </div>
</div>

<tagging></tagging>
<salesforce></salesforce>
<salesforce-export-modal></salesforce-export-modal>
<hubspot-export></hubspot-export>
<zint-grow-modal></zint-grow-modal>
<ai-insights-modal *ngIf="company?.financials && aiAccountsSummaries" [financials]="company?.financials" [aiAccountsSummaries]="aiAccountsSummaries" [companyId]="companyId"></ai-insights-modal> 
