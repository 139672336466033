import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { publicMethods } from '../../globals';
import { ZintGrowService } from '../../services/zint-grow.service';
import { ActivatedRoute } from '@angular/router';
import { ZintGrowSubscriptionsService } from '../../services/zint-grow-subscriptions.service';
import { CommunicationService } from '../../services/communication.service';
import {PermissionsService} from '../../services/permissions.service';

interface INotificationsFilter {
  showCompletedNotifications?: boolean | null;
  sortBy?: string | null;
  sortDirection?: string | null;
}

@Component({
  selector: 'zint-grow',
  templateUrl: './zint-grow.component.html',
  styleUrls: ['./zint-grow.component.css'],
})
export class ZintGrowComponent implements OnInit {
  pageNumber = 1;
  perPage = 100;
  numberPages: number;
  minNotificationGroupId: number;
  maxNotificationGroupId: number;

  notificationGroups: Array<any> = [];
  isSuperuser: boolean = false;

  companyNameSearch: '';
  shortlistedCompaniesByName: Array<any> = [];
  selectedCompanies = new Set();
  eventSourceTypes: Array<any> = [];
  ruleSets: Array<any> = [];
  selectedEventSourceType = null;
  selectedEventSourceTypes = new Set();
  selectedRuleSet: any;
  isFiltersDivAtTop: boolean = false;
  sortByOption: any;

  orgUsers: Array<any> = [];
  eachSelectedMember: Record<string, any> = {};
  selectedMemberIds = new Set<any>();
  selectedMembersFilter = new Set<string>();

  extraNotificationsFilters: INotificationsFilter = {
    showCompletedNotifications: null,
    sortBy: null,
    sortDirection: null,
  };

  @ViewChild('filters') filterDiv: ElementRef;

  constructor(
    private zintGrowService: ZintGrowService,
    private zintGrowSubscriptionsService: ZintGrowSubscriptionsService,
    private communicationService: CommunicationService,
    private permissionsService: PermissionsService,
    private pubMethods: publicMethods,
    private route: ActivatedRoute
  ) {}

  isLoading = this.zintGrowService.isLoading;

  ngOnInit(): void {
    this.zintGrowSubscriptionsService.getEventSourceTypes().subscribe(data => {
      this.eventSourceTypes = data;
    });
    this.zintGrowSubscriptionsService.listRuleSets().subscribe(data => {
      this.ruleSets = data.rule_sets;
    });

    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperuser = isSuperUser;
    });

    this.permissionsService
      .getSuperuserTeamsAndUsers()
      .subscribe(data => {
        if (this.pubMethods.isEmptyObjectOrArray(data)) return;
        this.orgUsers = data.organisation_users;
      });

    this.getUserRuleNotificationGroups();
    this.updateFiltersFromGetParams();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const rect =
      this.filterDiv.nativeElement.children[0].getBoundingClientRect();

    if (rect.top < -40) {
      this.isFiltersDivAtTop = true;
    } else {
      this.isFiltersDivAtTop = false;
    }
  }

  updateFiltersFromGetParams(): void {
    const params = this.route.snapshot.queryParams;
    if (params.minId) {
      this.minNotificationGroupId = params.minId;
    }
    if (params.maxId) {
      this.maxNotificationGroupId = params.maxId;
    }
    if (params.companyIds && params.companyNames) {
      const companyIdsAsList = JSON.parse(params.companyIds);
      const companyNamesAsList = JSON.parse(params.companyNames);
      for (let i = 0; i < companyIdsAsList.length; i++) {
        this.selectedCompanies.add({
          id: companyIdsAsList[i],
          company_name: companyNamesAsList[i],
        });
      }
    }
    if (params.selectedEventSourceTypes) {
      this.selectedEventSourceTypes = new Set(
        JSON.parse(params.selectedEventSourceTypes)
      );
    }
  }

  searchForCompaniesWithNotifications(): void {
    if (this.companyNameSearch.length > 2) {
      this.zintGrowService
        .getCompaniesWithNotificationsByNameMatch(this.companyNameSearch)
        .subscribe(data => this.updateMatchingCompaniesByName(data));
    }
  }

  updateMatchingCompaniesByName(data): void {
    this.shortlistedCompaniesByName = data;
  }

  addCompanyToFilteredCompanyList(selectedCompany): void {
    this.selectedCompanies.add(selectedCompany);
    this.companyNameSearch = '';
    this.getUserRuleNotificationGroups();
  }

  addSelectedEventSourceType(): void {
    if (this.selectedEventSourceType) {
      this.selectedEventSourceTypes.add(this.selectedEventSourceType);
      this.selectedEventSourceType = null;
      this.getUserRuleNotificationGroups();
    }
  }

  addSelectedMembers(selectedValue: any): void {
    if (!selectedValue) return;
    this.eachSelectedMember = null;

    if (selectedValue === 'selectAll') {
      this.orgUsers.forEach(user => {
        this.selectedMemberIds.add(user.userId);
        this.selectedMembersFilter.add(user.email);
      });
    } else {
      this.selectedMemberIds.add(selectedValue.userId);
      this.selectedMembersFilter.add(selectedValue.email);
    }
    this.getUserRuleNotificationGroups();
  }

  deleteSelectedMemberFilter(member: string): void {
    this.selectedMembersFilter.delete(member);

    const memberId = this.orgUsers.find(user => user.email === member).userId;

    this.selectedMemberIds.delete(memberId);
    this.getUserRuleNotificationGroups();
  }

  getUserRuleNotificationGroups(
    options: INotificationsFilter = this.extraNotificationsFilters
  ): void {
    const { showCompletedNotifications, sortBy, sortDirection } = options;

    const companyIds = [];
    if (this.selectedCompanies) {
      this.selectedCompanies.forEach(co => {
        companyIds.push(co['id']);
      });
    }
    let selectedRuleSetId = null;
    if (this.selectedRuleSet) {
      selectedRuleSetId = this.selectedRuleSet.id;
    }
    this.zintGrowService
      .getUserRuleNotificationGroups(
        this.pageNumber,
        this.perPage,
        this.minNotificationGroupId,
        this.maxNotificationGroupId,
        companyIds,
        this.selectedEventSourceTypes,
        selectedRuleSetId,
        showCompletedNotifications,
        sortBy,
        sortDirection,
        this.selectedMemberIds
      )
      .subscribe(data => {
        this.updateNotificationGroups(data);
      });
  }

  updateNotificationGroups(data): void {
    this.notificationGroups = data.notification_groups;
    this.pageNumber = data.page?.current;
    this.numberPages = data.page?.num_pages;
  }

  sortNotificationsGroups(sortDirection: string): void {
    if (!sortDirection || this.notificationGroups.length < 2) return;

    // TODO: more sort options to come, may need to get the sort option from the function parameter
    const sortBy = 'importance';

    this.extraNotificationsFilters = {
      ...this.extraNotificationsFilters,
      sortBy,
      sortDirection,
    };

    this.getUserRuleNotificationGroups(this.extraNotificationsFilters);
  }

  toggleShowHiddenNotifications(showOption: string): void {
    if (showOption === 'uncompleted') {
      this.extraNotificationsFilters.showCompletedNotifications = false;
    } else if (showOption === 'markedCompleted') {
      this.extraNotificationsFilters.showCompletedNotifications = true;
    } else if (showOption === 'all') {
      this.extraNotificationsFilters.showCompletedNotifications = null;
    }

    this.getUserRuleNotificationGroups(this.extraNotificationsFilters);
  }
}
