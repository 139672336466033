import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customColumnDisplay' })
export class CustomColumnDisplayPipe implements PipeTransform {
  getDateIfDate(input, columnType, dropdownInput) {
    if (input === "TODAY()"){
      return input
    }
    if (
      dropdownInput == 'isSet' ||
      dropdownInput == 'isNotSet' ||
      dropdownInput == 'isMe' ||
      dropdownInput == 'lockState'
    ) {
      return '';
    } else {
      if (columnType == 'Date') {
        input = new Date(input);
        return (
          input.getUTCFullYear() +
          '-' +
          (input.getUTCMonth() + 1) +
          '-' +
          input.getUTCDate()
        );
      } else {
        return input;
      }
    }
  }

  transform(inputString) {
    let inputStr;
    try {
      JSON.parse(inputString);
      inputStr = inputString;
    } catch (error) {
      return '';
    }
    return JSON.parse(inputStr)
      .filter(
        column =>
          (column.dropdownInput &&
            (column.dropdownInput == 'isSet' ||
              column.dropdownInput == 'isNotSet' ||
              column.dropdownInput == 'isMe' ||
              column.dropdownInput == 'lockState' ||
              column.boxInput ||
              column.boxInput == 0 ||
              column.boxInputLower ||
              column.boxInputLower == 0 ||
              column.boxInputUpper ||
              column.boxInputUpper == 0)) ||
          (column.options || []).filter(option => option.checked).length > 0
      )
      .map(
        col =>
          col.name +
          ': ' +
          ((col.options || []).length > 0 &&
          col.dropdownInput != 'isSet' &&
          col.dropdownInput != 'isNotSet' &&
          col.dropdownInput != 'isMe' &&
          col.dropdownInput != 'lockState'
            ? (col.options || [])
                .filter(opt => opt.checked == true)
                .map(_opt => _opt.value)
            : col.dropdownInput == 'between'
              ? col.dropdownInput +
                ' ' +
                this.getDateIfDate(
                  col.boxInputLower,
                  col.column_type,
                  col.dropdownInput
                ) +
                ' - ' +
                this.getDateIfDate(
                  col.boxInputUpper,
                  col.column_type,
                  col.dropdownInput
                )
              : col.dropdownInput +
                ' ' +
                this.getDateIfDate(
                  col.boxInput,
                  col.column_type,
                  col.dropdownInput
                )) +
          (col.lockFilter == undefined ? '' : ' and ' + col.lockFilter)
      );
  }
}
