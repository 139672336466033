import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { Company } from '../classes/company';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class DashboardService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService,
    private cookieservice: CookieService
  ) {}

  isLoading = {
    loadingCompanies: false,
    loadingImpExpCats: false,
    loadingUI: false,
    loadingDownload: false,
    loadingCompanyCount: false,
    loadingSicCodes: false,
    loadingImportExportCodes: false,
    loadingSaveUserSearch: false,
    loadingWebsiteKeywords: false,
    loadingMatchingEventNames: false,
    loadingSaveTerritory: false,
    loadingGetUserTerritories: false,
  };

  getInitialCompanies(): Observable<Company[]> {
    if (this.cookieservice.get('csrftoken') != undefined) {
      var params = this.auth.helperGetVerifiedGetParams({});
    } else {
      // TODO do not bother sending any other requests.
      var params = this.auth.helperGetEmptyGetParams({});
    }
    this.isLoading['loadingCompanies'] = true;
    return this.http
      .post<Company[]>(
        baseUrl + '/initial_companies/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCompanies'] = false)),
        catchError(
          this.pubMethods.handleError('getCompanies', [], this.isLoading)
        )
      );
  }

  getUIData(): Observable<any> {
    this.isLoading['loadingUI'] = true;
    return this.http
      .post(baseUrl + '/ui_data/', {}, this.auth.helperGetVerifiedPostDict())
      .pipe(
        tap(_ => (this.isLoading['loadingUI'] = false)),
        catchError(this.pubMethods.handleError('getUIData', [], this.isLoading))
      );
  }

  getInitialImportExportCategories(): Observable<any> {
    this.isLoading['loadingImportExportCodes'] = true;
    return this.http
      .post(
        baseUrl + '/initial_import_export_data/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingImportExportCodes'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getInitialImportExportCategories',
            [],
            this.isLoading
          )
        )
      );
  }

  searchImportExportCodes(impExpSearch: string): Observable<any> {
    let params = new HttpParams().set('impExpSearch', impExpSearch);
    this.isLoading['loadingImportExportCodes'] = true;
    return this.http
      .post(
        baseUrl + '/search_import_export_data/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingImportExportCodes'] = false)),
        catchError(
          this.pubMethods.handleError(
            'searchImportExportCodes',
            [],
            this.isLoading
          )
        )
      );
  }

  getChildImportExportCategories(
    id: number,
    impExpType: string,
    level: number
  ): Observable<any> {
    let params = new HttpParams()
      .set('id', String(id))
      .set('impExpType', impExpType)
      .set('level', String(level));
    return this.http
      .post(
        baseUrl + '/get_child_import_export_categories/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError(
            'getChildImportExportCategories',
            [],
            this.isLoading
          )
        )
      );
  }

  getFilterParameters(
    selectedTerritory: any,
    companyNameContains: string,
    accountantNameContains: string,
    bankerNameContains: string,
    selectedCompanyStatuses: Array<string>,
    lowerNumberOfUBOs: number,
    upperNumberOfUBOs: number,
    selectedUBOCountries: Array<string>,
    sicCode: string,
    selectedCountries: Array<string>,
    selectedCompanyCategories: Array<string>,
    selectedSICCodes: Array<string>,
    excludedSICCodes: Array<string>,
    websiteKeywords: Array<string>,
    corporateStructureIsTopLevelParentCompany: boolean = false,
    corporateStructureHasParent: boolean = false,
    corporateStructureDoesNotHaveParent: boolean = false,
    corporateStructureHasChild: boolean = false,
    corporateStructureDoesNotHaveChild: boolean = false,
    corporateStructureRelatedCompanies: string,
    hasWebsite: boolean = false,
    employeeLowerCount: number,
    employeeUpperCount: number,
    excludeEstimatedHeadcountFigures: boolean = false,
    lowerHeadCountGrowthPercentage: number,
    upperHeadCountGrowthPercentage: number,
    departmentHeadCountLowerCount: number,
    departmentHeadCountUpperCount: number,
    selectedFunctionalDepartments: Array<string>,
    lowerAge: number,
    upperAge: number,
    selectedAddressTypeOption: string,
    postcodeBeginsWith: string,
    postcodeDistrict: string,
    ukRegion: string,
    postcodeWithinRadius: string,
    selectedTownId: string,
    mileRadiusOfPostcode: number,
    hasOverseasParent: boolean = false,
    hasOfficesOverseas: boolean = false,
    selectedOfficeCountries: Array<string>,
    lowerNumberOfKnownOfficeLocations: number,
    upperNumberOfKnownOfficeLocations: number,
    selectedGeographicalRevenueCountries: Array<string>,
    selectedGeographicalRevenueTerritories: Array<string>,
    lowerGeographicalRevenueSelected: number,
    upperGeographicalRevenueSelected: number,
    hasLEINumber: boolean = false,
    selectedLEIStatus: string,
    selectedImportExportCategories: Array<string>,
    selectedImportingOrExportingOption: string,
    importExportTimeScale: number,
    importExportFrequencyImportingOrExporting: string,
    importExportFrequency: number,
    importExportFrequencyMonthCount: number,
    selectedWebTechnologies: Array<string>,
    webTrafficLow: number,
    webTrafficHigh: number,
    homepageSearchTerm: string,
    fullWebsiteSearchTerm: string,
    lowerAverageDirectorAgeRange: number,
    upperAverageDirectorAgeRange: number,
    lowerNumberOfDirectors: number,
    upperNumberOfDirectors: number,
    lowerNumberOfPSCs: number,
    upperNumberOfPSCs: number,
    PSCsRealPersonsOnly: boolean = false,
    recentDirectorshipTitleContains: string,
    lowerRecentDirectorshipMonth: number,
    upperRecentDirectorshipMonth: number,
    nationalitiesOfDirectorsSelected: Array<string>,
    nationalitiesOfPSCsSelected: Array<string>,
    lowerDaysUntilFinancialYearEnd: number,
    upperDaysUntilFinancialYearEnd: number,
    selectedFinancialYearEnds: Array<string>,
    lowerDaysUntilAnniversary: number,
    upperDaysUntilAnniversary: number,
    isHiring: boolean = false,
    hiringJobTitleContains: string,
    hiringDescriptionContains: string,
    selectedHiringDayLimit: number,
    lowerNumberOfRolesHiringFor: number,
    upperNumberOfRolesHiringFor: number,
    selectedNumberOfRolesHiringForDayLimit: number,
    includeRecruiters: boolean = false,
    directorJobTitleContains: string,
    amazonSeller: boolean = false,
    listedOnGCloud: boolean = false,
    hasPhoneNumber: boolean = false,
    hasEmailAddress: boolean = false,
    hasRaisedInvestment: boolean = false,
    hasShareFilingEvent: boolean = false,
    shareFilingEventWithinDays: number,
    lowerShareFilingCapitalValue: number,
    upperShareFilingCapitalValue: number,
    lowerInvestmentAmount: number,
    upperInvestmentAmount: number,
    ignoreRevenueEstimates: boolean = false,
    lowerRevenueSelected: number,
    upperRevenueSelected: number,
    lowerNetProfitSelected: number,
    upperNetProfitSelected: number,
    lowerCashAtBankSelected: number,
    upperCashAtBankSelected: number,
    lowerDebtorsSelected: number,
    upperDebtorsSelected: number,
    lowerCreditorsSelected: number,
    upperCreditorsSelected: number,
    lowerCurrentAssetsSelected: number,
    upperCurrentAssetsSelected: number,
    lowerFixedAssetsSelected: number,
    upperFixedAssetsSelected: number,
    lowerNetAssetsSelected: number,
    upperNetAssetsSelected: number,
    lowerAccountsDueSelected: number,
    upperAccountsDueSelected: number,
    lowerLatestAccountsSubmittedSelected: number,
    upperLatestAccountsSubmittedSelected: number,
    accountsAreOverdue: boolean = false,
    lowerGrossMargin: number,
    upperGrossMargin: number,
    lowerAnnualAssetsGrowthPercentage: number,
    upperAnnualAssetsGrowthPercentage: number,
    lowerAnnualFixedAssetsGrowthPercentage: number,
    upperAnnualFixedAssetsGrowthPercentage: number,
    lowerAnnualRevenueGrowthPercentage: number,
    upperAnnualRevenueGrowthPercentage: number,
    lowerAnnualGrossProfitGrowthPercentage: number,
    upperAnnualGrossProfitGrowthPercentage: number,
    accountsSearchTerm: string,
    accountsSearchWithinDays: number,
    issuedAGrant: boolean = false,
    lowerGrantAmount: number,
    upperGrantAmount: number,
    grantLowerMonthsUntilProjectEnd: number,
    grantUpperMonthsUntilProjectEnd: number,
    chargeRegistered: boolean = false,
    outstandingChargeRegistered: boolean = false,
    selectedEvents: Array<string>,
    postcodeEventsWithinRadius: string,
    mileRadiusOfEventsSelected: number,
    eventsWithinDaysLower: number,
    eventsWithinDaysUpper: number,
    newsWithinDays: number,
    newsContaining: string,
    newsSource: string,
    trademarkClassIdsSelected: Array<string>,
    trademarkDescriptionContains: string,
    trademarkNameContains: string,
    selectedTrademarkStatuses: Array<string>,
    excludeCompaniesTaggedByTeam: boolean = false,
    tagPositiveIdsSelected: Array<string>,
    tagNegativeIdsSelected: Array<string>,
    crossSellTagIdsSelected: Array<string>,
    crossSellLinkedThroughPeople: boolean = false,
    crossSellLinkedThroughCorporateStructure: boolean = false,
    similarCompanyUrl: string,
    pageNumber: number,
    sortKey: string,
    sortDirectionIsAscending: boolean = false,
    selectedCustomColumns: Array<any>,
    unreadAlertsOnly: boolean = false,
    lowerAlertsDayRange: number,
    upperAlertsDayRange: number,
    alertIdsSelected: Array<string>,
    selectedSalesforceObjects: Array<any>,
    selectedView: any,
    isLowEnergy: boolean = false,
    lowerTotalEnergyKwhSelected: number,
    upperTotalEnergyKwhSelected: number,
    lowerGasKwhSelected: number,
    upperGasKwhSelected: number,
    lowerElectricKwhSelected: number,
    upperElectricKwhSelected: number,
    lowerScope1KwhSelected: number,
    upperScope1KwhSelected: number,
    lowerScope2KwhSelected: number,
    upperScope2KwhSelected: number,
    lowerScope3KwhSelected: number,
    upperScope3KwhSelected: number,
    lowerTotalEmissionsKgCo2eSelected: number,
    upperTotalEmissionsKgCo2eSelected: number,
    lowerScope1KgCo2eSelected: number,
    upperScope1KgCo2eSelected: number,
    lowerScope2KgCo2eSelected: number,
    upperScope2KgCo2eSelected: number,
    lowerScope3KgCo2eSelected: number,
    upperScope3KgCo2eSelected: number,
    lowerKgCo2ePerMillionRevenueSelected: number,
    upperKgCo2ePerMillionRevenueSelected: number,
    lowerKgCo2ePerEmployeeSelected: number,
    upperKgCo2ePerEmployeeSelected: number,
    hasActivePatent: boolean = false,
    hasAnyPatentRecord: boolean = false,
    patentFiledWithinDays: number,
    lowerPatentAwardedDays: number,
    upperPatentAwardedDays: number,
    hasContract: boolean = false,
    lowerContractValueSelected: number,
    upperContractValueSelected: number,
    contractAwardedWithinMonths: number,
    lowerContractStartMonthsSelected: number,
    upperContractStartMonthsSelected: number,
    lowerContractEndMonthsSelected: number,
    upperContractEndMonthsSelected: number,
    contractTitleContains: string,
    contractDescriptionContains: string,
    ignoreSatisfiedCharges: boolean = false,
    chargeHolderNameContains: string,
    chargeCreatedMonthsAgoLower: number,
    chargeCreatedMonthsAgoUpper: number,
    supplierTagSelected: number,
    associateWithOneSupplierOnly: boolean = false,
    customerTagSelected: number,
    summarySemanticSearchTerm: string,
    summarySemanticSearchExclude: string,
    sliderValues: Record<string, any>
  ): HttpParams {
    let params = new HttpParams()
      .set(
        'selectedTerritory',
        this.pubMethods.stringifyDictForGetParams(selectedTerritory)
      )
      .set('companyNameContains', companyNameContains || '')
      .set('accountantNameContains', accountantNameContains || '')
      .set('bankerNameContains', bankerNameContains || '')
      .set(
        'lowerNumberOfUBOs',
        this.pubMethods.stringifyNumberForGetParam(lowerNumberOfUBOs)
      )
      .set(
        'upperNumberOfUBOs',
        this.pubMethods.stringifyNumberForGetParam(upperNumberOfUBOs)
      )
      .set(
        'selectedUBOCountries',
        this.pubMethods.stringifyListForGetParams(selectedUBOCountries)
      )
      .set(
        'selectedCompanyStatuses',
        this.pubMethods.stringifyListForGetParams(selectedCompanyStatuses)
      )
      .set('sicCode', sicCode || '')
      .set(
        'selectedCountries',
        this.pubMethods.stringifyListForGetParams(selectedCountries)
      )
      .set(
        'selectedCompanyCategories',
        this.pubMethods.stringifyListForGetParams(selectedCompanyCategories)
      )
      .set(
        'selectedSICCodes',
        this.pubMethods.stringifyListForGetParams(selectedSICCodes)
      )
      .set(
        'excludedSICCodes',
        this.pubMethods.stringifyListForGetParams(excludedSICCodes)
      )
      .set(
        'websiteKeywords',
        this.pubMethods.stringifyListForGetParams(websiteKeywords)
      )
      .set(
        'corporateStructureIsTopLevelParentCompany',
        String(corporateStructureIsTopLevelParentCompany)
      )
      .set('corporateStructureHasParent', String(corporateStructureHasParent))
      .set(
        'corporateStructureDoesNotHaveParent',
        String(corporateStructureDoesNotHaveParent)
      )
      .set('corporateStructureHasChild', String(corporateStructureHasChild))
      .set(
        'corporateStructureDoesNotHaveChild',
        String(corporateStructureDoesNotHaveChild)
      )
      .set(
        'corporateStructureRelatedCompanies',
        String(corporateStructureRelatedCompanies)
      )
      .set('hasWebsite', String(hasWebsite))
      .set(
        'employeeLowerCount',
        this.pubMethods.stringifyNumberForGetParam(employeeLowerCount)
      )
      .set(
        'employeeUpperCount',
        this.pubMethods.stringifyNumberForGetParam(employeeUpperCount)
      )
      .set(
        'excludeEstimatedHeadcountFigures',
        String(excludeEstimatedHeadcountFigures)
      )
      .set(
        'departmentHeadCountLowerCount',
        this.pubMethods.stringifyNumberForGetParam(
          departmentHeadCountLowerCount
        )
      )
      .set(
        'departmentHeadCountUpperCount',
        this.pubMethods.stringifyNumberForGetParam(
          departmentHeadCountUpperCount
        )
      )
      .set(
        'lowerHeadCountGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          lowerHeadCountGrowthPercentage
        )
      )
      .set(
        'upperHeadCountGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          upperHeadCountGrowthPercentage
        )
      )
      .set(
        'selectedFunctionalDepartments',
        this.pubMethods.stringifyListForGetParams(selectedFunctionalDepartments)
      )
      .set('lowerAge', this.pubMethods.stringifyNumberForGetParam(lowerAge))
      .set('upperAge', this.pubMethods.stringifyNumberForGetParam(upperAge))
      .set('selectedAddressTypeOption', selectedAddressTypeOption || '')
      .set('postcodeBeginsWith', postcodeBeginsWith || '')
      .set('postcodeDistrict', postcodeDistrict || '')
      .set('ukRegion', ukRegion || '')
      .set('postcodeWithinRadius', postcodeWithinRadius || '')
      .set('selectedTownId', selectedTownId || '')
      .set(
        'mileRadiusOfPostcode',
        this.pubMethods.stringifyNumberForGetParam(mileRadiusOfPostcode)
      )
      .set('hasOverseasParent', String(hasOverseasParent))
      .set('hasOfficesOverseas', String(hasOfficesOverseas))
      .set(
        'selectedOfficeCountries',
        this.pubMethods.stringifyListForGetParams(selectedOfficeCountries)
      )
      .set(
        'lowerNumberOfKnownOfficeLocations',
        this.pubMethods.stringifyNumberForGetParam(
          lowerNumberOfKnownOfficeLocations
        )
      )
      .set(
        'upperNumberOfKnownOfficeLocations',
        this.pubMethods.stringifyNumberForGetParam(
          upperNumberOfKnownOfficeLocations
        )
      )
      .set(
        'selectedGeographicalRevenueCountries',
        this.pubMethods.stringifyListForGetParams(
          selectedGeographicalRevenueCountries
        )
      )
      .set(
        'selectedGeographicalRevenueTerritories',
        this.pubMethods.stringifyListForGetParams(
          selectedGeographicalRevenueTerritories
        )
      )
      .set(
        'lowerGeographicalRevenueSelected',
        this.pubMethods.stringifyNumberForGetParam(
          lowerGeographicalRevenueSelected
        )
      )
      .set(
        'upperGeographicalRevenueSelected',
        this.pubMethods.stringifyNumberForGetParam(
          upperGeographicalRevenueSelected
        )
      )
      .set('hasLEINumber', String(hasLEINumber))
      .set('selectedLEIStatus', selectedLEIStatus || '')
      .set(
        'selectedImportExportCategories',
        this.pubMethods.stringifyListForGetParams(
          selectedImportExportCategories
        )
      )
      .set(
        'selectedImportingOrExportingOption',
        selectedImportingOrExportingOption || ''
      )
      .set(
        'importExportTimeScale',
        this.pubMethods.stringifyNumberForGetParam(importExportTimeScale)
      )
      .set(
        'importExportFrequencyImportingOrExporting',
        importExportFrequencyImportingOrExporting || ''
      )
      .set(
        'importExportFrequency',
        this.pubMethods.stringifyNumberForGetParam(importExportFrequency)
      )
      .set(
        'importExportFrequencyMonthCount',
        this.pubMethods.stringifyNumberForGetParam(
          importExportFrequencyMonthCount
        )
      )
      .set(
        'selectedWebTechnologies',
        this.pubMethods.stringifyListForGetParams(selectedWebTechnologies)
      )
      .set(
        'webTrafficLow',
        this.pubMethods.stringifyNumberForGetParam(webTrafficLow)
      )
      .set(
        'webTrafficHigh',
        this.pubMethods.stringifyNumberForGetParam(webTrafficHigh)
      )
      .set('homepageSearchTerm', homepageSearchTerm || '')
      .set('fullWebsiteSearchTerm', fullWebsiteSearchTerm || '')
      .set(
        'lowerAverageDirectorAgeRange',
        this.pubMethods.stringifyNumberForGetParam(lowerAverageDirectorAgeRange)
      )
      .set(
        'upperAverageDirectorAgeRange',
        this.pubMethods.stringifyNumberForGetParam(upperAverageDirectorAgeRange)
      )
      .set(
        'lowerNumberOfDirectors',
        this.pubMethods.stringifyNumberForGetParam(lowerNumberOfDirectors)
      )
      .set(
        'upperNumberOfDirectors',
        this.pubMethods.stringifyNumberForGetParam(upperNumberOfDirectors)
      )
      .set(
        'lowerNumberOfPSCs',
        this.pubMethods.stringifyNumberForGetParam(lowerNumberOfPSCs)
      )
      .set(
        'upperNumberOfPSCs',
        this.pubMethods.stringifyNumberForGetParam(upperNumberOfPSCs)
      )
      .set('PSCsRealPersonsOnly', String(PSCsRealPersonsOnly))
      .set(
        'recentDirectorshipTitleContains',
        recentDirectorshipTitleContains || ''
      )
      .set(
        'lowerRecentDirectorshipMonth',
        this.pubMethods.stringifyNumberForGetParam(lowerRecentDirectorshipMonth)
      )
      .set(
        'upperRecentDirectorshipMonth',
        this.pubMethods.stringifyNumberForGetParam(upperRecentDirectorshipMonth)
      )
      .set(
        'nationalitiesOfDirectorsSelected',
        this.pubMethods.stringifyListForGetParams(
          nationalitiesOfDirectorsSelected
        )
      )
      .set(
        'nationalitiesOfPSCsSelected',
        this.pubMethods.stringifyListForGetParams(nationalitiesOfPSCsSelected)
      )
      .set(
        'lowerDaysUntilFinancialYearEnd',
        this.pubMethods.stringifyNumberForGetParam(
          lowerDaysUntilFinancialYearEnd
        )
      )
      .set(
        'upperDaysUntilFinancialYearEnd',
        this.pubMethods.stringifyNumberForGetParam(
          upperDaysUntilFinancialYearEnd
        )
      )
      .set(
        'selectedFinancialYearEnds',
        this.pubMethods.stringifyListForGetParams(selectedFinancialYearEnds)
      )
      .set(
        'lowerDaysUntilAnniversary',
        this.pubMethods.stringifyNumberForGetParam(lowerDaysUntilAnniversary)
      )
      .set(
        'upperDaysUntilAnniversary',
        this.pubMethods.stringifyNumberForGetParam(upperDaysUntilAnniversary)
      )
      .set('isHiring', String(isHiring))
      .set('hiringJobTitleContains', <string>hiringJobTitleContains)
      .set('hiringDescriptionContains', <string>hiringDescriptionContains)
      .set(
        'selectedHiringDayLimit',
        this.pubMethods.stringifyNumberForGetParam(selectedHiringDayLimit)
      )
      .set(
        'lowerNumberOfRolesHiringFor',
        this.pubMethods.stringifyNumberForGetParam(lowerNumberOfRolesHiringFor)
      )
      .set(
        'upperNumberOfRolesHiringFor',
        this.pubMethods.stringifyNumberForGetParam(upperNumberOfRolesHiringFor)
      )
      .set(
        'selectedNumberOfRolesHiringForDayLimit',
        this.pubMethods.stringifyNumberForGetParam(
          selectedNumberOfRolesHiringForDayLimit
        )
      )
      .set('includeRecruiters', String(includeRecruiters))
      .set('directorJobTitleContains', directorJobTitleContains || '')
      .set('amazonSeller', String(amazonSeller))
      .set('listedOnGCloud', String(listedOnGCloud))
      .set('hasPhoneNumber', String(hasPhoneNumber))
      .set('hasEmailAddress', String(hasEmailAddress))
      .set('hasRaisedInvestment', String(hasRaisedInvestment))
      .set('hasShareFilingEvent', String(hasShareFilingEvent))
      .set(
        'shareFilingEventWithinDays',
        this.pubMethods.stringifyNumberForGetParam(shareFilingEventWithinDays)
      )
      .set(
        'lowerShareFilingCapitalValue',
        this.pubMethods.stringifyNumberForGetParam(lowerShareFilingCapitalValue)
      )
      .set(
        'upperShareFilingCapitalValue',
        this.pubMethods.stringifyNumberForGetParam(upperShareFilingCapitalValue)
      )
      .set(
        'lowerInvestmentAmount',
        this.pubMethods.stringifyNumberForGetParam(lowerInvestmentAmount)
      )
      .set(
        'upperInvestmentAmount',
        this.pubMethods.stringifyNumberForGetParam(upperInvestmentAmount)
      )
      .set(
        'lowerRevenueSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerRevenueSelected)
      )
      .set(
        'upperRevenueSelected',
        this.pubMethods.stringifyNumberForGetParam(upperRevenueSelected)
      )
      .set(
        'lowerNetProfitSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerNetProfitSelected)
      )
      .set(
        'upperNetProfitSelected',
        this.pubMethods.stringifyNumberForGetParam(upperNetProfitSelected)
      )
      .set(
        'lowerCashAtBankSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerCashAtBankSelected)
      )
      .set(
        'upperCashAtBankSelected',
        this.pubMethods.stringifyNumberForGetParam(upperCashAtBankSelected)
      )
      .set(
        'lowerDebtorsSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerDebtorsSelected)
      )
      .set(
        'upperDebtorsSelected',
        this.pubMethods.stringifyNumberForGetParam(upperDebtorsSelected)
      )
      .set(
        'lowerCreditorsSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerCreditorsSelected)
      )
      .set(
        'upperCreditorsSelected',
        this.pubMethods.stringifyNumberForGetParam(upperCreditorsSelected)
      )
      .set(
        'lowerCurrentAssetsSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerCurrentAssetsSelected)
      )
      .set(
        'upperCurrentAssetsSelected',
        this.pubMethods.stringifyNumberForGetParam(upperCurrentAssetsSelected)
      )
      .set(
        'lowerFixedAssetsSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerFixedAssetsSelected)
      )
      .set(
        'upperFixedAssetsSelected',
        this.pubMethods.stringifyNumberForGetParam(upperFixedAssetsSelected)
      )
      .set(
        'lowerNetAssetsSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerNetAssetsSelected)
      )
      .set(
        'upperNetAssetsSelected',
        this.pubMethods.stringifyNumberForGetParam(upperNetAssetsSelected)
      )
      .set('ignoreRevenueEstimates', String(ignoreRevenueEstimates))
      .set(
        'lowerAccountsDueSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerAccountsDueSelected)
      )
      .set(
        'upperAccountsDueSelected',
        this.pubMethods.stringifyNumberForGetParam(upperAccountsDueSelected)
      )
      .set(
        'lowerLatestAccountsSubmittedSelected',
        this.pubMethods.stringifyNumberForGetParam(
          lowerLatestAccountsSubmittedSelected
        )
      )
      .set(
        'upperLatestAccountsSubmittedSelected',
        this.pubMethods.stringifyNumberForGetParam(
          upperLatestAccountsSubmittedSelected
        )
      )
      .set('accountsAreOverdue', String(accountsAreOverdue))
      .set(
        'lowerGrossMargin',
        this.pubMethods.stringifyNumberForGetParam(lowerGrossMargin)
      )
      .set(
        'upperGrossMargin',
        this.pubMethods.stringifyNumberForGetParam(upperGrossMargin)
      )
      .set(
        'lowerAnnualAssetsGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          lowerAnnualAssetsGrowthPercentage
        )
      )
      .set(
        'upperAnnualAssetsGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          upperAnnualAssetsGrowthPercentage
        )
      )
      .set(
        'lowerAnnualFixedAssetsGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          lowerAnnualFixedAssetsGrowthPercentage
        )
      )
      .set(
        'upperAnnualFixedAssetsGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          upperAnnualFixedAssetsGrowthPercentage
        )
      )
      .set(
        'lowerAnnualRevenueGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          lowerAnnualRevenueGrowthPercentage
        )
      )
      .set(
        'upperAnnualRevenueGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          upperAnnualRevenueGrowthPercentage
        )
      )
      .set(
        'lowerAnnualGrossProfitGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          lowerAnnualGrossProfitGrowthPercentage
        )
      )
      .set(
        'upperAnnualGrossProfitGrowthPercentage',
        this.pubMethods.stringifyNumberForGetParam(
          upperAnnualGrossProfitGrowthPercentage
        )
      )
      .set('accountsSearchTerm', <string>accountsSearchTerm || '')
      .set(
        'accountsSearchWithinDays',
        this.pubMethods.stringifyNumberForGetParam(accountsSearchWithinDays)
      )
      .set('issuedAGrant', String(issuedAGrant))
      .set(
        'lowerGrantAmount',
        this.pubMethods.stringifyNumberForGetParam(lowerGrantAmount)
      )
      .set(
        'upperGrantAmount',
        this.pubMethods.stringifyNumberForGetParam(upperGrantAmount)
      )
      .set(
        'grantLowerMonthsUntilProjectEnd',
        this.pubMethods.stringifyNumberForGetParam(
          grantLowerMonthsUntilProjectEnd
        )
      )
      .set(
        'grantUpperMonthsUntilProjectEnd',
        this.pubMethods.stringifyNumberForGetParam(
          grantUpperMonthsUntilProjectEnd
        )
      )
      .set('chargeRegistered', String(chargeRegistered))
      .set('outstandingChargeRegistered', String(outstandingChargeRegistered))
      .set(
        'selectedEvents',
        this.pubMethods.stringifyListForGetParams(selectedEvents)
      )
      .set('postcodeEventsWithinRadius', <string>postcodeEventsWithinRadius)
      .set(
        'mileRadiusOfEventsSelected',
        this.pubMethods.stringifyNumberForGetParam(mileRadiusOfEventsSelected)
      )
      .set(
        'eventsWithinDaysLower',
        this.pubMethods.stringifyNumberForGetParam(eventsWithinDaysLower)
      )
      .set(
        'eventsWithinDaysUpper',
        this.pubMethods.stringifyNumberForGetParam(eventsWithinDaysUpper)
      )
      .set(
        'newsWithinDays',
        this.pubMethods.stringifyNumberForGetParam(newsWithinDays)
      )
      .set('newsContaining', <string>newsContaining)
      .set('newsSource', <string>newsSource)
      .set(
        'trademarkClassIdsSelected',
        this.pubMethods.stringifyListForGetParams(trademarkClassIdsSelected)
      )
      .set('trademarkDescriptionContains', <string>trademarkDescriptionContains)
      .set('trademarkNameContains', <string>trademarkNameContains)
      .set(
        'selectedTrademarkStatuses',
        this.pubMethods.stringifyListForGetParams(selectedTrademarkStatuses)
      )
      .set('excludeCompaniesTaggedByTeam', String(excludeCompaniesTaggedByTeam))
      .set(
        'tagPositiveIdsSelected',
        this.pubMethods.stringifyListForGetParams(tagPositiveIdsSelected)
      )
      .set(
        'tagNegativeIdsSelected',
        this.pubMethods.stringifyListForGetParams(tagNegativeIdsSelected)
      )
      .set(
        'crossSellTagIdsSelected',
        this.pubMethods.stringifyListForGetParams(crossSellTagIdsSelected)
      )
      .set('crossSellLinkedThroughPeople', String(crossSellLinkedThroughPeople))
      .set(
        'crossSellLinkedThroughCorporateStructure',
        String(crossSellLinkedThroughCorporateStructure)
      )
      .set('similarCompanyUrl', <string>similarCompanyUrl)
      .set('pageNumber', this.pubMethods.stringifyNumberForGetParam(pageNumber))
      .set('sortKey', <string>sortKey)
      .set('sortDirectionIsAscending', String(sortDirectionIsAscending))
      .set(
        'selectedCustomColumns',
        this.pubMethods.stringifyListForGetParams(selectedCustomColumns)
      )
      .set('unreadAlertsOnly', String(unreadAlertsOnly))
      .set(
        'lowerAlertsDayRange',
        this.pubMethods.stringifyNumberForGetParam(lowerAlertsDayRange)
      )
      .set(
        'upperAlertsDayRange',
        this.pubMethods.stringifyNumberForGetParam(upperAlertsDayRange)
      )
      .set(
        'alertIdsSelected',
        this.pubMethods.stringifyListForGetParams(alertIdsSelected)
      )
      .set(
        'selectedSalesforceObjects',
        this.pubMethods.stringifyListForGetParams(selectedSalesforceObjects)
      )
      .set(
        'selectedView',
        this.pubMethods.stringifyDictForGetParams(selectedView)
      )
      .set('isLowEnergy', String(isLowEnergy))
      .set(
        'lowerTotalEnergyKwhSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerTotalEnergyKwhSelected)
      )
      .set(
        'upperTotalEnergyKwhSelected',
        this.pubMethods.stringifyNumberForGetParam(upperTotalEnergyKwhSelected)
      )
      .set(
        'lowerGasKwhSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerGasKwhSelected)
      )
      .set(
        'upperGasKwhSelected',
        this.pubMethods.stringifyNumberForGetParam(upperGasKwhSelected)
      )
      .set(
        'lowerElectricKwhSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerElectricKwhSelected)
      )
      .set(
        'upperElectricKwhSelected',
        this.pubMethods.stringifyNumberForGetParam(upperElectricKwhSelected)
      )
      .set(
        'lowerScope1KwhSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerScope1KwhSelected)
      )
      .set(
        'upperScope1KwhSelected',
        this.pubMethods.stringifyNumberForGetParam(upperScope1KwhSelected)
      )
      .set(
        'lowerScope2KwhSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerScope2KwhSelected)
      )
      .set(
        'upperScope2KwhSelected',
        this.pubMethods.stringifyNumberForGetParam(upperScope2KwhSelected)
      )
      .set(
        'lowerScope3KwhSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerScope3KwhSelected)
      )
      .set(
        'upperScope3KwhSelected',
        this.pubMethods.stringifyNumberForGetParam(upperScope3KwhSelected)
      )
      .set(
        'lowerTotalEmissionsKgCo2eSelected',
        this.pubMethods.stringifyNumberForGetParam(
          lowerTotalEmissionsKgCo2eSelected
        )
      )
      .set(
        'upperTotalEmissionsKgCo2eSelected',
        this.pubMethods.stringifyNumberForGetParam(
          upperTotalEmissionsKgCo2eSelected
        )
      )
      .set(
        'lowerScope1KgCo2eSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerScope1KgCo2eSelected)
      )
      .set(
        'upperScope1KgCo2eSelected',
        this.pubMethods.stringifyNumberForGetParam(upperScope1KgCo2eSelected)
      )
      .set(
        'lowerScope2KgCo2eSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerScope2KgCo2eSelected)
      )
      .set(
        'upperScope2KgCo2eSelected',
        this.pubMethods.stringifyNumberForGetParam(upperScope2KgCo2eSelected)
      )
      .set(
        'lowerScope3KgCo2eSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerScope3KgCo2eSelected)
      )
      .set(
        'upperScope3KgCo2eSelected',
        this.pubMethods.stringifyNumberForGetParam(upperScope3KgCo2eSelected)
      )
      .set(
        'lowerKgCo2ePerMillionRevenueSelected',
        this.pubMethods.stringifyNumberForGetParam(
          lowerKgCo2ePerMillionRevenueSelected
        )
      )
      .set(
        'upperKgCo2ePerMillionRevenueSelected',
        this.pubMethods.stringifyNumberForGetParam(
          upperKgCo2ePerMillionRevenueSelected
        )
      )
      .set(
        'lowerKgCo2ePerEmployeeSelected',
        this.pubMethods.stringifyNumberForGetParam(
          lowerKgCo2ePerEmployeeSelected
        )
      )
      .set(
        'upperKgCo2ePerEmployeeSelected',
        this.pubMethods.stringifyNumberForGetParam(
          upperKgCo2ePerEmployeeSelected
        )
      )
      .set('hasActivePatent', String(hasActivePatent))
      .set('hasAnyPatentRecord', String(hasAnyPatentRecord))
      .set(
        'patentFiledWithinDays',
        this.pubMethods.stringifyNumberForGetParam(patentFiledWithinDays)
      )
      .set(
        'lowerPatentAwardedDays',
        this.pubMethods.stringifyNumberForGetParam(lowerPatentAwardedDays)
      )
      .set(
        'upperPatentAwardedDays',
        this.pubMethods.stringifyNumberForGetParam(upperPatentAwardedDays)
      )
      .set('hasContract', String(hasContract))
      .set(
        'lowerContractValueSelected',
        this.pubMethods.stringifyNumberForGetParam(lowerContractValueSelected)
      )
      .set(
        'upperContractValueSelected',
        this.pubMethods.stringifyNumberForGetParam(upperContractValueSelected)
      )
      .set(
        'contractAwardedWithinMonths',
        this.pubMethods.stringifyNumberForGetParam(contractAwardedWithinMonths)
      )
      .set(
        'lowerContractStartMonthsSelected',
        this.pubMethods.stringifyNumberForGetParam(
          lowerContractStartMonthsSelected
        )
      )
      .set(
        'upperContractStartMonthsSelected',
        this.pubMethods.stringifyNumberForGetParam(
          upperContractStartMonthsSelected
        )
      )
      .set(
        'lowerContractEndMonthsSelected',
        this.pubMethods.stringifyNumberForGetParam(
          lowerContractEndMonthsSelected
        )
      )
      .set(
        'upperContractEndMonthsSelected',
        this.pubMethods.stringifyNumberForGetParam(
          upperContractEndMonthsSelected
        )
      )
      .set('contractTitleContains', contractTitleContains || '')
      .set('contractDescriptionContains', contractDescriptionContains || '')
      .set('ignoreSatisfiedCharges', String(ignoreSatisfiedCharges))
      .set('chargeHolderNameContains', <string>chargeHolderNameContains || '')
      .set(
        'chargeCreatedMonthsAgoLower',
        this.pubMethods.stringifyNumberForGetParam(chargeCreatedMonthsAgoLower)
      )
      .set(
        'chargeCreatedMonthsAgoUpper',
        this.pubMethods.stringifyNumberForGetParam(chargeCreatedMonthsAgoUpper)
      )
      .set(
        'supplierTagSelected',
        this.pubMethods.stringifyNumberForGetParam(supplierTagSelected)
      )
      .set('associateWithOneSupplierOnly', String(associateWithOneSupplierOnly))
      .set(
        'customerTagSelected',
        this.pubMethods.stringifyNumberForGetParam(customerTagSelected)
      )
      .set('summarySemanticSearchTerm', summarySemanticSearchTerm || '')
      .set('summarySemanticSearchExclude', summarySemanticSearchExclude || '');

    for (const [key, value] of Object.entries(sliderValues)) {
      params = params.set(
        `lower${key}`,
        this.pubMethods.stringifyNumberForGetParam(value.lower)
      );
      params = params.set(
        `upper${key}`,
        this.pubMethods.stringifyNumberForGetParam(value.upper)
      );
    }
    return params;
  }

  searchCompaniesNew(getParams: HttpParams): Observable<any> {
    this.isLoading['loadingCompanies'] = true;
    return this.http
      .post(
        baseUrl + '/search_companies/',
        getParams,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCompanies'] = false)),
        catchError(
          this.pubMethods.handleError('searchCompaniesNew', [], this.isLoading)
        )
      );
  }

  getCompanyCount(getParams: HttpParams): Observable<any> {
    this.isLoading['loadingCompanyCount'] = true;
    return this.http
      .post(
        baseUrl + '/get_count_estimate/',
        getParams,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCompanyCount'] = false)),
        catchError(
          this.pubMethods.handleError('getCompanyCount', [], this.isLoading)
        )
      );
  }

  getInitialCompanyCount(): Observable<any> {
    this.isLoading['loadingCompanyCount'] = true;
    return this.http
      .post(
        baseUrl + '/get_initial_company_count/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCompanyCount'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getInitialCompanyCount',
            [],
            this.isLoading
          )
        )
      );
  }

  saveUserSearch(
    savedSearchName,
    searchParams,
    makeSavedSearchShared
  ): Observable<any> {
    let _params = new HttpParams()
      .set('savedSearchName', savedSearchName)
      .set('searchParams', searchParams)
      .set('makeSavedSearchShared', makeSavedSearchShared);
    this.isLoading['loadingSaveUserSearch'] = true;
    return this.http
      .post(
        baseUrl + '/save_user_search/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSaveUserSearch'] = false)),
        catchError(
          this.pubMethods.handleError('saveUserSearch', [], this.isLoading)
        )
      );
  }

  saveUserAlert(
    savedAlertName,
    selectedAlertFrequency,
    teamMemberIdToCreateAlertFor,
    searchParams
  ): Observable<any> {
    let _params = new HttpParams()
      .set('savedAlertName', savedAlertName)
      .set('selectedAlertFrequency', selectedAlertFrequency)
      .set('teamMemberIdToCreateAlertFor', teamMemberIdToCreateAlertFor)
      .set('searchParams', searchParams);
    this.isLoading['loadingCreateAlert'] = true;
    return this.http
      .post(
        baseUrl + '/save_user_alert/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCreateAlert'] = false)),
        catchError(
          this.pubMethods.handleError('saveUserAlert', [], this.isLoading)
        )
      );
  }

  saveTerritory(territoryName, territoryParams): Observable<any> {
    let _params = new HttpParams()
      .set('territoryName', territoryName)
      .set('territoryParams', territoryParams);
    this.isLoading['loadingSaveTerritory'] = true;
    return this.http
      .post(
        baseUrl + '/create_territory/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSaveTerritory'] = false)),
        catchError(
          this.pubMethods.handleError('saveTerritory', [], this.isLoading)
        )
      );
  }

  getMatchingTowns(townName): Observable<any> {
    let _params = new HttpParams().set('townName', townName);
    this.isLoading['loadingTownNames'] = true;
    return this.http
      .post(
        baseUrl + '/get_matching_towns/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTownNames'] = false)),
        catchError(
          this.pubMethods.handleError('getMatchingTowns', [], this.isLoading)
        )
      );
  }

  getMatchingWebsiteKeywords(websiteKeywordInput): Observable<any> {
    let _params = new HttpParams().set(
      'websiteKeywordInput',
      websiteKeywordInput
    );
    this.isLoading['loadingWebsiteKeywords'] = true;
    return this.http
      .post(
        baseUrl + '/get_matching_website_keywords/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingWebsiteKeywords'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getMatchingWebsiteKeywords',
            [],
            this.isLoading
          )
        )
      );
  }

  getMatchingEvents(eventNameSearchInput): Observable<any> {
    let _params = new HttpParams().set(
      'eventNameSearchInput',
      eventNameSearchInput
    );
    this.isLoading['loadingWebsiteKeywords'] = true;
    return this.http
      .post(
        baseUrl + '/get_matching_events/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingMatchingEventNames'] = false)),
        catchError(
          this.pubMethods.handleError('getMatchingEvents', [], this.isLoading)
        )
      );
  }

  saveColumnSize(columnName, width): Observable<any> {
    let _params = new HttpParams()
      .set('columnName', columnName)
      .set('width', width);
    this.isLoading['loadingColumnResizeSave'] = true;
    return this.http
      .post(
        baseUrl + '/save_column_width/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingColumnResizeSave'] = false)),
        catchError(
          this.pubMethods.handleError('getMatchingTowns', [], this.isLoading)
        )
      );
  }
}
