import {Component, NgZone, OnInit} from '@angular/core';
import {HubspotExporterService} from '../../../services/hubspot-export.service';
import {DataSharingService} from '../../../services/data-sharing.service';
import {publicMethods} from '../../../globals';
declare var $: any;

@Component({
  selector: 'hubspot-export',
  templateUrl: './hubspot-export.component.html',
  styleUrl: './hubspot-export.component.css'
})
export class HubspotExportComponent implements OnInit {

  companyIds = [];
  hubspotMatches = {};
  haveHubspotMatches = false;
  noHubspotMatches = [];
  allExistingChecked = false;
  allNewChecked = false;

  newlyCreatedCompanyNumbersToHubspotObjects = {};

  maximumSelectedRecords = 100;

  loadingStatus = this.hubspotExporterService.isLoading;

  constructor(
    private ngZone: NgZone,
    private dataSharingService: DataSharingService,
    private hubspotExporterService: HubspotExporterService,
    private pubMethods: publicMethods
  ) {}

  ngOnInit(): void {
    const exportModalSelf = this;
    $('#exportToHubspotModal').on('show.bs.modal', function (e) {
      exportModalSelf.ngZone.run(() =>
        exportModalSelf.getHubspotSettings()
      );
    });
    $('#exportToHubspotModal').on('hide.bs.modal', function (e) {
      exportModalSelf.ngZone.run(() =>
        exportModalSelf.wipeOldDataOnModalClose()
      );
    });
  }


  updateHubspotRecords(): void{
    const hubspotRecordsToUpdate = {};
    for (const companyNumber in this.hubspotMatches) {
        const hubspotRecords = this.hubspotMatches[companyNumber];
        hubspotRecords.forEach(hubspotRecord => {
            if (hubspotRecord.checked){
                hubspotRecordsToUpdate[hubspotRecord.hs_object_id] = companyNumber;
            }
        });
    }

    const numberOfRecordsToUpdate = Object.keys(hubspotRecordsToUpdate).length;
    if (numberOfRecordsToUpdate > this.maximumSelectedRecords){
        this.pubMethods.log('Please select a maximum of ' + this.maximumSelectedRecords + ' records.');
    }
    else if (numberOfRecordsToUpdate > 0){
        this.hubspotExporterService.updateExistingHubspotRecords(hubspotRecordsToUpdate).subscribe( response => {
            if (response.number_of_records_updated){
                this.pubMethods.log(response.number_of_records_updated + ' records updated in HubSpot');
                this.allExistingChecked = false;
                this.allNewChecked = false;
                this.toggleAllExistingChecked();
            }
        });
    }
    else{
        this.pubMethods.log('Please select one or more records.');
    }
  }

  createHubspotRecords(): void{
    // Ignore company numbers that you have already created or are not checked.
    let companyNumbersToCreate = this.noHubspotMatches.filter(noMatch => noMatch.checked && !(noMatch.company_number in this.newlyCreatedCompanyNumbersToHubspotObjects)).map(noMatch => noMatch.company_number);
    const numberOfRecordsToCreate = companyNumbersToCreate?.length;
    if (numberOfRecordsToCreate > this.maximumSelectedRecords){
        this.pubMethods.log('Please select a maximum of ' + this.maximumSelectedRecords + ' records.');
    }
    else if (companyNumbersToCreate?.length) {
        this.hubspotExporterService.createNewHubspotRecords(companyNumbersToCreate).subscribe( response => {
            if (response.number_of_records_created) {
                this.pubMethods.log(response.number_of_records_created + ' records created in HubSpot');
                // As this may be called multiple times while the modal is open, update the dictionary rather than replacing it.
                this.newlyCreatedCompanyNumbersToHubspotObjects = Object.assign({}, this.newlyCreatedCompanyNumbersToHubspotObjects, response.company_numbers_to_hubspot_objects || {});
                this.allExistingChecked = false;
                this.allNewChecked = false;
                this.toggleAllNewChecked();
            }
        });
    }
    else{
        this.pubMethods.log('Please select one or more records.');
    }
  }



  toggleAllExistingChecked() {
        for (const companyNumber in this.hubspotMatches) {
            const hubspotRecords = this.hubspotMatches[companyNumber];
            hubspotRecords.forEach(hubspotRecord => {hubspotRecord.checked = this.allExistingChecked});
        }
  }


  toggleAllNewChecked(): void {
      this.noHubspotMatches.forEach(noMatch => noMatch.checked = this.allNewChecked);
  }



  getHubspotSettings(): void {
    this.dataSharingService.currentSelectedCompanyIds.subscribe(
      companyIds => (this.companyIds = companyIds)
    );
    this.hubspotExporterService.getResultFromHubspot(this.companyIds).subscribe( response => {
        this.hubspotMatches = response.hubspot_matches
        this.noHubspotMatches = response.no_hubspot_matches;
        this.haveHubspotMatches = Object.keys(this.hubspotMatches)?.length > 0
    });
  }

  wipeOldDataOnModalClose(): void {
    this.hubspotMatches = {};
    this.haveHubspotMatches = false;
    this.noHubspotMatches = [];
    this.newlyCreatedCompanyNumbersToHubspotObjects = {};
    this.allExistingChecked = false;
    this.allNewChecked = false;
  }

}
