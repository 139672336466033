import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zint-loader',
  templateUrl: './zint-loader.component.html',
  styleUrls: ['./zint-loader.component.css'],
})
export class ZintLoaderComponent implements OnInit {
  @Input() size: 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' = 'medium';
  @Input() dotColor: string = '#000a36';
  @Input() isInline: boolean = false;

  sizeMap = {
    xxSmall: { dotSize: '3px', loaderSize: '12px' },
    xSmall: { dotSize: '5px', loaderSize: '20px' },
    small: { dotSize: '8px', loaderSize: '30px' },
    medium: { dotSize: '14px', loaderSize: '58px' },
    large: { dotSize: '20px', loaderSize: '90px' },
  };

  dotSize: string;
  loaderSize: string;

  constructor() {}

  ngOnInit(): void {
    this.dotSize = this.sizeMap[this.size].dotSize;
    this.loaderSize = this.sizeMap[this.size].loaderSize;
  }
}
