import { Component, OnInit } from '@angular/core';
import { CRMIntegrationsService } from '../../../services/crm-integrations.service';
import { ThemePalette } from '@angular/material/core';
import { VisibilityService } from '../../../services/visibility.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from '../../../services/communication.service';
import {PermissionsService} from '../../../services/permissions.service';
declare var $: any;

@Component({
  selector: 'hubspot',
  templateUrl: './hubspot.component.html',
  styleUrls: ['./hubspot.component.css'],
})
export class HubspotComponent implements OnInit {
  constructor(
    private commService: CommunicationService,
    private crmService: CRMIntegrationsService,
    private visService: VisibilityService,
    private permissionsService: PermissionsService,
    private _snackbar: MatSnackBar
  ) {}
  color: ThemePalette = 'primary';
  isParentOrgSuperUser = false;
  hasHubspotAccess = false;
  hasAuthorised = false;
  hubspotPublicUrl = '';
  toggleTooltip: string;
  isRevoking = false;
  lastSyncedDate = '';
  hubspotObjectName: string;
  syncedItems: number = null;
  exportConfig = null;

  zintPropertyOptions = [];
  hubspotPropertyOptions = [];

  selectedZintProperty = null;
  selectedHubspotProperty = null;
  isLookupField = false;
  overwriteField = true;

  organisationTeams = [];
  organisationUsers = [];

  loadingState = this.crmService.isLoading;

  ngOnInit(): void {
    document.title = 'Hubspot';
    this.commService.getIsParentOrgSuperUserMessage().subscribe(isParentOrgSuperUser => {
      this.isParentOrgSuperUser = isParentOrgSuperUser;
      if (this.isParentOrgSuperUser){
        this.permissionsService
          .getSuperuserTeamsAndUsers()
          .subscribe(data => {
            this.organisationTeams = data.organisation_teams;
            this.organisationUsers = data.organisation_users;
          });
      }
    });
    this.checkHubspotAccess();
    this.checkExistingHSAuth();
  }


  getZintAndHubspotMappingOptions(): void {
    if (this.zintPropertyOptions.length === 0 && this.hubspotPropertyOptions.length === 0){
        this.crmService.getZintAndHubspotMappingOptions().subscribe(response => {
          this.zintPropertyOptions = response.zint_properties;
          this.hubspotPropertyOptions = response.hubspot_properties;
          this.filterOutAlreadyUsedHubspotMappings();
        });
    }
    this.filterOutAlreadyUsedHubspotMappings();
  };

  filterOutAlreadyUsedHubspotMappings(): void {
    this.hubspotPropertyOptions = this.hubspotPropertyOptions.filter(
      prop => !(this.exportConfig['field_mappings'].some(mapping => mapping['crm_field_name'] === prop['name']))
    );
  }


  deleteHubspotFieldMapping(fieldMappingId): void {
    this.crmService.deleteHubspotFieldMapping(fieldMappingId).subscribe(response => {
      this.exportConfig = response.export_config
      this.filterOutAlreadyUsedHubspotMappings();
    });
  }


  addHubspotPropertyMapping(): void {
      this.crmService.addHubspotPropertyMapping(
          this.selectedHubspotProperty.name,
          this.selectedZintProperty,
          this.overwriteField,
          this.isLookupField
        ).subscribe(response => {
          this.exportConfig = response.export_config;
          this.overwriteField = false;
          this.isLookupField = false;
          this.selectedZintProperty = null;
          this.selectedHubspotProperty = null;
          this.filterOutAlreadyUsedHubspotMappings();
      });
  };


  activateCompanyExportAndEnrichment(): void {
    this.crmService.activateHubspotIntegration().subscribe(response => {
      this.exportConfig = response.export_config;
    });
  };

  checkHubspotAccess(): void {
    this.commService.getHubspotAccess().subscribe(hasAccess => {
      this.hasHubspotAccess = hasAccess;
      this.getTooltipText();
    });
  }

  ngAfterViewInit(): void {
    this.visService.getVisibilityChange().subscribe(visibilityChange => {
      //if not already authorised, tab change and revisit, reloads the page.
      if (!this.hasAuthorised && visibilityChange) {
        window.location.reload();
      }
    });
  }

  getTooltipText(): void {
    this.toggleTooltip = this.hasHubspotAccess
      ? this.hasAuthorised
        ? 'revoke'
        : 'authorise'
      : 'disabled';
  }

  checkExistingHSAuth(): void {
    this.crmService.getHubspotAuthStateAndURL().subscribe(res => {
      if (res.success) {
        this.hasAuthorised = true;
        this.lastSyncedDate = res.last_synced;
        this.syncedItems = res.number_of_records_synced;
        this.exportConfig = res.export_config
        // If it does not exist or is empty.
        if (!this.exportConfig?.field_mappings?.length){
          this.isLookupField = true;
        }
        else {
          this.isLookupField = false;
        }
      }
      if (res.url) {
        this.hubspotPublicUrl = res.url;
        this.isLookupField = true;
      }
    });
  }

  handleOAuth(authorised: boolean): void {
    if (!this.hasHubspotAccess) return;
    if (!authorised) {
      this.isRevoking = true;
      $('#confirmRevokeModal').modal('show');
      return;
    }
    //open a new tab and navigate to the publicUrl
    window.open(this.hubspotPublicUrl, '_blank');
  }

  toggleSyncState(): void {
    this.crmService.toggleHubspotEnrichmentProcess(this.exportConfig.crm_object_name).subscribe(res => {
      if (res.success) {
          this.exportConfig.is_enabled = res.is_enabled
      }
    });
  }

  revokeHubspotAccess(): void {
    this.crmService.revokeHubspotAccess().subscribe(res => {
      if (res.ok) {
        $('#confirmRevokeModal').modal('hide');
        this.hasAuthorised = false;
        this.isRevoking = false;

        this._snackbar.open(
          'Zint no longer has access to your HubSpot. In addition, you can uninstall the Zint application from your Hubspot connected apps.',
          'X',
          {
            duration: 8000,
          }
        );
      }
    });
  }
}
