<main class="container">
    <h2>HubSpot Integration</h2>
    <header *ngIf="!hasHubspotAccess" class="alert alert-warning" role="alert">Please contact your superuser or Zint Customer Success for further assistance.</header>

    @if(hasAuthorised){
    <div class="row">
        <div class="col d-flex flex-column align-self-center py-1 pt-1">
            <p><i class="fal fa-check-circle"></i> You have authorised Zint access to HubSpot.</p>
        </div>
        <div class="col d-flex flex-column align-items-center py-1 pt-1">
            <i class="fab fa-hubspot hs-fa-icon"><i class="far fa-thumbs-up hs-auth-success"></i></i>
            <mat-slide-toggle [matTooltip]="hasAuthorised ? 'Revoke': 'Authorise'" matTooltipPosition="after" [color]="color"
              [checked]="!isRevoking && hasAuthorised" (click)="handleOAuth(!hasAuthorised)"></mat-slide-toggle>
        </div>
        @if(!exportConfig && isParentOrgSuperUser) {
            <div class="col-md-12">
                 <ng-container>
                    <button class="basic-button-base button-dark btn-sm" (click)="activateCompanyExportAndEnrichment()"><i class="fal fa-check-circle"></i> Activate Company Export and Enrichment</button>
                </ng-container>
            </div>
        }
    </div>

    @if(exportConfig){
        <hr class="medium-margin-top medium-margin-bottom">
        <h5 class="text-center">HubSpot Auto Scheduled Enrichment Process</h5>

        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h6 class="mb-0">Sync Status</h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <p class="xsmall-margin-top">Hubspot Object: {{exportConfig.crm_object_name}}</p>
                                <p class="">Last Enrichment:
                                    @if(lastSyncedDate) {
                                        {{lastSyncedDate | date: 'dd MMM y'}}
                                    }
                                    @else{
                                        Pending
                                    }
                                </p>
                                <span class="">Objects Processed: {{syncedItems}}</span>
                            </div>
                            @if(isParentOrgSuperUser){
                                <div class="col-md-6">
                                    <mat-slide-toggle
                                      [matTooltip]="exportConfig.is_enabled ? 'Click to disable': 'Click to enable'"
                                      matTooltipPosition="below"
                                      [color]="color"
                                      [checked]="exportConfig.is_enabled"
                                      (click)="toggleSyncState()">
                                    </mat-slide-toggle>
                                    Regular data enrichment from Zint to HubSpot is @if (exportConfig.is_enabled){enabled} @else{disabled}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }


    <div *ngIf="isParentOrgSuperUser">
        <hr class="medium-margin-top medium-margin-bottom">
        <ng-container *ngIf="loadingState.isLoadingActivateHubspot">Loading</ng-container>
        @if(exportConfig) {
            <h5 class="text-center">Enrich/Export Mappings</h5>
            <table class="table table-striped table-bordered small-margin-top" *ngIf="this.exportConfig">
                <thead>
                    <tr>
                        <th>Zint Property</th>
                        <th>HubSpot Property Label</th>
                        <th>HubSpot Property Name</th>
                        <th>HubSpot Property Type</th>
                        <th class="d-none">Overwrite Existing Values?</th>
                        <th>Company Registration Number Lookup Field?</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fieldMapping of this.exportConfig.field_mappings">
                        <td class="align-middle">{{fieldMapping.zint_field}}</td>
                        <td class="align-middle">{{fieldMapping.crm_field_label}}</td>
                        <td class="align-middle">{{fieldMapping.crm_field_name}}</td>
                        <td class="align-middle">{{fieldMapping.crm_field_type}}</td>
                        <td class="text-center align-middle d-none">
                            <ng-container *ngIf="fieldMapping.overwrite">
                                <i class="fal fa-check-circle"></i>
                            </ng-container>
                            <ng-container *ngIf="!fieldMapping.overwrite">
                                <i class="fal fa-times"></i>
                            </ng-container>
                        </td>
                        <td class="text-center align-middle">
                            <ng-container *ngIf="fieldMapping.is_lookup_field">
                                <i class="fal fa-check-circle"></i>
                            </ng-container>
                            <ng-container *ngIf="!fieldMapping.is_lookup_field">
                                <i class="fal fa-times"></i>
                            </ng-container>
                        </td>
                        <td class="align-middle">
                            @if(!fieldMapping.is_lookup_field){
                                <button class="basic-button-base button-dark btn-sm" (click)="deleteHubspotFieldMapping(fieldMapping.id)">Delete</button>
                            }
                        </td>
                    </tr>

                    <tr *ngIf="zintPropertyOptions?.length !== 0 && hubspotPropertyOptions?.length !== 0">
                        <td class="align-middle">
                            <select [(ngModel)]="selectedZintProperty" class="custom-select" *ngIf="zintPropertyOptions?.length">
                                @if (this.exportConfig.field_mappings?.length === 0){
                                    <option value="Company Number" >Company Number</option>
                                }
                                @else {
                                    <option *ngFor="let zintProp of zintPropertyOptions" [ngValue]="zintProp" >{{zintProp}}</option>
                                }
                            </select>
                        </td>
                        <td class="align-middle">
                            <select [(ngModel)]="selectedHubspotProperty" class="custom-select" *ngIf="hubspotPropertyOptions?.length">
                                <option *ngFor="let hubspotProperty of hubspotPropertyOptions" [ngValue]="hubspotProperty" >{{hubspotProperty.label}}</option>
                            </select>
                        </td>
                        <td class="align-middle">
                            <ng-container *ngIf="selectedHubspotProperty">{{selectedHubspotProperty.name}}</ng-container>
                        </td>
                        <td class="align-middle">
                            <ng-container *ngIf="selectedHubspotProperty">{{selectedHubspotProperty.type}}</ng-container>
                        </td>
                        <td class="text-center align-middle d-none">
                            <input type="checkbox"
                               name="overwriteField"
                               [(ngModel)]="overwriteField"/>
                        </td>
                        <td class="text-center align-middle">
                            <input type="checkbox"
                               name="isLookupField"
                               value="isLookupField"
                               disabled
                               [(ngModel)]="isLookupField"/>
                        </td>
                        <td class="align-middle">
                            <button class="basic-button-base button-dark btn-sm" (click)="addHubspotPropertyMapping()">Add</button>
                        </td>
                    </tr>

                    <tr *ngIf="zintPropertyOptions?.length === 0 || hubspotPropertyOptions?.length === 0">
                        <td colspan="7" class="align-middle text-center">
                            <ng-container *ngIf="loadingState.isLoadingHubspotZintMappingOptions">
                                <div class="d-flex justify-content-center">
                                    <zint-loader size="xSmall"></zint-loader>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!loadingState.isLoadingHubspotZintMappingOptions">
                                <button class="basic-button-base button-dark btn-sm" (click)="getZintAndHubspotMappingOptions()">
                                    Add New
                                </button>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr class="medium-margin-top medium-margin-bottom">

            <h5 class="text-center small-margin-bottom">Permissions</h5>
            <ng-container *ngIf="exportConfig">
                <permissions [objectWithPermissions]="exportConfig.crm_config_object"
                             [isSuperUser]="isParentOrgSuperUser"
                             [organisationTeams]="organisationTeams"
                             [organisationUsers]="organisationUsers"
                             [postParamName]="'hubspotConfigId'"
                             [urlAppendString]="'hubspot_config'"
                             (permissionChanged)="checkExistingHSAuth()"
                ></permissions>
            </ng-container>
        }
    </div>
    }


  <section *ngIf="!hasAuthorised" class="pt-3">
    <div class="row my-4">
      <div class="col">
          <p>Regularly synchronise data between Zint and HubSpot; and export records from Zint into HubSpot</p>
          <p>By authorising this integration you agree to the <a href="#termsForHubspotIntegration" data-toggle="modal" data-target="#termsForHubspotIntegration">terms and conditions</a> of the Zint-HubSpot Integration.</p>
      </div>
      <div class="col d-flex flex-column align-items-center py-1">
        <i class="fab fa-hubspot hs-fa-icon"></i>
        <mat-slide-toggle [disabled]="!hasHubspotAccess" [matTooltip]="toggleTooltip" matTooltipPosition="after" [color]="color"
          [checked]="hasAuthorised" (click)="handleOAuth(!hasAuthorised)">Authorise</mat-slide-toggle>             
      </div>
    </div>
  </section>
</main>

<div class="modal fade" role="dialog" id="termsForHubspotIntegration" tabindex="-1">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="customColumnEditModalLabel">Terms and Conditions for HubSpot Integration</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe title="Terms and Conditions"
          src="https://docs.google.com/document/d/e/2PACX-1vSZyb90qTDn_8kGdkEbfXf00p6u0ZQ2qMvgPqxBsnnNyfdnrgDZtjTBhnSl8vQBihsjryOXJamqcfAi/pub"></iframe>
      </div>
    </div>
  </div>
</div>

<!-- Confirmation Modal -->
<div class="modal fade" id="confirmRevokeModal" tabindex="-1" role="dialog" aria-labelledby="confirmRevokeModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmRevokeModalLabel">Confirm</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to revoke Zint access to HubSpot?</p>
        <span class="mark">You will need to re-authorise if you want to use the HubSpot Integration in future.</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="basic-button-base button-dark rounded" data-dismiss="modal" (click)="isRevoking=false">Cancel</button>
        <button type="button" class="basic-button-base button-dark rounded" (click)="revokeHubspotAccess()">Revoke</button>
      </div>
    </div>
  </div>
</div>
